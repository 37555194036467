import { initial } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react"
import { getCompanyUsersByFilters } from "src/services/companyUser";
import FilterContext from "./FilterContext";
import MassConfirm from "./MassConfirm";
import MassSearch from "./MassSearch";

let confirmKey = 0;

const AppMassSend = (props) => {

    const [filters, setFilters] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [initialData, setInitialData] = useState([]);


    const setFiltersCallback = useCallback((filters) => { setFilters(filters) }, [])

    useEffect(() => {
        confirmKey++;
        if (confirmOpen && !props.data) {
            setIsLoading(true);
            getCompanyUsersByFilters(filters).then(({ data }) => {
                setInitialData(data);
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                console.error(error)
            })
        }
    }
        , [confirmOpen])

    useEffect(() => {
        confirmKey++;
        if (props?.data?.length > 0) {
            setInitialData(props.data);
            setConfirmOpen(true);
        }
    }
        , [props.data])

    const onClose = () => {
        props.close();
    }

    const openConfirm = () => {
        setSearchOpen(false);
        setConfirmOpen(true);
    }

    const preventRerenderMassSearch = useMemo(() => <MassSearch key={'1'} close={onClose} onConfirm={openConfirm} />, [searchOpen])

    return (<>
        <FilterContext.Provider value={[filters, setFiltersCallback]}>
            {searchOpen && preventRerenderMassSearch}
        </FilterContext.Provider>

        {confirmOpen && <MassConfirm key={confirmKey} initialData={initialData} loading={isLoading} contentId={props.contentId} onSend={() => { props.close() }} close={() => {
            if (!props.data) {
                setSearchOpen(true);
                setConfirmOpen(false);
            }
            else {
                onClose();
            }
        }} sent={onClose} />}
    </>)
}

export default AppMassSend;