import * as config from '../../constants';
import "isomorphic-fetch";
import axios from 'axios';

export function getAllAsyncEmailsPaginated(page, size, filters, orderBy, orderDirection) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ page, elementsPerPage: size, filters, orderBy, orderDirection }),
    }
    return axios({ url: `${config.apiUrl}/asyncEmail/getPaginated`, ...requestOptions })
}

// export function getCompanyDropdown(name) {
//     const requestOptions =
//     {
//         method: 'POST',
//         headers: { "Content-Type": 'application/json' },
//         withCredentials: true,
//         data: JSON.stringify({ name: name }),
//     }
//     return axios({ url: `${config.apiUrl}/company/getSelect`, ...requestOptions })
// }

// export function getCompanyById(id) {
//     const requestOptions =
//     {
//         method: 'POST',
//         headers: { "Content-Type": 'application/json' },
//         withCredentials: true,
//         data: JSON.stringify({ id }),
//     }
//     return axios({ url: `${config.apiUrl}/company/getById`, ...requestOptions })
// }

// export function deleteCompany(data) {
//     const requestOptions =
//     {
//         method: 'POST',
//         headers: { "Content-Type": 'application/json' },
//         withCredentials: true,
//         data: JSON.stringify(data),
//     }
//     return axios({ url: `${config.apiUrl}/company/delete`, ...requestOptions })
// }


// export function updateCompany(data) {
//     const requestOptions =
//     {
//         method: 'POST',
//         headers: { "Content-Type": 'application/json' },
//         withCredentials: true,
//         data: JSON.stringify(data),
//     }
//     return axios({ url: `${config.apiUrl}/company/update`, ...requestOptions })
// }


// export function createCompany(data) {
//     const requestOptions =
//     {
//         method: 'POST',
//         headers: { "Content-Type": 'application/json' },
//         withCredentials: true,
//         data: JSON.stringify(data),
//     }
//     return axios({ url: `${config.apiUrl}/company/create`, ...requestOptions })
// }


// export function getReportCompany(filter) {
//     let formData = new FormData();
//     formData.append('filters', JSON.stringify(filter));

//     const requestOptions =
//     {
//         method: 'POST',
//         headers: { "Content-Type": "multipart/form-data" },
//         responseType: 'arraybuffer',
//         withCredentials: true,
//         data: formData,
//     }
//     return axios({ url: `${config.apiUrl}/company/getReport`, ...requestOptions })
// }