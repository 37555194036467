export default theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),

    },
    dialogTitle: {
        cursor: 'move'
    },
    dialogTitleText: {
        verticalAlign: 'middle'
    },
    circularProgress: {
        width: '25px',
        height: '25px',
        marginLeft: '10px',
        verticalAlign: 'middle'
    },
    dialogContent: {
        backgroundColor: '#f4f6f8'
    },
    quizCard: {
        height: '100%',
    }
});