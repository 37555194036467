import { Checkbox, Chip } from "@material-ui/core";
import React, { Component } from 'react';
import * as constants from '../constants'
import { format, parse } from 'date-fns';

const getFormatedValue = (field, values) => {
  let value = values[field.name];
  let type = field.type;
  if (type === constants.checkbox) {
    return (<Checkbox disableRipple
      color="primary"
      checked={value} />)
  }
  else if (type === constants.datepicker) {
    if (value)
      return format(
        new Date(value),
        "dd/MM/yyyy"
      )
    else
      return '';
  }
  else if (type === constants.datetimepicker) {
    // console.log(value)
    // console.log(new Date(value))
    // console.log('value=',value)
    // console.log(parse(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date()))
    if (value)
      return format(
        new Date(value),
        "dd/MM/yyyy HH:mm:ss"
      )
    else return '';
  }
  // else if (type === constants.timepicker) {
  //   if (value)
  //     return format(
  //       new Date(value),
  //       "HH:mm"
  //     )
  //   else return '';
  // }
  else if (type === constants.dropdown) {
    if (value)
      return value;
    else return '';
  }
  else if (type === constants.customDropdown) {
    // console.log('dropdown value:',value)
    console.log('values:', values)

    if (values.productNameIs)
      return values.productNameIs;
    else return '';
  }
  else if (type === constants.query) {
    if (!value) {
      return "";
    }

    return (
      value.split(",").map(crt => (
        <Chip key={crt}
          label={crt}
        />
      )
      )
    )
  }
  else if (type === constants.link) {
    return (
      <a href={value} target="_blank">Link</a>
    )
  }
  else if (type === constants.textarea) {
    return (
      <span style={{wordBreak:'break-word'}}>{value}</span>
    )
  }
  else {
    return <span style={{whiteSpace:'pre'}}>{value}</span>;
  }
}

export default getFormatedValue;