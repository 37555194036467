import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import EmailEditor from '../Components/EmailEditor';
import { getNotifications } from 'src/services/notifications';

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      style={{ minHeight: '300px' }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}
const Notifications = ({ className, ...rest }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('one');
  const [state, setState] = React.useState({ notificationData: [], loading: true });

  const handleChange = (event, newValue) => {
    if (state.updatedNotificationData) {
      setState(prevState => ({ ...prevState, notificationData: { ...prevState.updatedNotificationData } }));
    }

    setValue(newValue);
  };

  useEffect(() => {
    getNotifications().then(({ data }) => setState(prevState => ({ ...prevState, notificationData: data, loading: false }))).catch(error => console.error(error));
  }, []);

  const setNotificationData = (data) => {
    setState(prevState => ({ ...prevState, updatedNotificationData: data }));
  }


  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Schimba sablon notificarile"
          title="Notificari"
        />
        <Divider />
        <CardContent>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
              <Tab
                value="one"
                label="Recuperare/Creare parola"
                wrapped
                {...a11yProps('one')}
              />
              <Tab value="two" label="Acces utilizatori" {...a11yProps('two')} />
              <Tab value="three" label="Alarma" {...a11yProps('three')} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index="one">
            <EmailEditor type="CREDENTIALS" variables={[{ name: 'parola', mandatory: true }, { name: 'email', mandatory: false }]} valueField="credentials" titleField="credentialsTitle" data={state?.notificationData} setData={setNotificationData} loading={state.loading} />
          </TabPanel>
          <TabPanel value={value} index="two">
            <EmailEditor type="CONTENT" variables={[{ name: 'parola', mandatory: true }, { name: 'nume_continut', mandatory: false }, { name: 'email', mandatory: false }, { name: 'detalii_extra', mandatory: false }]} valueField="content" titleField="contentTitle" data={state?.notificationData} setData={setNotificationData} title={state?.notificationData?.contentTitle} loading={state.loading} />
          </TabPanel>
          <TabPanel value={value} index="three">
            <EmailEditor type="REMINDER" variables={[{ name: 'nume_companie', mandatory: true }, { name: 'nume_user', mandatory: true }, { name: 'mesaj', mandatory: true }]} valueField="alarm" titleField="alarmTitle" data={state?.notificationData} setData={setNotificationData} loading={state.loading} />
          </TabPanel>
        </CardContent>

      </Card>
    </form>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
