import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';

// Externals
import classNames from 'classnames';
//import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'underscore';

// Material helpers
import { Table, TableHead, TableRow, TableFooter, TableCell, TableBody, Button, IconButton, Typography, CircularProgress, Paper, TableContainer, Card, CardContent } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from '@material-ui/core/styles'
import validate from 'validate.js';

// Shared components
// import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';

import { AddBox } from '@material-ui/icons';
import getDefaultData from './Components/defaultData';
import FilterTable from './Components/FilterTable';
import SortableHeader from './Components/SortableHeader';
import TableAction from './Components/TableAction';
import CustomTableCell from './Components/CustomTableCell';
import history from 'src/utils/history';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import TablePaginationCustom from './Components/TablePagination';
import FilterContext from 'src/views/events/FilterContext';

const CustomTable = (props) => {

  const columns = props.columns;
  const nameInput = React.createRef();
  let runningSearchQueries = [];
  let currentQueryId = 0;
  const { updateTableData: updateTableDataQuery, deleteTableData: deleteTableDataQuery, createTableData, getTableDataPaginatedQuery } = props.queries;

  let stateColumns = [];
  let definitions = columns.definitions;
  let defaultData = {};
  for (let definition of definitions) {
    let title = definition.title;
    let name = definition.name;
    let type = definition.type;
    defaultData[name] = definition.defaultValue || getDefaultData(type);
    let column = {
      title: title,
      field: name,
    }
    stateColumns.push(column);
  }
  let mandatoryFilters = {};
  if (columns.mandatoryFilters && columns.mandatoryFilters.length) {
    mandatoryFilters = { ...columns.mandatoryFilters[0] };
  }
  let initialFilter = columns.initialFilter || {};

  const [state, setState] = useState({
    isLoading: true,
    editMode: { isEdit: false, editIndex: 0 },
    deleteMode: { isDelete: false, deleteIndex: 0 },
    addMode: { isAdd: false },
    error: {},
    columns: [...stateColumns],
    defaultData,
    isEditable: columns.isEditable,
    data: [
    ],
    page: 0,
    totalElements: 0,
    totalPages: 0,
    pageSize: columns.defaultPageSize,
    filterDefinitions: columns.filterDefinitions,
    searchValue: '',
    filter: { ...mandatoryFilters, ...initialFilter },
    order: { orderBy: 'id', orderDirection: 'desc' },
    actions: columns.actions,
    downloadAction: columns.downloadAction,
    downloadAction2: columns.downloadAction2,
    customAction: columns.customAction
  });


  const firstRenderFilter = useRef(true);
  const [filters, setFilters] = useContext(FilterContext) || [];



  useEffect(() => {
    refreshData();
  }, [state.order]);


  const refreshData = () => {
    getDataPaginatedWithQuery(0, state.pageSize, state.filter, state.order.orderBy, state.order.orderDirection);
  }

  if (columns.setTriggerRefresh) {
    columns.setTriggerRefresh(refreshData);
  }

  useEffect(() => {
    if (firstRenderFilter.current) {
      firstRenderFilter.current = false;
      return;
    }

    updateData();
    if (columns.getFilters) {
      columns.getFilters(state.filter)
    }

  }, [state.filter]);





  const getQueryPaginatedData = (page, pageSize, query, orderBy, orderDirection) => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    getDataPaginatedWithQueryDebounced(page, pageSize, state.filter, orderBy, orderDirection);
  }

  const getDataPaginatedWithQueryDebounced = useCallback(_.debounce((page, pageSize, query, orderBy, orderDirection) => {
    getDataPaginatedWithQuery(page, pageSize, query, orderBy, orderDirection);
  }, 400), []);

  const getDataPaginatedWithQuery = (page, pageSize, query, orderBy, orderDirection) => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    let id = currentQueryId;
    currentQueryId++;
    query = { ...query, ...mandatoryFilters };
    runningSearchQueries = [...runningSearchQueries, id];
    if (setFilters) {
      setFilters(query);
    }
    getTableDataPaginatedQuery(page, pageSize, query, orderBy, orderDirection).then(data => {
      setState(prevState => ({ ...prevState, data: data.data.content, totalElements: data.data.totalElements, totalPages: data.data.content.totalPages, page: page, pageSize: pageSize, editMode: { isEdit: false }, deleteMode: { isDelete: false }, addMode: { isAdd: false } }));
      setLoadingStatus(id);
    });
  }


  const setLoading = (status) => {
    setState(prevState => ({ ...prevState, isLoading: status }));
  }

  const setLoadingStatus = (id) => {
    runningSearchQueries = runningSearchQueries.filter(crtId => crtId !== id);
    if (runningSearchQueries.length === 0) {
      setState(prevState => ({ ...prevState, isLoading: false }));
    }
  }

  const setErrors = (name, value, schema) => {
    let hasError = false;
    // console.log('errors', { ...state.editField, [name]: value })
    const errors = validate({ ...state.editField, [name]: value }, schema, { fullMessages: false });
    // console.log('test errors', errors)
    if (errors) {
      hasError = true;
      setState(prevState => ({ ...prevState, error: errors }));
    }
    if (!hasError) {
      setState(prevState => ({ ...prevState, error: errors }));
    }
  }

  const setMultipleErrors = (fields, schema) => {
    let hasError = false;
    // console.log('errors', { ...state.editField, ...fields })
    const errors = validate({ ...state.editField, ...fields }, schema, { fullMessages: false });
    // console.log('test errors', errors)
    if (errors) {
      hasError = true;
      setState(prevState => ({ ...prevState, error: errors }));
    }
    if (!hasError) {
      setState(prevState => ({ ...prevState, error: errors }));
    }
  }

  const setValue = (name, changeVal) => {
    setState(prevState => ({ ...prevState, editField: { ...prevState.editField, [name]: changeVal } }));
  }

  const { classes, className } = props;

  const rootClassName = classNames(classes.root, className);

  const updateData = () => {
    getDataPaginatedWithQueryDebounced(0, state.pageSize, state.filter, state.order.orderBy, state.order.orderDirection);
    // nameInput.current.focus();
  }

  const handleChangePage = (event, newPage) => {
    if (!isNotEditDeleteAdd())
      return;
    getDataPaginatedWithQuery(newPage, state.pageSize, state.filter, state.order.orderBy, state.order.orderDirection);
    // nameInput.current.focus();

  }

  const handleChangeRowsPerPage = (event) => {
    if (!isNotEditDeleteAdd())
      return;

    getDataPaginatedWithQuery(0, event.target.value, state.filter, state.order.orderBy, state.order.orderDirection);
    // nameInput.current.focus();

  }


  const onSort = (name) => {
    if (isEditDeleteCreate())
      return;
    const { order } = state;
    let newOrderBy = order.orderBy;
    let newOrderDirection = 'asc';
    if (order.orderBy === name) {
      if (order.orderDirection === 'desc') {
        newOrderBy = 'id';
        newOrderDirection = 'desc';
      }
      else {
        newOrderDirection = 'desc';
      }
    }
    else {
      newOrderBy = name;
    }
    setState(prevState => ({ ...prevState, order: { orderBy: newOrderBy, orderDirection: newOrderDirection } }));

  }

  const deleteTableData = (value, index) => {
    setLoading(true);
    deleteTableDataQuery(value).then(result => {
      refreshData();
    }).catch(error => {
      setLoading(false);

      let message = 'Nu s-au putut sterge datele!';
      if (error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      props.enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 60000,
        style: { whiteSpace: 'pre-line' },
        action: (key) => (
          <Button onClick={(e) => props.closeSnackbar(key)}>
            <span style={{ color: 'white' }}>{'Inchide'}</span>
          </Button>
        )
      });
      setState(prevState => ({ ...prevState, isLoading: false }));
    })
  }

  const addTableData = () => {
    let editFieldData = { ...state.editField }
    if (columns.mandatoryDataCreate) {
      editFieldData = { ...editFieldData, ...columns.mandatoryDataCreate };
    }
    setLoading(true);
    createTableData(editFieldData).then(result => {
      refreshData();
    }).catch(e => {
      setLoading(false);
      let errors = [];
      let errorMsg = "";
      if (e?.response?.data?.errors) {
        errors = e.response.data.errors
      }
      if (errors && errors.length && errors.length > 0) {
        let firstError = errors[0];
        if (firstError.message) {
          errorMsg = firstError.message;
        }
        for (let i = 1; i < errors.length; i++) {
          const crtError = errors[i];
          if (crtError.message)
            errorMsg += "\n" + crtError.message;
        }
      }

      let message = 'Nu s-au putut adauga noile date!\n';
      if (e?.response?.data?.message) {
        message = e?.response?.data?.message;
      }

      props.enqueueSnackbar(message + errorMsg, {
        variant: 'error',
        autoHideDuration: 60000,
        style: { whiteSpace: 'pre-line' },
        action: (key) => (
          <Button onClick={(e) => props.closeSnackbar(key)}>
            <span style={{ color: 'white' }}>{'Inchide'}</span>
          </Button>
        )
      });
    });
  }

  const getInitialValidation = (editField) => {
    let formatField = { ...editField };
    for (let definition of columns.definitions) {
      let name = definition.name;
      if (definition.type.includes('date'))
        formatField[name] = new Date(editField[name]);
      else
        formatField[name] = editField[name];

    }
    const errors = validate(formatField, columns.validatorSchema, { fullMessages: false });
    return errors;
  }


  const addClick = () => {
    if (columns?.customAddFunction) {
      columns.customAddFunction();
      return;
    }


    // return () => {
    let error = getInitialValidation(state.defaultData);
    if (isNotEditDeleteAdd())
      setState(prevState => ({ ...prevState, addMode: { isAdd: true }, error: error, editField: { ...state.defaultData }, data: [state.defaultData, ...prevState.data] }));
    // };
  }

  const downloadReport = () => {
    const { filter } = state;

    props.getReport(filter);
  }


  const cancelClick = (index) => {
    return () => {
      if (isEditOrDeleteOnCurrentRow(index))
        setState(prevState => ({ ...prevState, editMode: { isEdit: false }, deleteMode: { isDelete: false }, addMode: { isAdd: false }, error: [] }));
      else if (isAddOnCurrentRow(index))
        setState(prevState => ({ ...prevState, editMode: { isEdit: false }, deleteMode: { isDelete: false }, addMode: { isAdd: false }, data: prevState.data.splice(1), error: [] }));

    };
  }

  const deleteClick = (index, row) => {
    return () => {
      if (isNotEditDeleteAdd())
        setState(prevState => ({ ...prevState, deleteMode: { isDelete: true, deleteIndex: index }, deleteField: { ...row } }));
    };
  }

  const editClick = (index, row) => {
    if (columns?.customUpdateFunction) {
      const rowId = row?.id;
      columns.customUpdateFunction(rowId);
      return;
    }

    if (isNotEditDeleteAdd()) {
      const error = getInitialValidation(row)
      setState(prevState => ({ ...prevState, editMode: { isEdit: true, editIndex: index }, error: error, editField: { ...row } }));
    }

  }

  const updateTableData = () => {
    setState(prevState => ({ ...prevState, isLoading: true }));
    updateTableDataQuery(state.editField).then(result => {
      // setState(prevState => {
      //   let currentData = prevState.data;
      //   currentData[prevState.editMode.editIndex] = { ...prevState.editField };
      //   return ({ ...prevState, data: prevState.data, editMode: false, isLoading: false });
      // });
      refreshData();
    }).catch(e => {
      setLoading(false);
      let errors = [];
      let errorMsg = "";
      if (e?.response?.data?.errors) {
        errors = e.response.data.errors
      }
      if (errors.length && errors.length > 0) {
        let firstError = errors[0];
        if (firstError.message) {
          errorMsg = firstError.message;
        }
        for (let i = 1; i < errors.length; i++) {
          const crtError = errors[i];
          if (crtError.message)
            errorMsg += "\n" + crtError.message;
        }


      }
      let message = 'Nu s-au putut modifica datele!\n';
      if (e?.response?.data?.message) {
        message = e?.response?.data?.message;
      }
      // let exception = '';
      // if (error.type && error.type === 'Validation') {
      //   exception = error.message;
      // }

      props.enqueueSnackbar(message + errorMsg, {
        variant: 'error',
        autoHideDuration: 60000,
        style: { whiteSpace: 'pre-line' },
        action: (key) => (
          <Button onClick={(e) => props.closeSnackbar(key)}>
            <span style={{ color: 'white' }}>{'Inchide'}</span>
          </Button>
        )
      });
      setState({ ...state, isLoading: false })
    });
  }

  const isEditDeleteCreate = () => {
    return (state?.editMode?.isEdit) || (state?.deleteMode?.isDelete) || (state?.addMode?.isAdd);

  }

  const isEditOrDeleteOnCurrentRow = (index) => {
    return (state.editMode.isEdit && state.editMode.editIndex === index) || (state.deleteMode.isDelete && state.deleteMode.deleteIndex === index);
  }

  const isDeleteOnCurrentRow = (index) => {
    return state.deleteMode.isDelete && state.deleteMode.deleteIndex === index;
  }

  const isEditOnCurrentRow = (index) => {
    return state.editMode.isEdit && state.editMode.editIndex === index;
  }

  const isAddOnCurrentRow = (index) => {
    return state.addMode.isAdd && index === 0;
  }

  const isNotEditDeleteAdd = () => {
    return !state.editMode.isEdit && !state.deleteMode.isDelete && !state.addMode.isAdd;
  }


  const getLink = (oldLink, row) => {
    let link = '';
    if (oldLink.includes('${') && oldLink.includes('}')) {
      for (const key of Object.keys(row)) {
        oldLink = oldLink.replace('${' + key + '}', row[key]);
      }
    }
    link = oldLink;
    return link;
  }

  return (
    <Fragment>
      {!props.disableFilter ? (
        <Card className={rootClassName} style={{ marginBottom: "12px" }}>
          <CardContent>
            <div className={classes.addButton}>
              {props.getReport ?
                <Tooltip title="Descarca raport" placement="bottom" disabled={state.isLoading || props.loading}>
                  <span>
                    <IconButton disabled={state.isLoading || props.loading} color='inherit' aria-label="edit" onClick={() => downloadReport()}>
                      <SystemUpdateAltOutlinedIcon style={{ color: '#3f51b5' }} />
                    </IconButton>
                  </span>
                </Tooltip>
                : ''}
              {props.customActions ? props.customActions.map(customAction => (
                <Tooltip title={customAction.tooltip} placement="bottom" disabled={state.isLoading || props.loading}>
                  <span>
                    <IconButton disabled={state.isLoading || props.loading} color='inherit' aria-label="edit" onClick={customAction.onClick}>
                      {customAction.icon}
                    </IconButton>
                  </span>
                </Tooltip>
              )
              )
                : ''}

              {state.isEditable ? (
                <Tooltip title="Adauga" placement="bottom" disabled={state.isLoading || props.loading}>
                  <span>
                    <IconButton disabled={state.isLoading || props.loading} color='inherit' aria-label="edit" onClick={() => addClick()}>
                      <AddBox style={{ color: '#3f51b5' }} />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : ''}
            </div>

            <FilterTable hasFilter={state?.filterDefinitions?.length > 0} disabled={isEditDeleteCreate()} filterDefinitions={state.filterDefinitions} filterValue={state.filter}
              updateFunction={(e, name, value) => setState(prevState => ({ ...prevState, filter: { ...prevState.filter, [name]: value } }))} />
          </CardContent>
        </Card>
      ) : ''}
      <Card className={rootClassName}>
        <PerfectScrollbar>
          {state.isLoading || props.loading ? <CircularProgress className={classes.loader} /> : ''}
          <CardContent>
            <Table size='small' className={state.isLoading || props.loading ? classes.transparent : {}}>
              <SortableHeader isEditable={state.isEditable} isDisabledAddRemove={props.isEditDisabled && props.isDeleteDisabled && !state.addMode.isAdd} actions={state.actions} columns={props.columns} order={state.order} onSort={onSort} downloadAction={state.downloadAction} downloadAction2={state.downloadAction2} customAction={state.customAction} />
              <TableBody>
                {state.data.map((row, index) => (
                  <TableRow className={(state.editMode.isEdit && state.editMode.editIndex !== index) || (state.deleteMode.isDelete && state.deleteMode.deleteIndex !== index) || (state.addMode.isAdd && index !== 0) ? classes.transparent : undefined}>
                    {state.isEditable && !(props.isEditDisabled && props.isDeleteDisabled && !state.addMode.isAdd) ? (
                      <TableCell className={classes.actionsTableCell}>
                        <TableAction isNotEditDeleteAdd={isNotEditDeleteAdd} editClick={editClick} deleteClick={deleteClick} isDeleteDisabled={props.isDeleteDisabled} isEditDisabled={props.isEditDisabled}
                          isEditOnCurrentRow={isEditOnCurrentRow} isDeleteOnCurrentRow={isDeleteOnCurrentRow} isAddOnCurrentRow={isAddOnCurrentRow} updateTableData={updateTableData}
                          deleteTableData={deleteTableData} addTableData={addTableData} cancelClick={cancelClick} isLoading={state.isLoading || props.loading} row={row} index={index}
                          error={state.error} isEditable={state.isEditable} />
                      </TableCell>
                    ) : ''}
                    <CustomTableCell columns={columns} deleteMode={state.deleteMode} addMode={state.addMode}
                      isLoading={state.isLoading || props.loading} row={row} index={index} error={state.error} editMode={state.editMode}
                      setErrors={setErrors} setMultipleErrors={setMultipleErrors} setValue={setValue} editField={state.editField} />

                    {state?.actions?.length ? (
                      <TableCell>
                        <Button onClick={() => !isEditDeleteCreate() ? history.push(getLink(state.actions[0].link, row)) : ''} >Link</Button>

                      </TableCell>
                    ) : ''}

                    {state?.downloadAction ? (
                      <TableCell>
                        <Button onClick={(e) => {
                          if (isEditDeleteCreate())
                            return;
                          let customData = {}
                          for (const crtField of state.downloadAction.requiredActionFields) {
                            customData = { ...customData, [crtField]: row[crtField] };
                          }

                          state.downloadAction.action(customData)
                        }}>Link</Button>

                      </TableCell>
                    ) : ''}

                    {state?.downloadAction2 ? (
                      <TableCell>
                        <Button onClick={(e) => {
                          if (isEditDeleteCreate())
                            return;
                          let customData = {}
                          for (const crtField of state.downloadAction2.requiredActionFields) {
                            customData = { ...customData, [crtField]: row[crtField] };
                          }

                          state.downloadAction2.action(customData)
                        }}>Link</Button>

                      </TableCell>
                    ) : ''}
                    {state?.customAction ? (
                      <TableCell>
                        <Button onClick={(e) => {
                          if (isEditDeleteCreate())
                            return;
                          let customData = {}
                          for (const crtField of state.customAction.requiredActionFields) {
                            customData = { ...customData, [crtField]: row[crtField] };
                          }

                          state.customAction.action(customData)
                        }}>{state?.customAction?.label}</Button>

                      </TableCell>
                    ) : ''}

                  </TableRow>
                ))}

              </TableBody>
              <TableFooter >
                <TableRow>
                  {/* <TablePagination
                    rowsPerPageOptions={columns.pageSizes}
                    count={state.totalElements}
                    rowsPerPage={state.pageSize}
                    page={state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                    }}
                    labelRowsPerPage="Numar inregistrari per pagina"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} din ${count}`}
                    backIconButtonText="Pagina anterioara"
                    nextIconButtonText="Pagina urmatoare"
                    disabled={!isNotEditDeleteAdd()}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  /> */}
                  <TablePaginationCustom pageSizes={columns.pageSizes}
                    totalElements={state.totalElements}
                    pageSize={state.pageSize}
                    page={state.page}
                    disabled={!isNotEditDeleteAdd()}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </PerfectScrollbar>
      </Card>
    </Fragment>
  );

}




export default compose(withStyles(styles), withSnackbar)(CustomTable);