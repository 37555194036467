import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// width: 'calc(100% - 256px)'

const Page = forwardRef(({
  children,
  title = '',
  disableSpacing,
  disableNavSpacing,
  ...rest
}, ref) => {
  return (
    <div style={disableSpacing ? {} : disableNavSpacing ? { marginLeft: '20px', marginRight: '20px', marginTop: '64px', height: 'calc(100% - 64px)', minHeight: 'unset' } :
      { marginLeft: '245px', marginRight: '20px', marginTop: '64px', height: 'calc(100% - 64px)', minHeight: 'unset' }}
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
