import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Paper,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
  Grid
} from '@material-ui/core';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import {
  getCompanyUserDropdown,
  getCompanyUserQueryDropdown
} from 'src/services/companyUser';
import { Fragment } from 'react';
import Draggable from 'react-draggable';
import theme from './styles';
import { LabelDivider } from 'mui-label-divider';
import { getEmployeeDropdown } from 'src/services/users';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { AddBox, AddCircle, RemoveCircle } from '@material-ui/icons';

let dropdownGeneration = 0;

const useStyles = makeStyles(theme);

const Send = props => {
  const classes = useStyles();
  let initialState = {
    selectedUser: undefined,
    loading: false,
    cc: [],
    extraDetails: '',
    selectedUsers: []
  };
  const [state, setState] = useState({ ...initialState });

  const setUsers = (user, index) => {
    console.log(user, index);
    setState(prevState => {
      if (index + 1 > prevState.selectedUsers.size) {
        return {
          ...prevState,
          selectedUsers: [...prevState.selectedUsers, user]
        };
      } else if (user == undefined || null) {
        let newUsers = [...prevState.selectedUsers];
        newUsers.splice(index, 1);
        return {
          ...prevState,
          selectedUsers: [...newUsers]
        };
      } else {
        let newUsers = [...prevState.selectedUsers];
        newUsers[index] = user;
        return {
          ...prevState,
          selectedUsers: [...newUsers]
        };
      }
    });
  };

  const getCustomCompanyUserDropDown = async query => {
    return getCompanyUserQueryDropdown(query).then(({ data }) => ({
      data: data.filter(cu => !state.selectedUsers.some(s => s.id === cu.id))
    }));
  };

  const getDropdownData = () => {
    let numberOfDropdown = Math.max(state.selectedUsers.length, 1);
    if (state.selectedUsers[state.selectedUsers.length - 1] !== undefined) {
      numberOfDropdown++;
    }
    // for(const selectedUser of state.selectedUsers)
    // {
    //   if(!selectedUser)
    // }

    let dropdownData = [];
    for (let i = 0; i < numberOfDropdown; i++) {
      dropdownData = [...dropdownData, { gen: dropdownGeneration }];
    }
    dropdownGeneration++;
    return dropdownData;
  };

  const dropdownData = useMemo(() => {
    return getDropdownData();
  }, [state.selectedUsers]);

  const setSelectedUser = user => {
    setState(prevState => ({ ...prevState, selectedUser: user }));
  };

  const setLoading = state => {
    setState(prevState => ({ ...prevState, loading: state }));
  };

  const updateExtraDetails = details => {
    setState(prevState => ({ ...prevState, extraDetails: details }));
  };

  const setFlag = e => {
    const name = e.target.name;
    const value = !!e.target.checked;
    console.log('setting checkbox', name, value);
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const updateCC = (cc, index) => {
    setState(prevState => {
      let newCC = [...prevState.cc];
      if (cc === null) {
        newCC.splice(index, 1);
      } else {
        newCC[index] = cc;
      }
      return { ...prevState, cc: newCC };
    });
  };

  const addCC = () => {
    setState(prevState => ({ ...prevState, cc: [...prevState.cc, ''] }));
  };

  useEffect(() => {
    if (props.isSend) {
      reset();
    }
  }, [props.isSend]);

  const reset = () => {
    setState({ ...initialState });
  };

  function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title">
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <Fragment>
      <Dialog
        open={props.isSend}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="draggable-dialog-title"
        >
          <Typography variant="h5" className={classes.dialogTitle}>
            Trimite Quiz {props.name}
            {state.loading || props.loading ? (
              <CircularProgress className={classes.circularProgress} />
            ) : (
              ''
            )}
          </Typography>
        </DialogTitle>
        <DialogContent key={'1d'}>
          {/* <Grid container alignItems="center" spacing="1">
            <Grid item xs="10"> */}

          {/* <ServerAutocomplete
            onChange={(e, changeVal) => {
              setUsers(changeVal, 0);
            }}
            variant="outlined"
            label="User"
            margin="dense"
            name="companyUser"
            disabled={state.loading}
            isLoading={props.loading || state.loading}
            value={state?.selectedUsers[0]}
            getOptions={getCompanyUserQueryDropdown}
          /> */}
          {/* 
          {state.selectedUsers.length === 0 && (
            <ServerAutocomplete
              onChange={(e, changeVal) => {
                setUsers(changeVal, 0);
              }}
              key='before'
              variant="outlined"
              label="User primu"
              margin="dense"
              name="companyUser"
              disabled={state.loading}
              isLoading={props.loading || state.loading}
              value={undefined}
              getOptions={getCompanyUserQueryDropdown}
            />
          )} */}

          {dropdownData.map((user, index) => (
            <ServerAutocomplete
              onChange={(e, changeVal) => {
                setUsers(changeVal, index);
              }}
              key={state.selectedUsers[index]?.id}
              variant="outlined"
              label={'Utilizator ' + (index + 1)}
              margin="dense"
              name="companyUser"
              disabled={state.loading}
              isLoading={props.loading || state.loading}
              value={state.selectedUsers[index]}
              getOptions={getCustomCompanyUserDropDown}
            />
          ))}

          {/* {state.selectedUsers.length > 0 && (
            <ServerAutocomplete
              onChange={(e, changeVal) => {
                setUsers(changeVal, state.selectedUsers.length);
              }}
              key='after'
              variant="outlined"
              label="User dupa"
              margin="dense"
              name="companyUser"
              disabled={state.loading}
              isLoading={props.loading || state.loading}
              value={undefined}
              getOptions={getCompanyUserQueryDropdown}
            />
          )} */}

          {/* </Grid>
            <Grid item xs="1">
              <IconButton color="primary" aria-label="add to shopping cart">
                <RemoveCircle />
              </IconButton>
            </Grid>
            <Grid item xs="1">
              <IconButton color="primary" aria-label="add to shopping cart">
                <AddCircle />
              </IconButton>
            </Grid>
          </Grid> */}
          <br />
          <LabelDivider>Configuratie Redirectionare + Descarcare</LabelDivider>
          <FormControlLabel
            style={{ marginLeft: '1px' }}
            control={
              <Checkbox
                checked={!!state.canDownload}
                onChange={setFlag}
                color="primary"
                size="small"
                name="canDownload"
              />
            }
            label={
              <Typography style={{ fontSize: '14px' }}>
                Documentele se pot descarca
              </Typography>
            }
          />
          <FormControlLabel
            style={{ marginLeft: '1px' }}
            control={
              <Checkbox
                checked={!!state.canDownloadQuiz}
                onChange={setFlag}
                color="primary"
                size="small"
                name="canDownloadQuiz"
              />
            }
            label={
              <Typography style={{ fontSize: '14px' }}>
                Quiz-urile se pot descarca
              </Typography>
            }
          />
          <FormControlLabel
            style={{ marginLeft: '1px' }}
            control={
              <Checkbox
                checked={!!state.canDownloadForward}
                onChange={setFlag}
                color="primary"
                size="small"
                name="canDownloadForward"
              />
            }
            label={
              <Typography style={{ fontSize: '14px' }}>
                Se poate descarca dupa redirectionare
              </Typography>
            }
          />
          <FormControlLabel
            style={{ marginLeft: '1px' }}
            control={
              <Checkbox
                checked={!!state.canForward}
                onChange={setFlag}
                color="primary"
                size="small"
                name="canForward"
              />
            }
            label={
              <Typography style={{ fontSize: '14px' }}>
                Se poate redirectiona
              </Typography>
            }
          />

          <br />
          <LabelDivider>Mesaj suplimentar</LabelDivider>
          <TextField
            key={'1f'}
            style={{ fontSize: '14px' }}
            InputProps={{
              style: {
                fontSize: '14px'
              }
            }}
            InputLabelProps={{
              style: { fontSize: '14px' }
            }}
            rowsMin={3}
            multiline
            disabled={state.loading}
            fullWidth
            onChange={e => updateExtraDetails(e.target.value)}
            value={state.extraDetails}
            margin="dense"
            name="name1"
            variant="outlined"
          />
          <br />

          <br />
          <LabelDivider>CC</LabelDivider>
          <br />
          <TextField
            key={1}
            style={{ fontSize: '14px' }}
            InputProps={{
              style: {
                fontSize: '14px'
              }
            }}
            InputLabelProps={{
              style: { fontSize: '14px' }
            }}
            disabled={state.loading}
            fullWidth
            label="Email"
            margin="dense"
            name="name"
            value="Email curent"
            variant="outlined"
          />
          {state.cc.map((value, index) => (
            <ServerAutocomplete
              key={state?.cc[index]?.id}
              style={{ fontSize: '14px' }}
              InputProps={{
                style: {
                  fontSize: '14px'
                }
              }}
              InputLabelProps={{
                style: { fontSize: '14px' }
              }}
              onChange={(e, changeVal) => {
                updateCC(changeVal, index);
              }}
              margin="dense"
              size="small"
              value={state.cc[index]}
              label="Email"
              variant="outlined"
              disabled={state.loading}
              getOptions={name =>
                getEmployeeDropdown(name).then(({ data }) => {
                  const newData = data.filter(d => {
                    return !state.cc.filter(cc => cc.id === d.id).length > 0;
                  });
                  return Promise.resolve({ data: newData });
                })
              }
            />
          ))}
          <Button
            color="primary"
            onClick={addCC}
            disabled={state.loading || props.loading}
          >
            Adauga CC
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !state?.selectedUsers?.length || state.loading || props.loading
            }
            onClick={() => {
              props.send(
                props.id,
                state?.selectedUsers,
                state?.cc,
                state?.extraDetails,
                state.canDownload,
                state.canDownloadForward,
                state.canDownloadQuiz,
                state.canForward
              );
            }}
          >
            Trimite
          </Button>
          <Button
            color="primary"
            onClick={props.close}
            disabled={state.loading || props.loading}
          >
            Anuleaza
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(withRouter)(Send);
