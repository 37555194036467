import { Button, Card, CardContent, Checkbox, Chip, CircularProgress, IconButton, makeStyles, Table, TableBody, TableCell, TableFooter, TableRow, TextField } from '@material-ui/core';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import data from 'src/views/product/ProductListView/data';
import { object } from 'underscore';
import FilterTable from './Components/FilterTable';
import SortableHeader from './Components/SortableHeader';
import TablePaginationCustom from './Components/TablePagination';
import theme from './styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as constants from 'src/components/EditableTable/constants'
import { format } from 'date-fns';
import FilterContext from 'src/views/events/FilterContext';
import { Edit, FilterSharp } from '@material-ui/icons';
import ConfirmationText from '../ConfirmationText';
import CCSelector from 'src/views/events/components/CCSelector';

const ViewTable = (props) => {
    const useStyles = makeStyles(theme);

    const { columns } = props;
    const state = useState({});
    const [data, setData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [pagination, setPagination] = useState({ totalElements: 0, pageSize: 10, page: 0 })
    const [filter, setFilter] = useState({});
    const [visibleColumns, setVisibleColumns] = useState(columns.definitions.map(d => d.name))

    const [textEditHeader, setTextEditHeader] = useState(undefined);
    const [textEditRow, setTextEditRow] = useState(undefined);
    const [globalTextEditValue, setGlobalTextEditValue] = useState(undefined);

    const [ccEditHeader, setCCEditHeader] = useState(undefined);
    const [ccEditRow, setCCEditRow] = useState(undefined);
    const [globalCCEditValue, setGlobalCCEditValue] = useState(undefined);



    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState({ orderBy: 'id', orderDirection: 'desc' })
    const classes = useStyles();

    const compare = (a, b, type) => {
        if (type === constants.datetimepicker || type === constants.datepicker) {
            return new Date(a).getTime() - new Date(b).getTime();
        }
        else if (typeof (a) === 'number' || typeof (b) === 'number') {
            return a - b;
        }
        else if (typeof (b) === 'boolean' || typeof (a) === 'boolean') {
            if (!!a === !!b) {
                return 0;
            }
            if (!!a === true) {
                return 1;
            }
            else {
                return -1;
            }
        }
        else {
            if (a == undefined || a == null) {
                if (b == undefined || b == null) {
                    return 0;
                }
                else {
                    return -1;
                }
            }
            if (b == undefined || b == null) {
                return 1;
            }

            const localeCompare = a.localeCompare(b);
            return localeCompare;
        }
    }

    const computeCurrentPageData = () => {
        const { page, pageSize } = pagination;
        const { orderBy, orderDirection } = order;
        const orderByType = columns?.definitions?.find(d => d.name === orderBy)?.type;
        data.sort((a, b) => orderDirection === 'asc' ? compare(a[orderBy], b[orderBy], orderByType) : compare(b[orderBy], a[orderBy], orderByType));
        let newPageData = data.filter(n => Object.entries(filter).every(([key, value]) => value === undefined ? true : n[key]?.toString()?.includes(value)))
        newPageData = newPageData.slice(page * pageSize, page * pageSize + pageSize);
        setPageData(newPageData);
    }

    const handleChangePage = (e, pageNumber) => {
        setPagination((prev) => ({ ...prev, page: pageNumber }));
    }

    const handleChangeRowsPerPage = (e, rowsPerPage) => {
        setPagination((prev) => ({ ...prev, pageSize: +e.target.value, page: 0 }));
    }

    const onSort = (name) => {

        if (order.orderBy === name) {
            if (order.orderDirection === 'asc') {
                setOrder({ orderBy: name, orderDirection: 'desc' });
            }
            else {
                setOrder({ orderBy: 'id', orderDirection: 'desc' });
            }
        }
        else {
            setOrder({ orderBy: name, orderDirection: 'asc' });
        }
    }

    const updateValue = (value, name, id) => {
        setData(prev => {
            const newData = [...prev];
            let rowWithId = newData.find(n => n.id === id);
            rowWithId[name] = value;
            return newData;
        })
    }

    const updateAllValues = (value, name) => {
        setData(prev => {
            let newData = prev.map(n => ({ ...n, [name]: value }));
            return newData;
        })
    }

    useEffect(() => {
        if (data.length <= 0) {
            setData(props.initialData);
            setPagination((prev) => ({ ...prev, totalElements: props.initialData.length }));
        }
    }, [props.initialData, data])

    useEffect(() => {
        computeCurrentPageData();
    }, [data, filter, pagination, order])

    useEffect(() => {
        if (props.setData) {
            props.setData(data);
        }
    }, [data])

    const rootClassName = classNames(classes.root);

    return (
        <>
            <Card className={rootClassName} style={{ marginBottom: "12px" }}>
                <CardContent>
                    <FilterTable hasFilter filterDefinitions={columns.filterDefinitions} filterValue={filter}
                        updateFunction={(e, name, value) => setFilter(prev => {
                            if (!value?.trim()) {
                                value = undefined;
                            }
                            return { ...prev, [name]: value }
                        })}
                        setVisibleColumns={setVisibleColumns}
                        visibleColumns={visibleColumns}
                    />
                </CardContent>
            </Card>
            <Card className={rootClassName}>
                {isLoading || props.loading ? <CircularProgress className={classes.loader} /> : ''}
                <CardContent>
                    <PerfectScrollbar>

                        <Table size='small' className={isLoading || props.loading ? classes.transparent : {}}>
                            <SortableHeader isEditable={false} isDisabledAddRemove={true} columns={{
                                ...props.columns, definitions: props.columns.definitions
                                    .filter(c => visibleColumns.includes(c.name))
                            }} order={order} onSort={onSort} />
                            <TableBody>
                                <TableRow key={'selector_row'}>
                                    {columns.definitions.filter(c => visibleColumns.includes(c.name)).map(column => (
                                        <TableCell key={column.name}>
                                            {column.type == constants.editableCheckbox && <Checkbox
                                                disabled={false}
                                                color={data.every(d => d[column.name] === data[0][column.name]) ? 'primary' : 'default'}
                                                checked={data.some(d => d[column.name] === true)}
                                                onChange={e => {
                                                    const changeVal = e.target.checked;
                                                    updateAllValues(changeVal, column.name)
                                                }}
                                            />}
                                            {column.type == 'editableText' &&
                                                <Button size='small' onClick={() => { setTextEditHeader(column.name) }}>Seteaza coloana</Button>
                                            }
                                            {column.type == 'emailTagList' &&
                                                <Button size='small' onClick={() => { setCCEditHeader(column.name) }}>Seteaza coloana</Button>
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                                {pageData.map((row, index) => (
                                    <TableRow key={row.id}>
                                        {columns.definitions.filter(c => visibleColumns.includes(c.name)).map(column => (
                                            <TableCell key={column.name}>
                                                {(column.type == 'checkbox' || column.type == 'editableCheckbox') && <Checkbox
                                                    disabled={false}
                                                    color="primary"
                                                    checked={!!row[column.name]}
                                                    readOnly={column.type == 'checkbox'}
                                                    onChange={e => {
                                                        if (e.target.readOnly) {
                                                            return;
                                                        }
                                                        const changeVal = e.target.checked;
                                                        updateValue(changeVal, column.name, row.id)
                                                    }}
                                                />}
                                                {column.type === constants.datetimepicker &&
                                                    format(
                                                        new Date(row[column.name]),
                                                        "dd/MM/yyyy HH:mm:ss"
                                                    )
                                                }
                                                {column.type === 'editableText' &&
                                                    // <TextField value={row[column.name]} multiline fullWidth size='small' onChange={(e) => { updateValue(e.target.value, column.name, row.id) }} />
                                                    <>
                                                        <IconButton color='primary' size='small' onClick={() => { setTextEditRow({ name: column.name, id: row.id }) }}><Edit /></IconButton>
                                                        <span>{row[column.name]}</span>

                                                    </>
                                                }
                                                {(column.type === constants.text || column.type === 'editableTextHeader') &&
                                                    row[column.name]}
                                                {(column.type === 'emailTagList') &&
                                                    <>
                                                        <IconButton color='primary' size='small' onClick={() => { setCCEditRow({ name: column.name, id: row.id }) }}><Edit /></IconButton>
                                                        <div>{row[column.name]?.map(e => e.name + ', ')}</div>
                                                    </>
                                                }
                                            </TableCell>

                                        )
                                        )}

                                    </TableRow>
                                ))}

                            </TableBody>

                            <TableFooter >
                                <TableRow>
                                    <TablePaginationCustom pageSizes={[10, 15, 25]}
                                        totalElements={pagination.totalElements}
                                        pageSize={pagination.pageSize}
                                        page={pagination.page}
                                        disabled={false}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </PerfectScrollbar>


                </CardContent>
            </Card>
            <ConfirmationText defaultValue={textEditRow ? pageData.find(p => p.id === textEditRow.id)[textEditRow.name] : globalTextEditValue} open={textEditHeader || textEditRow} title={'Confirmare'} isLoading={false} onConfirm={(value) => {
                if (textEditHeader) {
                    updateAllValues(value, textEditHeader)
                    setGlobalTextEditValue(value);
                }
                else if (textEditRow) {
                    updateValue(value, textEditRow.name, textEditRow.id)
                    setGlobalTextEditValue(undefined);
                }
                setTextEditHeader(undefined);
                setTextEditRow(undefined);
            }} onCancel={() => {
                setTextEditHeader(undefined);
                setTextEditRow(undefined);
            }} />

            <CCSelector defaultValue={ccEditRow ? pageData.find(p => p.id === ccEditRow.id)[ccEditRow.name] : globalCCEditValue} open={ccEditHeader || ccEditRow} onConfirm={(value) => {
                if (ccEditHeader) {
                    updateAllValues(value, ccEditHeader)
                    setGlobalCCEditValue(value)
                }
                else if (ccEditRow) {
                    updateValue(value, ccEditRow.name, ccEditRow.id)
                    setGlobalCCEditValue(undefined)
                }
                setCCEditHeader(undefined);
                setCCEditRow(undefined);
            }}
                onCancel={() => {
                    setCCEditHeader(undefined);
                    setCCEditRow(undefined);
                }}

            />

        </>
    )
}


export default ViewTable;