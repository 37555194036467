import { TablePagination } from "@material-ui/core"

const TablePaginationCustom = ({ pageSizes, totalElements, pageSize, page, disabled, onChangePage, onChangeRowsPerPage }) => {

    return (
        <TablePagination
            rowsPerPageOptions={pageSizes}
            count={totalElements}
            rowsPerPage={pageSize}
            page={page}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
            }}
            labelRowsPerPage="Numar inregistrari per pagina"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} din ${count}`}
            backIconButtonText="Pagina anterioara"
            nextIconButtonText="Pagina urmatoare"
            disabled={disabled}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
        />
    )
}

export default TablePaginationCustom;