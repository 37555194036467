import validate from 'validate.js';


export default {
  name: {
    presence: { allowEmpty: false, message: 'Numele firmei este necesar!' },
  },
  cui: {
    presence: { allowEmpty: false, message: 'CUI-ul firmei este necesar!' },
    length: { maximum: 20, message: 'Lungimea maxima este de 20 de caractere!' }
  },

};
