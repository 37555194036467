export default theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),

    },
    dialogContent: {
        backgroundColor: '#f4f6f8'
    },
    tooltip: {
        backgroundColor: 'red',
        fontSize:'13px'
    },
    tooltipArrow: {
        color: 'red'
    }
});