import React, { useState } from 'react';
import {
    makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import * as constants from 'src/components/EditableTable/constants'
import EditableTable from 'src/components/EditableTable';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { deleteQuiz, downloadQuizzes, getAllQuizzesPaginated, getReportQuizzes } from 'src/services/quiz';
import QuizEditor from './QuizEditor';
import QuizTester from 'src/components/Quiz'
import theme from './styles'
import { downloadFile } from 'src/utils/downloadFile';
import { getEmployeeDropdown } from 'src/services/users';

const useStyles = makeStyles(theme);

const Quiz = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ create: false, edit: false, quizId: undefined, loading: false });

    const setMode = (mode) => {
        setState(prevState => {
            let create = false;
            let edit = false;

            if (mode === 'create') {
                create = true;
                edit = false;
            }
            else if (mode === 'edit') {
                create = false;
                edit = true;
            }

            return ({ ...prevState, create: create, edit: edit });
        })
    }

    const setLoading = (mode) => {
        setState(prevState => {
            return ({ ...prevState, loading: mode });
        });
    }

    const closeEditor = () => {

        setState(prevState => {
            return ({ ...prevState, create: false, edit: false, editId: undefined });
        });
    }

    const setEditId = (id) => {
        setState(prevState => {
            return ({ ...prevState, editId: id });
        });
    }


    let triggerRefresh;

    let columns = {
        definitions: [
            { title: 'Nume', name: 'name', type: constants.text, sortable: true },
            { title: 'Creare', name: 'creationDate', type: constants.datetimepicker, sortable: true },
            { title: 'Creat de', name: 'ownerUsername', type: constants.text, sortable: true, editableDisabled: true },
        ],
        isEditable: true,
        isDeleteDisabled: false,
        validatorSchema: {},
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'text', name: 'name', useId: true, label: 'Nume', size: 3, value: '' },
            { type: 'dropdown', name: 'ownerId', useId: true, label: 'Creat de', size: 3, value: '', getOptions: getEmployeeDropdown },

        ],
        customAddFunction: () => setMode('create'),
        customUpdateFunction: (id) => {
            setMode('edit');
            setEditId(id);

        },
        downloadAction: {
            label: 'Arata', requiredActionFields: ["id"], action: (requiredFields) => {
                setState(prevState => ({ ...prevState, quizId: requiredFields?.id }));
            }
        },
        downloadAction2: {
            label: 'Descarca', requiredActionFields: ["id"], action: (requiredFields) => {
                downloadQuiz(requiredFields?.id);
            }
        },
        setTriggerRefresh: (triggerRefreshFunction) => { triggerRefresh = triggerRefreshFunction }

    };

    const getReport = (filter) => {
        setLoading(true);
        getReportQuizzes(filter).then(({ data }) => {
            downloadFile(data, "Raport_Quizz-uri.xlsx", 'application/octet-stream')
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }

    const downloadQuiz = (id) => {
        setLoading(true);
        downloadQuizzes(id).then(({ data }) => {
            downloadFile(data, "Quiz.docx", 'application/octet-stream')
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    return (
        <Page
            className={classes.root}
            title="Quiz-uri">
            <QuizEditor
                isAdd={state?.create}
                isEdit={state?.edit}
                closeFunction={closeEditor}
                refreshFunction={() => {
                    if (triggerRefresh) {
                        triggerRefresh();
                    }
                }}
                editId={state?.editId}

            />
            <EditableTable columns={columns} queries={{
                getTableDataPaginatedQuery: getAllQuizzesPaginated,
                deleteTableData: deleteQuiz,
            }}
                getReport={getReport}
                loading={state.loading}

            />
            {state.quizId ? <QuizTester id={state.quizId} isTest close={() => setState(prevState => ({ ...prevState, quizId: undefined }))} /> : ''}
        </Page>
    );
};

export default compose(withRouter)(Quiz);
