import React, { useEffect, useState } from 'react';
import {
    makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { getAllUsersPaginated, deleteUser, updateUser, createUser, getReportEmployees } from 'src/services/users';
import * as constants from 'src/components/EditableTable/constants'
import EditableTable from 'src/components/EditableTable';
import schema from './schema';
import { getCurrentUserData, resetPassword } from 'src/services/user';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';
import theme from './styles';
import { downloadFile } from 'src/utils/downloadFile';
import ConfirmationDialog from 'src/components/ConfirmationDialog';

const useStyles = makeStyles(theme);


const Users = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ loading: false, resetDialogVisible: false, resetEmail: undefined });

    useEffect(() => { getCurrentUserData().then(resp => console.log(resp)).catch(error => console.log('error')) }

        , [])


    const setLoading = (mode) => {
        setState(prevState => ({ ...prevState, loading: mode }))
    }

    const showError = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'error' });
    }

    const showSuccess = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'success' });
    }

    let columns = {
        definitions: [
            { title: 'Email', name: 'username', type: constants.text, sortable: true },
            { title: 'Activ', name: 'isActive', type: constants.checkbox, sortable: true, defaultValue: true },
        ],
        isDeleteDisabled: true,
        isEditable: true,
        validatorSchema: schema,
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'text', name: 'username', label: 'Email', size: 3, value: '' },
            { type: 'boolean', name: 'active', label: 'Activ', size: 1, value: '' }

        ],
        customAction: {
            label: 'Reseteaza', title: 'Reseteaza parola', requiredActionFields: ["username"], action: (requiredFields) => {
                setResetEmail(requiredFields.username);
            }
        }
    };

    const setResetEmail = (email) => {
        setState(prevState => ({ ...prevState, resetDialogVisible: !!email, resetEmail: email }))
    }

    const resetEmail = () => {
        setLoading(true);
        resetPassword({ username: state.resetEmail }).then(() => {
            showSuccess('Parola a fost resetata cu succes pentru: ' + state.resetEmail)
            setLoading(false);
            setResetEmail(false);
        }
        ).catch(() => {
            showError('Parola nu a putut fi resetata pentru: ' + state.resetEmail)
            setLoading(false);
        }
        );
    }

    const getReport = (filter) => {
        setLoading(true);
        getReportEmployees(filter).then(({ data }) => {
            downloadFile(data, "Raport_Angajati.xlsx", 'application/octet-stream')
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }

    return (
        <Page
            className={classes.root}
            title="Angajati"
        >
            <EditableTable columns={columns}
                loading={state.loading}
                queries={{
                    getTableDataPaginatedQuery: getAllUsersPaginated,
                    deleteTableData: deleteUser,
                    updateTableData: updateUser,
                    createTableData: createUser
                }}
                getReport={getReport}
            />
            <ConfirmationDialog visible={state.resetDialogVisible} title={"Confirmare resetare parola"}
                content={"Esti sigur ca doresti resetarea parolei pentru: " + state.resetEmail + " ?"}
                onCancel={() => { setResetEmail(false) }}
                onOk={() => { resetEmail() }}
                isLoading={state.loading}
            />
        </Page>
    );
};

export default compose(withSnackbar)(Users);
