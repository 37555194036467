import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';



const SortableHeader = (props) => {

    const { isEditable, columns, order, onSort } = props;

    return (
        <TableHead>
            <TableRow>
                {(isEditable) && !props.isDisabledAddRemove ?
                    (<TableCell>
                        Modifica
                    </TableCell>) : ''
                }
                {columns.definitions.map(c =>
                (<TableCell>
                    <TableSortLabel
                        active={order.orderBy === c.name}
                        direction={order.orderDirection}
                        disabled={!c.sortable}
                        onClick={(e) => onSort(c.name)}
                    >
                        {c.title}

                    </TableSortLabel>
                </TableCell>))}

                {props.actions?.length ? (
                    <TableCell>
                        {props.actions[0].label}

                    </TableCell>
                ) : ''}
                {props?.downloadAction ? (
                    <TableCell>
                        {props.downloadAction.label}

                    </TableCell>
                ) : ''}
                {props?.downloadAction2 ? (
                    <TableCell>
                        {props.downloadAction2.label}

                    </TableCell>
                ) : ''}
                {props?.customAction ? (
                    <TableCell>
                        {props?.customAction?.title}

                    </TableCell>
                ) : ''}
            </TableRow>
        </TableHead>
    )

}

export default SortableHeader;