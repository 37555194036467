import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { compose } from "recompose";

const GdprDialog = (props) => {

    const [state, setState] = useState({ accept: false });

    return (
        <Fragment>
            <Dialog
                open={localStorage.getItem('acceptGdpr') == null && !state.accept}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'lg'}
                fullWidth
                >
                <DialogTitle >
                    <Typography variant='h4' style={{ verticalAlign: 'middle' }} component={'span'}>
                        Utilizarea de cookie-uri
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ backgroundColor: '#f4f6f8' }}>
                    <Typography variant='body1'>
                        Draga utilizator,
                        </Typography>
                    <br />
                    <Typography variant='body1'>

                        Pentru functionarea aplicatiei este necesara folosirea unor functionalitati ale browser-ului. Acestea sunt folosite strict pentru functionarea site-ului.
                    </Typography>
                    <br />

                    <Typography variant='body1'>

                        Prin consimtamantul oferit permiteti aplicatiei sa foloseasca aceste functionalitati fara de care aplicatia nu poate functiona.<br />
                        Aplicatia va folosii aceste date pentru a putea face autentificarea si alte interactiuni cu site-ul.
                        </Typography>
                    <br />
                    <br />
                    <Typography variant='body2' component={'span'}>
                        <p>Informatii care ar putea fi folosite:</p>
                        <br />
                        <ul style={{ marginLeft: '35px' }}>
                            <li>Sistemul de operare al dispozitivului</li>
                            <li>Browser-ul si setarile lui</li>
                            <li>Informatii stocate in cookie-uri si local storage</li>
                            <li>Adresa IP</li>
                        </ul>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { window.location.href = 'https://www.google.ro' }
                    }>
                        Refuza</Button>

                    <Button color="primary"
                        onClick={() => {
                            localStorage.setItem('acceptGdpr', 'true');
                            setState({ accept: true });
                        }}>Accepta</Button>

                </DialogActions>
            </Dialog>

        </Fragment >
    )



}


export default compose(withSnackbar)(GdprDialog);