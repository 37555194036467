import React, { Component } from 'react';
import * as constants from '../constants'
import { Input, TextField, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Fab, Button, IconButton, Typography, CircularProgress } from '@material-ui/core';
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";
// import IntegrationReactSelect from 'views/NewFeatures/components/New/ReactSelect/ReactSelect';
import { format, parse } from 'date-fns';
import ChipInput from 'material-ui-chip-input';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { Fragment } from 'react';

const EditComponent = (props) => {
    let field = props.field;
    let values = props.values;
    let setValue = props.setValue;
    let setErrors = props.setErrors;
    let errors = props.errors;
    let isLoading = props.isLoading;
    let schema = props.schema;
    let setMultipleErrors = props.setMultipleErrors;

    let initialError;
    let type = field.type;
    let value = values[field.name];
    let name = field.name;
    let disabled = isLoading;
    let hasError = errors !== undefined && errors.hasOwnProperty(name) && errors[name] !== undefined;
    let error = hasError ? errors[name].reduce((prev, crt) => { return prev + '; ' + crt }) : '';
    if (error.length === 0 && errors && Object.keys(errors) && Object.keys(errors).length) {
        error = String.fromCharCode(160);
        //add space to level all inputs
    }

    switch (type) {
        case constants.text: {
            return (
                <TextField
                    fullWidth
                    type={type}
                    disabled={disabled || field.editableDisabled}
                    value={value}
                    onChange={e => {
                        const changeVal = e.target.value;
                        setValue(name, changeVal);
                        const value = e.target.value;
                        setErrors(name, value, schema);
                    }}
                    error={hasError}
                    helperText={error}
                    margin="dense"
                    id="margin-dense"
                />
            )
        }
        case constants.textarea: {
            return (
                <TextField
                    fullWidth
                    type={type}
                    multiline
                    disabled={disabled}
                    value={value}
                    onChange={e => {
                        const changeVal = e.target.value;
                        setValue(name, changeVal);
                        const value = e.target.value;
                        setErrors(name, value, schema);
                    }}
                    error={hasError}
                    helperText={error}
                    margin="dense"
                    id="margin-dense"
                />
            )
        }
        case constants.minMillis:
        case constants.maxMillis:
        case constants.numeric: {
            return (
                <TextField
                    value={value}
                    disabled={disabled}
                    fullWidth
                    onChange={e => {
                        if ((!e.target.value.length === 0 && !e.target.value == '-') && (e.target.valueAsNumber === undefined || isNaN(e.target.valueAsNumber))) //||e.target.value.includes('.')
                            return;
                        const changeVal = e.target.valueAsNumber;
                        setValue(name, changeVal);
                        setErrors(name, changeVal, schema);
                    }}
                    error={hasError}
                    helperText={error}
                    margin="dense"
                    id="margin-dense"
                    type='number'
                />
            )
        }
        case constants.checkbox: {
            return (
                <Checkbox
                    disabled={disabled || field.editableDisabled}
                    color="primary"
                    checked={value}
                    onChange={e => {
                        const changeVal = e.target.checked;
                        setValue(name, changeVal);
                    }}
                />
            )
        }
        case constants.datepicker: {
            return (
                <DatePicker
                    disabled={disabled}
                    value={value}
                    openTo="year"
                    format="dd/MM/yyyy"
                    showTodayButton
                    views={["year", "month", "date"]}
                    onChange={e => {
                        const changeVal = e.toISOString();
                        setValue(name, changeVal);
                        const value = e;
                        setErrors(name, value, schema);
                    }}
                    error={hasError}
                    helperText={error}
                />
            )
        }
        case constants.datetimepicker: {
            // const newValue = parse(value, 'HH:mm:ss', new Date(2018, 0, 1));

            return (
                <DateTimePicker
                    disabled={disabled || field.editableDisabled}
                    value={value}
                    ampm={false}
                    openTo="year"
                    todayLabel="Now"
                    format="dd/MM/yyyy HH:mm"
                    showTodayButton
                    onChange={e => {
                        const differenceHours = e.getTimezoneOffset() / 60 * -1;
                        e.setTime(e.getTime() + (differenceHours * 60 * 60 * 1000))
                        const changeVal = e.toISOString().slice(0, -1);
                        setValue(name, changeVal);
                        const value = e;
                        setErrors(name, value, schema);
                    }}
                    error={hasError}
                    helperText={error}
                />
            )
        }
        case constants.timepicker: {
            const newValue = parse(value, 'HH:mm:ss', new Date(2018, 0, 1));
            return (
                // <DateTimePicker
                //     disabled={disabled}
                //     value={value}
                //     ampm={false}
                //     openTo="year"
                //     todayLabel="Now"
                //     format="dd/MM/yyyy HH:mm"
                //     showTodayButton
                //     onChange={e => {
                //         const changeVal = e.toISOString();
                //         setValue(name, changeVal);
                //         const value = e;
                //         setErrors(name, value, schema);
                //     }}
                //     error={hasError}
                //     helperText={error}
                // />
                <TimePicker
                    showTodayButton
                    disabled={disabled}
                    todayLabel="now"
                    value={newValue}
                    minutesStep={1}
                    ampm={false}
                    onChange={e => {
                        const changeVal = e.toISOString();
                        const newValue = format(e, 'HH:mm:ss');

                        setValue(name, newValue);
                        const value = e;
                        setErrors(name, newValue, schema);
                    }}
                    error={hasError}
                    helperText={error}
                />

            )
        }
        // case constants.dropdown: {
        //     console.log('sugestions', props.suggestions, 'name', name, props.suggestions[name]);
        //     const suggestions = props.suggestions[name];
        //     const defaultValue = suggestions.find(suggestion => suggestion.value === value);
        //     console.log(defaultValue)
        //     return (
        //         <div>
        //             <IntegrationReactSelect
        //                 onValueChange={changeVal => {
        //                     setValue(name, changeVal.value);
        //                     // const value = e;
        //                     setErrors(name, changeVal.value, schema);
        //                 }}
        //                 suggestions={suggestions}
        //                 dataType={'client'}
        //                 //isMulti={props.isMulti}
        //                 defaultValue={defaultValue}
        //                 error={hasError}
        //                 helperText={error}
        //             //isLoading={this.state.isLoading}
        //             //loadOptions={props.debouncedGetLoadOptions}
        //             />
        //             <p className={'MuiFormHelperText-root MuiFormHelperText-filled'}>{'  '}</p>
        //         </div>

        //     )
        // }
        case constants.query: {
            const defaultValue = value ? value.split(",") : [];
            return (
                <div style={{ marginBottom: "12px" }}>
                    <ChipInput
                        onAdd={addVal => {
                            let changeVal = [...defaultValue];
                            if (defaultValue.includes(addVal)) {
                                return;
                            }

                            changeVal.push(addVal);
                            const newValue = changeVal.join(",");
                            console.log("nevalue:", newValue)
                            setValue(name, newValue);
                            setErrors(name, newValue, schema);
                        }}
                        onDelete={deleteVal => {
                            const remainingValues = defaultValue.filter(value => value !== deleteVal);
                            const newValue = remainingValues.join(",");

                            setValue(name, newValue);
                            setErrors(name, newValue, schema);
                        }

                        }
                        value={defaultValue}
                        fullWidth
                        error={hasError}
                        helperText={error}
                    //isLoading={this.state.isLoading}
                    //loadOptions={props.debouncedGetLoadOptions}
                    />
                    {/* <p className={'MuiFormHelperText-root MuiFormHelperText-filled'}>{'  '}</p> */}
                </div>

            )
        }
        // case constants.customDropdown: {

        //     const idField = props.field.definition.id;
        //     const labelField = props.field.definition.label;
        //     let defaultValue = {}
        //     if (values.productId) {
        //         defaultValue = { id: values[idField], label: values[labelField] }
        //     }
        //     console.log('defaultvalue:', defaultValue)
        //     return (
        //         <div>
        //             <IntegrationReactSelect
        //                 onValueChange={changeVal => {

        //                     if (!changeVal) {
        //                         changeVal = {};
        //                     }

        //                     setValue(idField, changeVal.id);
        //                     setValue(labelField, changeVal.label);
        //                     setValue(name, changeVal.label);

        //                     // const value = e;
        //                     setErrors(name, changeVal.label, schema);
        //                 }}
        //                 dataType={'server'}
        //                 //isMulti={props.isMulti}
        //                 isClearable={true}
        //                 defaultValue={defaultValue}
        //                 error={hasError}
        //                 helperText={error}
        //                 //isLoading={this.state.isLoading}
        //                 loadOptions={props.debouncedGetLoadOptions}
        //             />
        //             <p className={'MuiFormHelperText-root MuiFormHelperText-filled'}>{'  '}</p>
        //         </div>

        //     )
        // }
        case constants.selectServer: {

            const idField = props.field.definition.id;
            const labelField = props.field.definition.label;
            const getOptions = props.field.getOptions;
            let defaultValue = {}
            //console.log('select')
            if (values.hasOwnProperty(idField) && values.hasOwnProperty(labelField)) {
                defaultValue = { id: values[idField], name: values[labelField] }
            }
            console.log(values)
            console.log('default value:',defaultValue)

            if (props.field.fixedValue) {
                defaultValue = { id: props.field.fixedValue.id, name: props.field.fixedValue.name }
            }

            return (
                <div>
                    <ServerAutocomplete
                        disabled={disabled || field.editableDisabled}

                        onChange={(e, changeVal) => {

                            if (!changeVal) {
                                changeVal = {};
                            }

                            setValue(idField, changeVal.id);
                            setValue(labelField, changeVal.name);
                            // setValue(name, changeVal.label);

                            // const value = e;
                            setErrors(name, changeVal.name, schema);
                        }}
                        value={defaultValue}
                        defaultValue={defaultValue}
                        hasError={hasError}
                        error={error}
                        //isLoading={this.state.isLoading}
                        getOptions={props.field.getOptions}
                    />
                </div>

            )
        }
        case constants.file:
            {
                return (
                    <Fragment>
                        <label htmlFor="btn-upload-edit-component">
                            <input
                                id="btn-upload-edit-component"
                                name="btn-upload-edit-component"
                                style={{ display: 'none' }}
                                type="file"
                                onChange={
                                    (e) => {
                                        // this.selectFile(e);
                                        // e.target.value = ""

                                        console.log("edit")
                                        if (e?.target?.files[0]?.name?.length) {
                                            setValue(name, e?.target?.files[0]?.name);
                                            setValue('selectedFile', true);
                                            setValue('file', e?.target?.files[0]);
                                            setMultipleErrors({ name: e?.target?.files[0]?.name, 'selectedFile': true, 'file': e?.target?.files[0] }, schema);

                                        }
                                        else {
                                            setValue(name, "");
                                            setValue('selectedFile', false);
                                            setValue('file', undefined);
                                            setMultipleErrors({ name: '', 'selectedFile': false, 'file': undefined }, schema);

                                        }


                                        //todo improve validations
                                        // setErrors(name, e?.target?.files[0]?.name, schema);
                                        // setErrors('selectedFile', true, schema);
                                        // setErrors('file', e?.target?.files[0], schema);

                                    }

                                }
                            // onClick={this.handleClick}
                            />
                            <Button
                                className="btn-choose"
                                variant="outlined"
                                component="span" >
                                Alege Fisier
                            </Button>
                        </label>
                        <TextField
                            margin='dense'
                            label='Nume fisier'
                            // variant='outlined'
                            disabled={!values.selectedFile && !value?.length}
                            fullWidth
                            value={value || ''}
                            onChange={e => {
                                setValue(name, e.target.value);
                                setErrors(name, e.target.value, schema);
                            }}
                            error={hasError}
                            helperText={error}
                        />
                    </Fragment>
                )
            }
        default: return;
    }
}



export default EditComponent;