import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Paper,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Box
} from '@material-ui/core';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { getAllCompanyUsersPaginated, getCompanyUserDropdown } from 'src/services/companyUser';
import { Fragment } from 'react';
import Draggable from 'react-draggable';
import theme from './styles'
import { DateTimePicker } from '@material-ui/pickers';
import EditableTable from 'src/components/EditableTable';
import * as constants from 'src/components/EditableTable/constants'
import { getCompanyDropdown } from 'src/services/company';

const useStyles = makeStyles(theme);


const MassSearch = (props) => {
    const classes = useStyles();


    let columns = useMemo(() => ({
        definitions: [
            { title: 'Prenume', name: 'firstName', type: constants.text, sortable: true },
            { title: 'Nume', name: 'lastName', type: constants.text, sortable: true },
            { title: 'Email', name: 'email', type: constants.text, sortable: true },
            { title: 'Functie', name: 'job', type: constants.text, sortable: true },
            { title: 'Activ', name: 'active', type: constants.checkbox, sortable: true, defaultValue: true },
            { title: 'Creare', name: 'creationDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
            { title: 'Companie', name: 'companyName', type: constants.selectServer, sortable: true, definition: { id: 'companyId', label: 'companyName' }, getOptions: getCompanyDropdown },

        ],

        isEditable: false,
        validatorSchema: {},
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'text', name: 'firstName', label: 'Prenume', size: 2, value: '' },
            { type: 'text', name: 'lastName', label: 'Nume', size: 2, value: '' },
            { type: 'text', name: 'email', label: 'Email', size: 2, value: '' },
            { type: 'text', name: 'job', label: 'Functie', size: 2, value: '' },
            { type: 'boolean', name: 'active', label: 'Activ', size: 1, value: '' },
            { type: 'range', name: 'range', startField: 'startDate', endField: 'endDate', label: 'Interval Creare', size: 3, value: '' },
            { type: 'dropdown', name: 'companyName', label: 'Companie', size: 3, value: '', getOptions: getCompanyDropdown }

        ],
    }), []);

    const [state, setState] = useState({ loading: false });


    const queries = useMemo(() => ({
        getTableDataPaginatedQuery: getAllCompanyUsersPaginated,
    }), [])

    const setLoading = (mode) => {
        setState(prevState => ({ ...prevState, loading: mode }))
    }


    function PaperComponent(props) {
        return (
            <Draggable handle="#draggable-dialog-title" >
                <Paper {...props} />
            </Draggable>
        );
    }

    // const getAllCompanyUsersPaginatedMemo = useMemo(() => getAllCompanyUsersPaginated, []);

    return (
        <Fragment
        >
            <Dialog
                open={true || props.isEditCompletion}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                // maxWidth={'sm'}
                fullScreen
            >
                <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                    <Typography variant='h5' className={classes.dialogTitle}>
                        Cauta utilizatori mass
                        {state.loading || props.loading ? <CircularProgress className={classes.circularProgress} /> : ''}
                    </Typography>
                </DialogTitle>
                <DialogContent key={1}>
                    <EditableTable key={2} columns={columns} queries={queries}
                    // loading={state.loading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={state.loading || props.loading} onClick={() => {
                        props.onConfirm();
                    }
                    }>Cauta</Button><Button onClick={props.close} disabled={state.loading || props.loading}>Anuleaza</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default compose(withRouter)(MassSearch);
