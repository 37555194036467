import * as config from '../../constants';
import "isomorphic-fetch";
import axios from 'axios';
import { downloadFile } from 'src/utils/downloadFile';



export function upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onUploadProgress,
        data: formData,
    }


    return axios({ url: `${config.apiUrl}/file/upload`, ...requestOptions })
}


export function download(filename, onDownloadProgress) {
    const requestOptions =
    {
        method: 'POST',
        // headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onDownloadProgress,
        responseType: 'arraybuffer'
        // data: formData,
    }


    return axios({ url: `${config.apiUrl}/file/download`, ...requestOptions })
        .then(response => { downloadFile(response.data, 'ActionReport.bmp', 'application/octet-stream') });
}

export function getAllDocumentsPaginated(page, size, filters, orderBy, orderDirection) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ page, elementsPerPage: size, filters, orderBy, orderDirection }),
    }
    return axios({ url: `${config.apiUrl}/file/getPaginated`, ...requestOptions })
}

export function createDocument(data) {
    let formData = new FormData();
    let fields = Object.keys(data);
    for (const field of fields) {
        let crtValue = data[field];
        if (field === 'companyId' && crtValue == undefined) {
            crtValue = 0;
        }
        formData.append(field, crtValue);
    }

    // formData.append("file", file);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        // onUploadProgress,
        data: formData,
    }


    return axios({ url: `${config.apiUrl}/file/create`, ...requestOptions })
}

export function updateDocument(data) {
    let formData = new FormData();
    let fields = Object.keys(data);
    for (const field of fields) {
        let crtValue = data[field];
        if (field === 'companyId' && crtValue == undefined) {
            crtValue = 0;
        }
        formData.append(field, crtValue);
    }

    // formData.append("file", file);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        // onUploadProgress,
        data: formData,
    }


    return axios({ url: `${config.apiUrl}/file/update`, ...requestOptions })
}

export function downloadDocument(data) {
    let formData = new FormData();
    let fields = Object.keys(data);
    for (const field of fields) {
        formData.append(field, data[field]);
    }

    // formData.append("file", file);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        responseType: 'arraybuffer',

        // onUploadProgress,
        data: formData,
    }


    return axios({ url: `${config.apiUrl}/file/download`, ...requestOptions }).
        then(response => {
            downloadFile(response.data, data.name, 'application/octet-stream');
            return new Promise((resolve, reject) => {

                resolve();

            });
        });
}

export function getDocumentAsBlob(data) {
    let formData = new FormData();
    let fields = Object.keys(data);
    for (const field of fields) {
        formData.append(field, data[field]);
    }

    // formData.append("file", file);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        responseType: 'arraybuffer',

        // onUploadProgress,
        data: formData,
    }


    return axios({ url: `${config.apiUrl}/file/download`, ...requestOptions });
}



export function deleteDocument(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/file/delete`, ...requestOptions })
}

export function getDocumentDropdown(name) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ name: name }),
    }
    return axios({ url: `${config.apiUrl}/file/getSelect`, ...requestOptions })
}

export function getReportDocuments(filter) {
    let formData = new FormData();
    formData.append('filters', JSON.stringify(filter));

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        responseType: 'arraybuffer',
        withCredentials: true,
        data: formData,
    }
    return axios({ url: `${config.apiUrl}/file/getReport`, ...requestOptions })
}




// import {downloadFile} from '../helpers/downloadFile'; // <= HERE
// export function fetchReport(token, sessionId) {
//   return dispatch => {
//     dispatch({type: 'FETCH_REPORT'});
//     axios.get(`${API_ROOT}/report/${sessionId}`, {
//       headers: {
//         Authorization: `Custom ${token}`
//       }
//     })
//       .then(response => {
//         dispatch({type: 'FETCH_REPORT_FULFILLED', payload: response.data});
//         downloadFile(response.data, 'ActionReport.csv', 'text/csv'); // <= HERE
//       })
//       .catch(err => {
//         dispatch({type: 'FETCH_REPORT_REJECTED', payload: err});
//       });
//   };
// }