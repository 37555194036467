import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    CircularProgress
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { compose } from "recompose";

const GdprDialog = (props) => {

    const { title, content, onOk, onCancel, visible, isLoading } = props;

    return (
        <Fragment>
            <Dialog
                open={visible}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'xs'}
                fullWidth
            >
                <DialogTitle >
                    <Typography variant='h4' style={{ verticalAlign: 'middle' }} component={'span'}>
                        {title}
                        {isLoading && <CircularProgress size={25} style={{ marginLeft: '7px', verticalAlign: 'middle' }} />}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ backgroundColor: '#f4f6f8' }}>
                    <Typography variant='body1'>
                        {content}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button disabled={props.isLoading} onClick={() => { onCancel() }
                    }>
                        Refuza</Button>

                    <Button color="primary"
                        disabled={props.isLoading}
                        onClick={() => {
                            onOk()
                        }}>Accepta</Button>

                </DialogActions>
            </Dialog>

        </Fragment >
    )



}


export default compose(withSnackbar)(GdprDialog);