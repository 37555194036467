import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    Typography,
    Paper,
    CircularProgress
} from '@material-ui/core';
import { downloadDocument, getAllDocumentsPaginated, getDocumentDropdown } from 'src/services/files';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { getQuizDropdown } from 'src/services/quiz';
import { LabelDivider } from "mui-label-divider";
import { Fragment } from 'react';
import { createContent, updateContent } from 'src/services/content';
import Draggable from 'react-draggable';
import QuizTester from 'src/components/Quiz'
import { withSnackbar } from 'notistack';
import theme from './styles'
import { getEmployeeDropdown } from 'src/services/users';
import { getCompanyDropdown } from 'src/services/company';
import * as constants from 'src/components/EditableTable/constants'
import { downloadFile } from 'src/utils/downloadFile';
import EditableTable from 'src/components/EditableTable';
import PdfViewer from 'src/components/PdfViewer';
import { getCurrentUserData } from 'src/services/user';
import DraggablePaper from 'src/components/DraggablePaper';

const useStyles = makeStyles(theme);

const DocumentPicker = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ pdfViewer: { open: false, name: '' }, loading: false, email: undefined, tableKey: 1 });


    const setLoading = (state) => {
        setState((prevState) => ({ ...prevState, loading: state }));
    }

    const refreshTable = () => {
        setState(prevState => {
            let tableKey = prevState.tableKey + 1;
            return { ...prevState, tableKey }
        })
    }

    const showError = (message) => {
        props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 60000,
            style: { whiteSpace: 'pre-line' },
            action: (key) => (
                <Button onClick={(e) => props.closeSnackbar(key)}>
                    <span style={{ color: 'white' }}>{'Inchide'}</span>
                </Button>
            )
        });
    }

    const setEmail = (email) => {
        setState(prevState => ({ ...prevState, email }));
    }

    useEffect(() => {
        setLoading(true);
        getCurrentUserData().then(({ data }) => {
            setEmail(data.email);
            setLoading(false);
        })
            .catch(error => {
                setLoading(false);
                console.error(error)
            })

    }, [])


    const columns = {
        definitions: [
            { title: 'Fisier', name: 'name', type: constants.file, sortable: true },
            { title: 'Companie asociata', name: 'companyName', type: constants.selectServer, sortable: true, definition: { id: 'companyId', label: 'companyName' }, getOptions: getCompanyDropdown },
            { title: 'Creat de', name: 'ownerUsername', type: constants.text, sortable: true, editableDisabled: true },
            { title: 'Creare', name: 'creationDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
        ],
        isEditable: false,
        validatorSchema: {},
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'text', name: 'name', label: 'Denumire', size: 3, value: '' },
            { type: 'dropdown', name: 'ownerUsername', label: 'Creat de', size: 3, defaultValue: { id: state.email, name: state.email }, getOptions: getEmployeeDropdown },
            { type: 'dropdown', name: 'companyName', label: 'Companie asociata', size: 3, value: '', getOptions: getCompanyDropdown },
        ],
        initialFilter: { 'ownerUsername': state.email },
        downloadAction: {
            label: 'Arata', requiredActionFields: ["name"], action: (requiredFields) => {
                openPdfViewer(requiredFields.name);
            }
        },
        downloadAction2: {
            label: 'Descarca', requiredActionFields: ["id", "name"], action: (requiredFields) => {
                setLoading(true);
                downloadDocument(requiredFields).then(() => {
                    setLoading(false);
                }).catch(() => {
                    showError('A aparut o eroare la descarcarea fisierului!')
                    setLoading(false);
                });
            }
        },
        customAction: {
            label: 'Alege', title: 'Alege Document', requiredActionFields: ["id", "name"], action: (requiredFields) => {
                props.add(requiredFields);
            }
        }
    };

    const showSuccess = (message) => {
        props.enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: 5000,
            style: { whiteSpace: 'pre-line' },

        });
    }

    const closePdfViewer = () => {
        setState(prevState => ({ ...prevState, pdfViewer: { open: false, name: '' } }));
    }

    const openPdfViewer = (pdfName) => {
        setState(prevState => ({ ...prevState, pdfViewer: { open: true, name: pdfName } }));
    }

    return (
        <Fragment>
            <Dialog
                open={props.isOpen}
                PaperComponent={DraggablePaper}
                maxWidth={'xl'}
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title123">
                    <Typography variant='h5' className={classes.dialogTitleText}>
                        Cautare Avansata Documente
                        {state.loading ? <CircularProgress className={classes.circularProgress} /> : ''}

                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {state.email ?
                        <EditableTable
                            columns={columns} queries={{
                                getTableDataPaginatedQuery: getAllDocumentsPaginated,
                            }}
                            loading={state.loading}
                        /> : ''}

                    <PdfViewer close={closePdfViewer} {...state.pdfViewer} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={state.loading} onClick={props.close}>Inchide</Button>
                </DialogActions>
            </Dialog>

        </Fragment >
    );
};

export default compose(withRouter, withSnackbar)(DocumentPicker);
