import React, { useState } from 'react';
import {
    Button,
    Container,
    Grid,
    makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { getAllCompaniesPaginated, deleteCompany, updateCompany, createCompany, getReportCompany } from 'src/services/company';
import * as constants from 'src/components/EditableTable/constants'
import EditableTable from 'src/components/EditableTable';
import { create } from 'underscore';
import schema from './schema';
import { downloadFile } from 'src/utils/downloadFile';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),

    }
}));

const Company = () => {
    const classes = useStyles();

    const [state, setState] = useState({ loading: false });

    const setLoading = (state) => {
        setState(prevState => ({ ...prevState, loading: state }));
    }

    let columns = {
        definitions: [
            { title: 'Nume', name: 'name', type: constants.text, sortable: true },
            { title: 'CUI', name: 'cui', type: constants.text, sortable: true },
            { title: 'Activ', name: 'active', type: constants.checkbox, sortable: true, defaultValue: true },
            { title: 'Creare', name: 'creationDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
        ],
        isDeleteDisabled: false,
        isEditable: true,
        validatorSchema: schema,
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'text', name: 'name', label: 'Nume', size: 3, value: '' },
            { type: 'text', name: 'cui', label: 'CUI', size: 3, value: '' },
            { type: 'boolean', name: 'active', label: 'Activ', size: 1, value: '' },

        ],
        actions: [{ label: 'Lista Angajati', link: '/app/company-user?companyId=${id}' }],
    };


    const getReport = (filter) => {
        setLoading(true);
        getReportCompany(filter).then(({ data }) => {
            downloadFile(data, "Raport_Companie.xlsx", 'application/octet-stream')
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }

    return (
        <Page
            className={classes.root}
            title="Companii"
        >




            <EditableTable columns={columns} queries={{
                getTableDataPaginatedQuery: getAllCompaniesPaginated,
                deleteTableData: deleteCompany,
                updateTableData: updateCompany,
                createTableData: createCompany
                // updateTableData: updateAlarmFilterData
                // , deleteTableData: deleteAlarmFilterData, createTableData: createAlarmFilterData
            }}
                getReport={getReport}
                loading={state.loading}
            />
        </Page>
    );
};

export default Company;
