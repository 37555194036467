import { createMuiTheme, FormControl, InputLabel, MenuItem, Select, TextField, ThemeProvider, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import RangeCalendar from 'src/components/RangeCalendar';
import ServerAutocomplete from 'src/components/ServerAutocomplete';



const filterComponent = (props) => {
    const theme = useTheme();

    const customTheme = createMuiTheme({

        overrides: {
            MuiInputLabel: {
                outlined: {
                    '&$shrink': { transform: 'translate(14px, -1.5px) scale(0.75)' },
                    transform: 'translate(14px, 17px) scale(1)',
                    color: '#546e7a'
                }
            },
            MuiFormLabel: {
                root: { fontSize: '0.950rem' }
            }


        },
        typography: {

        }
    });

    switch (props.type) {
        case 'text':
            return (
                <TextField margin="dense" variant="outlined" fullWidth {...props} size={'small'} onChange={(e) => props.onChange(e, props.name, e.target.value)} /> //{...props}
            )
        case 'dropdown':
            return (
                <ServerAutocomplete
                    {...props}
                    onChange={(e, changeVal) => {
                        if (props.useId) {
                            return props.onChange(e, props.name, changeVal?.id)

                        }
                        return props.onChange(e, props.name, changeVal?.name)
                    }}
                    variant='outlined'
                //isLoading={this.state.isLoading}
                // getOptions={props.getOptions}
                />
            )
        case 'boolean':
            return (
                <ThemeProvider theme={customTheme}>

                    <FormControl fullWidth variant="outlined" >
                        <InputLabel id="demo-simple-select-filled-label" style={{ backgroundColor: 'white' }}>{props?.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            {...props}
                            fullWidth
                            margin="dense" variant="outlined"
                            displayEmpty
                            // inputProps={{
                            //     style:{
                            //         marginTop: theme.spacing(2),
                            //     }
                            // }}
                            style={{ marginTop: '4px' }}
                            onChange={(e) => props.onChange(e, props.name, e.target.value)}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value=" ">
                                <em>Nesetat</em>
                            </MenuItem>
                            <MenuItem value={'true'}>Da</MenuItem>
                            <MenuItem value={'false'}>Nu</MenuItem>
                        </Select>
                    </FormControl>
                </ThemeProvider>

            )
        case 'visibleColumns':
            return (
                <ThemeProvider theme={customTheme}>

                    <FormControl fullWidth variant="outlined" >
                        <InputLabel id="demo-simple-select-filled-label" style={{ backgroundColor: 'white' }}>{props?.label}</InputLabel>
                        <Select
                            multiple
                            labelId="demo-simple-select-filled-label"
                            {...props}
                            fullWidth
                            margin="dense" variant="outlined"
                            displayEmpty
                            value={props?.visibleColumns || []}
                            style={{ marginTop: '4px' }}
                            onChange={(e) => {
                                props.setVisibleColumns(e.target.value)

                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {props.options.map(({ name, title }) => (
                                <MenuItem key={name} value={name} >
                                    {/* style={getStyles(name, personName, theme)} */}
                                    {title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ThemeProvider>

            )
        case 'range':
            {
                let value = undefined
                if (props?.filterValue && props?.filterValue[props?.startField]) {
                    value = []
                    value = [props.filterValue[props.startField]];
                    if (props.filterValue[props.endField]) {
                        value = [...value, props.filterValue[props.endField]]
                    }
                }

                return (
                    <RangeCalendar {...props} value={value} onChange={(e) => {
                        if (!e.value) {
                            props.onChange(e, props.startField, undefined);
                            props.onChange(e, props.endField, undefined);
                        }
                        else if (e.value.length >= 2) {
                            props.onChange(e, props.startField, e.value[0]);
                            props.onChange(e, props.endField, e.value[1]);
                        }
                        else {
                            props.onChange(e, props.startField, e.value);
                        }

                    }}/>
                )
            }
    }

}

export default filterComponent;