import React, { Component } from "react";

import { createDocument, getAllDocumentsPaginated, upload, updateDocument, deleteDocument, downloadDocument, getReportDocuments } from "src/services/files";
import Page from "src/components/Page";
import * as constants from 'src/components/EditableTable/constants'

import "./App.css";
import EditableTable from "src/components/EditableTable";
import schema from "./schema";
import theme from './styles';
import { Button, makeStyles } from "@material-ui/core";
import { compose } from "recompose";
import { withStyles, withTheme } from "@material-ui/styles";
import { downloadFile } from "src/utils/downloadFile";
import { withSnackbar } from "notistack";
import PdfViewer from "src/components/PdfViewer";
import { getEmployeeDropdown } from "src/services/users";
import { getCompanyDropdown } from "src/services/company";


class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);
        this.upload = this.upload.bind(this);

        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            filename: '',
            progress: 0,
            message: "",
            isError: false,
            fileInfos: [],
            loading: false,
            pdfViewer: { open: false, name: '' }
        };
    }
    setLoading = (state) => {
        this.setState({ loading: state });
    }

    handleClick = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    showError = (message) => {
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 60000,
            style: { whiteSpace: 'pre-line' },
            action: (key) => (
                <Button onClick={(e) => this.props.closeSnackbar(key)}>
                    <span style={{ color: 'white' }}>{'Inchide'}</span>
                </Button>
            )
        });
    }

    closePdfViewer = () => {
        this.setState({ pdfViewer: { open: false, name: '' } });
    }

    openPdfViewer = (pdfName) => {
        this.setState({ pdfViewer: { open: true, name: pdfName } });
    }

    columns = {
        definitions: [
            { title: 'Fisier', name: 'name', type: constants.file, sortable: true },
            { title: 'Companie asociata', name: 'companyName', type: constants.selectServer, sortable: true, definition: { id: 'companyId', label: 'companyName' }, getOptions: getCompanyDropdown },
            { title: 'Creat de', name: 'ownerUsername', type: constants.text, sortable: true, editableDisabled: true },
            { title: 'Creare', name: 'creationDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
        ],
        isDeleteDisabled: false,
        isEditable: true,
        validatorSchema: schema,//schema,
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'text', name: 'name', label: 'Denumire', size: 3, value: '' },
            { type: 'dropdown', name: 'ownerId', useId: true, label: 'Trimis de', size: 3, value: '', getOptions: getEmployeeDropdown },
            { type: 'dropdown', name: 'companyName', label: 'Companie asociata', size: 3, value: '', getOptions: getCompanyDropdown },
        ],
        downloadAction: {
            label: 'Arata', requiredActionFields: ["name"], action: (requiredFields) => {
                this.openPdfViewer(requiredFields.name);
            }
        },
        downloadAction2: {
            label: 'Descarca', requiredActionFields: ["id", "name"], action: (requiredFields) => {
                this.setLoading(true);
                downloadDocument(requiredFields).then(() => {
                    this.setLoading(false);
                }).catch(() => {
                    this.showError('A aparut o eroare la descarcarea fisierului!')
                    this.setLoading(false);
                });
            }
        }
    };


    selectFile(event) {
        let target = event.target;
        let eventFile = event.target.files;
        this.setState({
            selectedFiles: eventFile,
            filename: eventFile[0].name
        }, () => {
        });

    }

    upload() {
        let currentFile = this.state.selectedFiles[0];

        this.setState({
            progress: 0,
            currentFile: currentFile,
        });

        upload(currentFile, (event) => {
            this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
            });
        })
            .then((response) => {
                this.setState({
                    message: response.data.message,
                    selectedFiles: undefined,
                    isError: false
                });
            })
            .catch(() => {
                this.setState({
                    progress: 0,
                    message: "Could not upload the file!",
                    currentFile: undefined,
                    isError: true
                });
            });

        this.setState({
            selectedFiles: undefined,
        });
    }

    getReport = (filter) => {
        this.setLoading(true);
        getReportDocuments(filter).then(({ data }) => {
            downloadFile(data, "Raport_Documente.xlsx", 'application/octet-stream')
            this.setLoading(false);

        }).catch(error => {
            this.setLoading(false);
        })
    }

    render() {
        const {
            selectedFiles,
            currentFile,
            progress,
            message,
            fileInfos,
            isError
        } = this.state;
        const { classes } = this.props;

        return (
            <Page
                className={classes.root}
                title="Documente"
            >
                {/* <div className="mg20" style={{ position: 'relative', width: '600px', margin: 'auto' }}>
                    {currentFile && (
                        <Box className="mb25" display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <BorderLinearProgress variant="determinate" value={progress} />
                            </Box>
                            <Box minWidth={35}>
                                <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                            </Box>
                        </Box>)
                    }

                    <label htmlFor="btn-upload">
                        <input
                            id="btn-upload"
                            name="btn-upload"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={
                                (e) => {
                                    this.selectFile(e);
                                    // e.target.value = ""


                                }

                            }
                            onClick={this.handleClick}
                        />
                        <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span" >
                            Choose Files
                        </Button>
                    </label>
                    <div className="file-name">
                        {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
                    </div>
                    <Button
                        className="btn-upload"
                        color="primary"
                        variant="contained"
                        component="span"
                        disabled={!selectedFiles}
                        onClick={this.upload}>
                        Upload
        </Button>

                    <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                        {message}
                    </Typography>

                    <Typography variant="h6" className="list-header">
                        List of Files
          </Typography>
                    <ul className="list-group">
                        {fileInfos &&
                            fileInfos.map((file, index) => (
                                <ListItem
                                    divider
                                    key={index}>
                                    <a href={file.url}>{file.name}</a>
                                </ListItem>
                            ))}
                    </ul>
                </div >
                <Button
                    className="btn-choose"
                    variant="outlined"
                    onClick={download}
                    component="span" >
                    Download
          </Button>

                <Grid
                    container
                    spacing={1}
                    wrap="wrap"
                    direction="row"
                    alignItems="center"
                    alignContent='center'
                // justify="center"
                >
                    <Grid
                        // className={classes.item}
                        item
                        md={2}
                    // sm={6}
                    // xs={12}
                    >
                        <label htmlFor="btn-upload1">
                            <input
                                id="btn-upload1"
                                name="btn-upload1"
                                style={{ display: 'none' }}
                                type="file"
                                onChange={
                                    (e) => {
                                        this.selectFile(e);
                                        // e.target.value = ""


                                    }

                                }
                                onClick={this.handleClick}
                            />
                            <Button
                                className="btn-choose"
                                variant="outlined"
                                component="span" >
                                Alege Fisier
                        </Button>
                        </label>
                        <div className="file-name">
                            {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
                        </div>
                    </Grid>

                    <Grid
                        // className={classes.item}
                        item
                        md={4}
                    // sm={6}
                    // xs={12}
                    >
                        <TextField
                            margin='dense'
                            label='Nume fisier'
                            variant='outlined'
                            fullWidth
                            value={this.state.filename}
                            onChange={e => this.setState({ 'filename': e.target.value })}
                        />


                    </Grid>
                    <Grid
                        // className={classes.item}
                        item
                        md={2}
                    // sm={6}
                    // xs={12}
                    >


                    </Grid>
                </Grid> */}


                <EditableTable columns={this.columns} queries={{
                    getTableDataPaginatedQuery: getAllDocumentsPaginated,
                    createTableData: createDocument,
                    updateTableData: updateDocument,
                    deleteTableData: deleteDocument,
                }}
                    getReport={this.getReport}
                    loading={this.state.loading}
                />

                <PdfViewer close={this.closePdfViewer} {...this.state.pdfViewer} />
            </Page>
        );
    }
}

export default compose(withStyles(theme), withSnackbar)(UploadFiles)