import validate from 'validate.js';


export default {
  firstName: {
    presence: { allowEmpty: false, message: 'Prenumele este necesar!' },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'Numele este necesar!' },
  },
  email: {
    email: { message: 'Email-ul nu are un format corect!' }
  },
  job: {
    presence: { allowEmpty: false, message: 'Job-ul este necesar!' },
  },
  companyName: {
    presence: { allowEmpty: false, message: 'Compania este necesara!' },
  },

};
