import React, { useState } from 'react';
import {
    makeStyles
} from '@material-ui/core';
import * as constants from 'src/components/EditableTable/constants'
import EditableTable from 'src/components/EditableTable';
import { getAllSummaryContentPaginated, getReportSummaryContent } from 'src/services/content';
import { Fragment } from 'react';
import theme from './styles'
import { getCompanyDropdown } from 'src/services/company';
import { getCompanyUserDropdown, getCompanyUserQueryDropdown } from 'src/services/companyUser';
import { downloadFile } from 'src/utils/downloadFile';
import { getEmployeeDropdown } from 'src/services/users';

const useStyles = makeStyles(theme);

const SummaryContent = () => {

    const [state, setState] = useState({ loading: false });

    const setLoading = (state) => {
        setState(prevState => ({ ...prevState, loading: state }));
    }

    const getReport = (filter) => {
        setLoading(true);
        getReportSummaryContent(filter).then(({ data }) => {
            downloadFile(data, "Raport Sumar SSM&PSI.xlsx", 'application/octet-stream')
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }


    const classes = useStyles();
    let columns = {
        definitions: [
            { title: 'Nume', name: 'name', type: constants.text, sortable: true },
            { title: 'Quiz-uri finalizate', name: 'quizzesFinished', type: constants.checkbox, sortable: true },
            { title: 'Documente descarcate', name: 'documentsDownloaded', type: constants.checkbox, sortable: true },
            // { title: 'Prenume', name: 'firstName', type: constants.text, sortable: false },
            // { title: 'Nume', name: 'lastName', type: constants.text, sortable: false },
            // { title: 'Email', name: 'email', type: constants.text, sortable: true },
            { title: 'Trimis catre', name: 'sentToDetails', type: constants.text, sortable: true },
            { title: 'Redirectionat de', name: 'forwardedByDetails', type: constants.text, sortable: false },
            { title: 'Companie', name: 'companyName', type: constants.text, sortable: true },
            { title: 'Trimis de', name: 'sentByEmail', type: constants.text, sortable: true },
            { title: 'Data trimitere', name: 'sendDate', type: constants.datetimepicker, sortable: true },
            { title: 'Data finalizare', name: 'completionDate', type: constants.datetimepicker, sortable: true },
            { title: 'Data accesare', name: 'lastAccessDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
            { title: 'Data creare', name: 'creationDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
        ],
        isEditable: false,
        validatorSchema: {},
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'text', name: 'name', label: 'Nume', size: 2, value: '' },
            { type: 'boolean', name: 'documentsDownloaded', label: 'Documente descarcate', size: 3, value: '' },
            { type: 'boolean', name: 'quizzesFinished', label: 'Quizz-uri finalizate', size: 2, value: '' },
            { type: 'boolean', name: 'completionDate', label: 'Finalizat', size: 2, value: '' },
            { type: 'dropdown', name: 'companyName', label: 'Companie', size: 3, value: '', getOptions: getCompanyDropdown },
            { type: 'dropdown', name: 'companyUserId', label: 'Trimis catre', size: 3, value: '', getOptions: getCompanyUserQueryDropdown, useId: true },
            { type: 'dropdown', name: 'sentByEmail', label: 'Trimis de', size: 2, value: '', getOptions: getEmployeeDropdown },
            { type: 'dropdown', name: 'forwardedById', useId: true, label: 'Redirectionat de', size: 2, value: '', getOptions: getCompanyUserQueryDropdown },

        ],
    };

    return (
        <Fragment>
            <EditableTable columns={columns} queries={{
                getTableDataPaginatedQuery: getAllSummaryContentPaginated,
            }}
                getReport={getReport}
                loading={state.loading}
            />
        </Fragment>
    );
};

export default SummaryContent;