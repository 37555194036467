import { IconButton, TableCell, Tooltip, Typography } from '@material-ui/core';
import { Clear, Delete, Done, EditRounded } from '@material-ui/icons';
import getFormatedValue from './formatedData';
import { withSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { compose } from 'underscore';
import EditComponent from './EditComponent';


const TableAction = (props) => {

    const {
        columns, deleteMode, addMode, isLoading, row, index, error, editMode, setErrors, setValue, editField, setMultipleErrors } = props;


    const getCell = () => {
        if (!deleteMode.isDelete) {
            return (columns.definitions.map((c, i) =>
            (<TableCell>
                {(editMode.isEdit && editMode.editIndex === index) || (addMode.isAdd && index === 0) ?
                    <EditComponent field={c} values={editField} setValue={setValue}
                        setErrors={setErrors} setMultipleErrors={setMultipleErrors} errors={error} isLoading={isLoading}
                        schema={columns.validatorSchema} suggestions={columns.suggestions} debouncedGetLoadOptions={columns.debouncedGetLoadOptions}
                        dataType={columns.dataType} isMulti={columns.isMulti} /> : getFormatedValue(c, row)}
            </TableCell>)));
        }
        else if (deleteMode.isDelete) {
            return (
                <TableCell colSpan={columns.definitions.length}>
                    {deleteMode.deleteIndex === index ? <Typography variant='h5'>Sunteti sigur ca vreti sa stergeti aceasta inregistrare?</Typography> : ''}
                </TableCell>
            );
        }
    }


    return (
        <Fragment>
            { getCell()}
        </Fragment>
    )

}


export default compose(withSnackbar)(TableAction);