import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { createQuiz, deleteQuiz, getAllQuizzesPaginated, updateQuiz, getQuiz } from 'src/services/quiz';
import { Fragment } from 'react';
import Draggable from 'react-draggable';
import { withSnackbar } from 'notistack';
import theme from './styles'
import { Block, CheckCircleOutline, Remove, RemoveCircleOutline } from '@material-ui/icons';
import { isInteger } from 'lodash';


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles = makeStyles(theme);


const QuizEditor = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ name: "", minimumPercentage: 75, quizzes: [{ question: "", answers: [{ textContent: "", isCorrect: true }, { textContent: "", isCorrect: false }, { textContent: "", isCorrect: false }] }], loading: false });
    const { isEdit, isAdd, closeFunction, updateQuizFunction, refreshFunction, editId } = props;

    const addQuiz = () => {
        setState(prevState => {
            let newQuizzes = [...prevState.quizzes, {
                question: "", answers: [{ textContent: "", isCorrect: true }, { textContent: "", isCorrect: false }, { textContent: "", isCorrect: false }],
            }]
            return ({ ...prevState, quizzes: newQuizzes });
        })
    }

    const addAnswer = (quizIndex) => {
        setState(prevState => {
            let newQuizzes = [...prevState.quizzes];
            newQuizzes[quizIndex].answers = [...newQuizzes[quizIndex].answers, { textContent: "", isCorrect: false }]
            return ({ ...prevState, quizzes: newQuizzes });
        })
    }

    const removeAnswer = (quizIndex, answerIndex) => {
        setState(prevState => {
            let newQuizzes = [...prevState.quizzes];
            let newQuiz = { ...newQuizzes[quizIndex] }
            newQuiz.answers.splice(answerIndex, 1);
            newQuizzes[quizIndex] = newQuiz;
            return ({ ...prevState, quizzes: newQuizzes });
        })
    }

    const removeQuiz = (index) => {
        setState(prevState => {
            let newQuizzes = [...prevState.quizzes]
            // console.table(newQuizzes)
            newQuizzes.splice(index, 1);
            // console.table(newQuizzes)
            // console.log(index)
            return ({ ...prevState, quizzes: newQuizzes });
        })
    }

    useEffect(() => {
        if (editId && editId !== 0) {
            reset();

            getEditValues(editId);
        }
    }, [editId]);

    useEffect(() => {
        //reset
        if (isAdd) {
            reset();
        }
    }, [isAdd]);


    const reset = () => {
        setState(prevState => {
            return ({ name: "", minimumPercentage: 75, quizzes: [{ question: "", answers: [{ textContent: "", isCorrect: true }, { textContent: "", isCorrect: false }, { textContent: "", isCorrect: false }] }], loading: false });
        })
    }



    const setLoading = (state) => {
        setState(prevState => {
            return ({ ...prevState, loading: state });
        })
    }

    const getEditValues = (id) => {
        setLoading(true);
        getQuiz(id).then(({ data }) => {
            setState(prevState => {
                return ({ ...prevState, minimumPercentage: data.minimumPercentage, creationDate: data.creationDate, name: data.name, quizzes: data.questionDtoList, loading: false });
            })
        }).catch(error => {
            console.error(error)
            setLoading(false);
        });
    }

    const validate = (questionDtoList) => {
        let errors = "";
        let i = 1;
        for (const questionDto of questionDtoList) {
            const questionEqualsAnswer = questionDto.answers.some(a => a.textContent == questionDto.question)
            const answers = questionDto.answers.map(a => a.textContent)
            const answersEqualEachother = answers.some(x => answers.indexOf(x) !== answers.lastIndexOf(x));

            if (questionEqualsAnswer || answersEqualEachother) {
                if (errors.length === 0) {
                    errors += "Erroare intrebare si/sau raspuns duplicat pentru intrebarea: "
                }
                else {
                    errors += ", ";
                }
                errors += i;
            }
            i++;
        }
        const questions = questionDtoList.map(q => q.question);
        const identicalQuestions = questions.some(x => questions.indexOf(x) !== questions.lastIndexOf(x));
        if (identicalQuestions) {
            if (errors.length > 0) {
                errors += "\n";
            }
            else {
                errors += "Intrebarile nu sunt unice!";
            }
        }
        if (errors?.length) {
            return errors;
        }
        else {
            return undefined;
        }


    }



    const updateQuestion = (e, index) => {
        const name = e?.target?.name;
        const value = e?.target?.value;

        setState(prevState => {
            let newQuizzes = [...prevState.quizzes]
            let newQuizAtIndex = { ...newQuizzes[index] };
            newQuizAtIndex[name] = value;
            newQuizzes[index] = newQuizAtIndex
            return ({ ...prevState, quizzes: newQuizzes });
        })
    }

    const updateAnswer = (e, answerIndex, questionIndex) => {
        const value = e?.target?.value;

        setState(prevState => {
            let newQuizzes = [...prevState.quizzes]
            let newQuizAtIndex = { ...newQuizzes[questionIndex] };
            let newAnswers = [...newQuizAtIndex.answers]
            newAnswers[answerIndex] = { ...newAnswers[answerIndex], textContent: value };
            newQuizAtIndex.answers = newAnswers;
            newQuizzes[questionIndex] = newQuizAtIndex
            return ({ ...prevState, quizzes: newQuizzes });
        })
    }

    const updateCorrectAnswer = (answerIndex, questionIndex) => {

        setState(prevState => {
            let newQuizzes = [...prevState.quizzes]
            let newQuizAtIndex = { ...newQuizzes[questionIndex] };
            let newAnswers = [...newQuizAtIndex.answers]
            newAnswers[answerIndex] = {
                ...newAnswers[answerIndex], isCorrect: !newAnswers[answerIndex].isCorrect
            };
            newQuizAtIndex.answers = newAnswers;
            newQuizzes[questionIndex] = newQuizAtIndex
            return ({ ...prevState, quizzes: newQuizzes });
        })
    }


    const changeName = (e) => {
        const name = e?.target?.name;
        const value = e?.target?.value;
        setState(prevState => {
            return ({ ...prevState, name: value });
        })
    }

    const changeMinimumPercentage = (e) => {
        const value = e?.target?.value;
        if (value.length <= 0) {
            setState(prevState => {
                return ({ ...prevState, minimumPercentage: '' });
            })
            return;
        }
        else if (value <= 0) {
            return;
        }
        else if (!isInteger(+value)) {
            return;
        }
        setState(prevState => {
            return ({ ...prevState, minimumPercentage: +value });
        })
    }


    return (
        <Fragment>

            <Dialog
                open={isEdit || isAdd}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'xl'}
                fullWidth

            >
                <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                    <Typography variant='h5' className={classes.dialogTitleText}>
                        {isEdit ? "Editeaza Quiz" : ""}
                        {isAdd ? "Adauga Quiz" : ""}
                        {state.loading ? <CircularProgress className={classes.circularProgress} /> : ''}

                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <br />
                    <Grid
                        container
                        spacing={3}
                        wrap="wrap"
                    >
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Card>
                                <CardHeader
                                    title="Date generale"
                                />
                                <Divider />
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={6}
                                        wrap="wrap"
                                    >

                                        <Grid
                                            item
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                md={2}
                                                sm={2}
                                                xs={2}
                                            >

                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    id="intrebare"
                                                    label="Nume quiz"
                                                    disabled={state.loading}
                                                    onChange={changeName}
                                                    value={state?.name}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                sm={2}
                                                xs={2}
                                            >

                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    id="intrebare"
                                                    label="Procent minim"
                                                    type='number'
                                                    // inputProps={{ min: '0', step: '1', pattern: "\\d*" }}
                                                    disabled={state.loading}
                                                    onChange={changeMinimumPercentage}
                                                    value={state?.minimumPercentage}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>
                        {state?.quizzes?.map((quiz, index) =>
                        (
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={6}
                            >
                                <Card className={classes.quizCard}>
                                    <CardHeader
                                        title={"Intrebare: " + (index + 1)}
                                    />
                                    <Divider />
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={1}
                                            item
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            wrap="wrap"
                                        >
                                            <Grid
                                                item
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"

                                                    id="intrebare"
                                                    label="Intrebare"
                                                    rowsMin={3}
                                                    multiline
                                                    name="question"
                                                    disabled={state.loading}
                                                    value={quiz?.question}
                                                    onChange={e => updateQuestion(e, index)}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            {quiz.answers.map((answer, answerIndex) =>
                                                <Grid
                                                    item
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    container
                                                    justifyContent="center"
                                                    alignContent='center'
                                                    alignItems='center'
                                                >
                                                    <Grid
                                                        item
                                                        md={10}
                                                        sm={10}
                                                        xs={10}

                                                    >
                                                        <TextField
                                                            fullWidth
                                                            id={"raspuns" + (answerIndex + 1)}
                                                            size="small"
                                                            label={(answerIndex + 1) + ". Raspuns " + (answer.isCorrect ? "Corect" : "Gresit")}
                                                            multiline
                                                            name="answer"
                                                            disabled={state.loading}
                                                            value={answer.textContent}
                                                            onChange={e => updateAnswer(e, answerIndex, index)}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={1}
                                                        sm={1}
                                                        xs={1}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <IconButton onClick={(e) => { updateCorrectAnswer(answerIndex, index) }} color='primary'>
                                                            {answer.isCorrect ? <CheckCircleOutline style={{ color: '#4caf50' }} /> : <Block style={{ color: '#f44336' }} />}
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={1}
                                                        sm={1}
                                                        xs={1}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <IconButton onClick={(e) => { removeAnswer(index, answerIndex) }} color='primary'>
                                                            <RemoveCircleOutline />
                                                        </IconButton>
                                                    </Grid>

                                                </Grid>
                                            )}


                                        </Grid>
                                    </CardContent>
                                    <Fragment>
                                        <Divider />
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            p={1}
                                        >
                                            <Button onClick={(e) => addAnswer(index)}
                                                disabled={state.loading}
                                                color="primary"
                                            >Adauga raspuns</Button>
                                            {index === state?.quizzes?.length - 1 &&

                                                <Button onClick={addQuiz}
                                                    disabled={state.loading}
                                                    color="primary"

                                                >Adauga Intrebare</Button>
                                            }
                                            {index > 0 ? (

                                                <Button onClick={(e) => removeQuiz(index)}
                                                    disabled={state.loading}

                                                >Sterge</Button>
                                            )

                                                : ''}
                                        </Box>
                                    </Fragment>
                                </Card>



                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                        onClick={() => {
                            closeFunction();
                            reset();
                        }
                        }
                    >
                        Anuleaza
                    </Button>
                    <Button onClick={() => {
                        const quizzes = state.quizzes;
                        let errorDetails = '';
                        let hasError;
                        if (!state.name) {
                            errorDetails += "Nu s-a completat numele quiz-ului\n ";
                            hasError = true;
                        }
                        if (state.minimumPercentage.toString().length <= 0) {
                            errorDetails += "Nu s-a completat procentul minim\n ";
                            hasError = true;
                        }
                        else if (state.minimumPercentage <= 0 || state.minimumPercentage > 100) {
                            errorDetails += "Procentul minim nu este intre 0-100\n ";
                            hasError = true;
                        }
                        for (let i = 0; i < quizzes.length; i++) {
                            let quiz = quizzes[i];
                            if (quiz.answers.some(a => !a.textContent)) {
                                errorDetails += "Nu s-au completat toate datele pentru intrebarea: " + (i + 1) + "\n";
                                hasError = true;
                            }
                            if (!quiz.answers.some(a => a.isCorrect)) {
                                errorDetails += "Nu s-a selectat raspunsul corect pentu intrebarea: " + (i + 1) + "\n";
                                hasError = true;
                            }
                            if (quiz.answers.length < 3) {
                                errorDetails += "Nu s-a respectat limita de 3 raspunsuri pentu intrebarea: " + (i + 1) + "\n";
                                hasError = true;
                            }
                        }
                        if (hasError) {
                            props.enqueueSnackbar('Datele nu sunt valide!\n' + errorDetails, {
                                variant: 'error',
                                autoHideDuration: 4000,
                                style: { whiteSpace: 'pre-line' },
                                action: () => (
                                    <Button onClick={(e, key) => props.closeSnackbar(key)}>
                                        <span style={{ color: 'white' }}>{'Inchide'}</span>
                                    </Button>
                                )
                            });
                            return;
                        }


                        let errorsValidator = validate(state.quizzes);
                        if (errorsValidator) {
                            props.enqueueSnackbar(errorsValidator, {
                                variant: 'error',
                                autoHideDuration: 60000,
                                style: { whiteSpace: 'pre-line' },
                                action: (key) => (
                                    <Button onClick={(e) => props.closeSnackbar(key)}>
                                        <span style={{ color: 'white' }}>{'Inchide'}</span>
                                    </Button>
                                )
                            });

                            return;
                        }

                        if (isAdd) {
                            createQuiz({ name: state.name, minimumPercentage: state.minimumPercentage, questionDtoList: state.quizzes }).then(() => {
                                closeFunction();
                                refreshFunction();
                                reset();
                            }
                            ).catch(e => {
                                let message = 'Nu s-au putut adauga noile date!\n';
                                if (e?.response?.data?.message) {
                                    message = e?.response?.data?.message;
                                }

                                props.enqueueSnackbar(message, {
                                    variant: 'error',
                                    autoHideDuration: 60000,
                                    style: { whiteSpace: 'pre-line' },
                                    action: (key) => (
                                        <Button onClick={(e) => props.closeSnackbar(key)}>
                                            <span style={{ color: 'white' }}>{'Inchide'}</span>
                                        </Button>
                                    )
                                });
                            });
                        }
                        else if (isEdit) {
                            updateQuiz({ id: editId, creationDate: state.creationDate, minimumPercentage: state.minimumPercentage, name: state.name, questionDtoList: state.quizzes }).then(() => {
                                closeFunction();
                                refreshFunction();
                                reset();
                            }
                            ).catch(e => {
                                let message = 'Nu s-au putut modifica date!\n';
                                if (e?.response?.data?.message) {
                                    message = e?.response?.data?.message;
                                }

                                props.enqueueSnackbar(message, {
                                    variant: 'error',
                                    autoHideDuration: 60000,
                                    style: { whiteSpace: 'pre-line' },
                                    action: (key) => (
                                        <Button onClick={(e) => props.closeSnackbar(key)}>
                                            <span style={{ color: 'white' }}>{'Inchide'}</span>
                                        </Button>
                                    )
                                });
                            })
                        }


                    }}


                        color="primary"
                        disabled={state.loading}
                    >
                        Salveaza
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment >


    );
};

export default compose(withRouter, withSnackbar)(QuizEditor);
