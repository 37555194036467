import { Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import FilterComponent from './FilterComponent';


const FilterTable = (props) => {

    const { hasFilter, filterDefinitions, updateFunction, filterValue } = props;

    if (!hasFilter) {
        return (<Fragment></Fragment>);
    }

    const filters = filterDefinitions.map(def =>
        (def.size > 0) ?
            (
                <Grid item key={def.name} xs={def.size}>
                    <FilterComponent onChange={updateFunction} setVisibleColumns={props.setVisibleColumns} filterValue={filterValue} visibleColumns={props.visibleColumns} {...def} defaultValue={filterValue[def.name]} value={filterValue[def.name]} disabled={props.disabled} />
                </Grid>
            ) : <Fragment></Fragment>
    );

    return (
        // <fieldset style={{ borderRadius: '2px', width: 'inherit', border: '1px solid rgb(162, 167, 172)', marginTop: '10px', marginBottom: '10px', marginLeft: '15px', marginRight: '15px', paddingBottom: '4px', paddingTop: '0px' }}>
        //     <legend style={{
        //         backgroundColor: "white",
        //         position: "absolute",
        //         top: "-2px",
        //         padding: "revert"
        //     }}> Filter</legend>

        <Grid container spacing={1} style={{ paddingRight: '15px', paddingLeft: '15px', width: 'inherit' }} justify='start' alignItems='center'>
            {filters}
        </Grid>
        // </fieldset>
    );


}


export default FilterTable;