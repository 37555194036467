import validate from 'validate.js';


export default {
  email: {
    // presence: { allowEmpty: false, message: 'Email-ul este necesar!' },
    email: {message:'Email-ul nu are un format corect!'}
  },
  password: {
    presence: { allowEmpty: false, message: 'Parola este necesara!' },
  },


};
