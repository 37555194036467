import axios from 'axios';
import { apiUrl } from '../constants';
import history from './history';

let isRefreshing = false;
let refreshSubscribers = [];


export function instantiateAxios() {
    axios.defaults.baseURL = apiUrl;
    axios.interceptors.response.use(response => {
        console.log('interceptor');
        return response;
    }, error => {
        const { config, response: { status } } = error;
        const originalRequest = config;
        const error_description = error.response.data;
        if (status === 401 && !config.url.includes('/auth/refresh') && !originalRequest?.hasRetry) {
            if (!isRefreshing) {
                isRefreshing = true;
                refreshAccessToken()
                    .then(newToken => {
                        isRefreshing = false;
                        onRrefreshed(newToken)
                    });
            }
            const retryOrigReq = new Promise((resolve, reject) => {
                subscribeTokenRefresh(token => {
                    originalRequest.hasRetry = true;
                    resolve(axios.request(originalRequest));
                });
            })
            return retryOrigReq;
        }
        else if (status === 401 && config.url.includes('/auth/refresh')) {
            history.push("/login?session_expired=true");
            refreshSubscribers = [];
            isRefreshing = false;
            return Promise.reject(error)
        }
        else {
            return Promise.reject(error);
        }
    });
}


function subscribeTokenRefresh(cb) {

    refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
    refreshSubscribers.map(cb => cb(token))
    refreshSubscribers = [];

}

function refreshAccessToken() {
    console.log('refresh access token');

    //const user = JSON.parse(localStorage.getItem('user'));
    //const refreshToken = user.refreshToken;
    //const accessToken = user.token;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
        //'Authorization': accessToken, 
        // data: JSON.stringify({ refreshToken }),
    };
    return axios.request({ 'url': `${apiUrl}/auth/refresh?timestamp=${new Date().getTime()}`, ...requestOptions })
        .then(user => {
            // if (user.data.token) {
            //     localStorage.setItem('user', JSON.stringify(user.data));
            //     // let dt = new Date();
            //     // dt.setSeconds( user.refresh_token_expires_in + dt.getSeconds() );
            //     // localStorage.setItem('must_expire',dt);
            // }
            // return user;
            return;
        })
    // .catch(e => {
    //     history.push("/login");
    //     return;
    // });
}

