import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Password from './Password';
import theme from './styles';
import Notifications from './Notifications';
import { getNotifications } from 'src/services/notifications';
import { Fragment } from 'react';
import { hasAnyOfAuthorities } from 'src/utils/authUtils';
import * as constants from 'src/components/EditableTable/constants'
import { getAllAsyncEmailsPaginated } from 'src/services/asyncEmail';
import EditableTable from 'src/components/EditableTable';

const useStyles = makeStyles(theme);

const SettingsView = () => {
  const classes = useStyles();
  const [state, setState] = useState({});

  let columns = {
    definitions: [
      { title: 'Trimis de', name: 'senderEmail', type: constants.text, sortable: false },
      { title: 'Redirectionat de', name: 'forwarderEmail', type: constants.text, sortable: false },
      { title: 'Prenume', name: 'toFirstName', type: constants.text, sortable: false },
      { title: 'Nume', name: 'toLastName', type: constants.text, sortable: false },
      { title: 'Email', name: 'toEmail', type: constants.text, sortable: false },
      { title: 'Companie', name: 'toCompany', type: constants.text, sortable: false },
      { title: 'Incercari trimitere', name: 'retryCount', type: constants.numeric, sortable: true, editableDisabled: true },
      { title: 'Ultima incercare', name: 'retryDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
      { title: 'Data trimitere', name: 'sendDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
      { title: 'Denumire', name: 'contentName', type: constants.text, sortable: false, editableDisabled: true },
      { title: 'Ultima eroare', name: 'lastErrorMessage', type: constants.text, sortable: true, editableDisabled: true },

    ],
    isEditable: false,
    validatorSchema: {},
    defaultPageSize: 5,
    pageSizes: [5, 10, 15],
    filterDefinitions: [
      { type: 'text', name: 'toEmail', label: 'Email', size: 2, value: '' },
      { type: 'text', name: 'senderEmail', label: 'Trimis de', size: 2, value: '' },
      { type: 'text', name: 'forwarderEmail', label: 'Redirectionat de', size: 2, value: '' },
      { type: 'text', name: 'contentName', label: 'Denumire', size: 2, value: '' },
      { type: 'text', name: 'lastErrorMessage', label: 'Ultima eroare', size: 2, value: '' },
      // { type: 'boolean', name: 'completed', label: 'Finalizat', size: 3, value: '' },
    ],


  };


  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      {/* <Notifications /> */}
      <Box>
        <Password />
        <br/>
        <Card>
          <CardHeader
            title="Status mail-uri"
            subheader="Mail-uri trimitere multipla"
          />
          <Divider />
          <CardContent>
            <EditableTable columns={columns} queries={{
              getTableDataPaginatedQuery: getAllAsyncEmailsPaginated,
            }}

              // customActions={getCustomActions}
              isDeleteDisabled
              isEditDisabled
              // getReport={getReport}
              loading={state.loading}
            />
          </CardContent>
        </Card>
        {hasAnyOfAuthorities("ROLE_ADMIN") ?
          <Fragment>
            <br />
            <Notifications />
          </Fragment>
          : ''}
      </Box>
    </Page>
  );
};

export default SettingsView;
