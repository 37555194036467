export const radialConfig = {

    chart: {
        height: 350,
        type: 'radialBar',
    },
    plotOptions: {
        radialBar: {
            hollow: {
                size: '70%',
            }
        },
    },
    labels: ['Rezultat'],
    // , '#3f51b5'
    // colors: ['white']

}

