import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { useState } from 'react';
import "primereact/resources/primereact.min.css";                  //core css
import "primereact/resources/themes/mdc-light-deeppurple/theme.css";  //theme
import "primeicons/primeicons.css";                                //icons
import { Grid } from '@material-ui/core';
import './style.css';

const RangeCalendar = (props) => {



    addLocale('ro', {
        firstDayOfWeek: 1,
        dayNames: ['Duminica', 'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata'],
        dayNamesShort: ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'],
        monthNamesShort: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Azi',
        clear: 'Sterge'
    });

    return (
        <>
            <span className="p-float-label" style={{ marginTop: '3px' }}>
                <Calendar id="range" value={props.value} appendTo={document.body} 
                autoZIndex={true} baseZIndex={9999} showButtonBar style={{ width: '100%', height: '40px' }} inputStyle={{ fonstSize: '16px', borderColor: '#c4c4c4', color: '#263238' }}
                    onChange={(e) => {
                        props.onChange(e);

                    }} selectionMode="range" readOnlyInput locale='ro' showTime hourFormat="24" numberOfMonths={2} />
                <label style={{ color: '#617983', fontWeight: '400', fontFamily: 'Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif' }} htmlFor="range">{props.label}</label>
            </span>
        </>
    )
}

export default RangeCalendar;