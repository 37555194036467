import { Paper } from "@material-ui/core"
import Draggable from "react-draggable"


export default (props) => {

    return (
        <Draggable handle="#draggable-dialog-title123" enableUserSelectHack={false} cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}