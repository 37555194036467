import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { instantiateAxios } from './utils/interceptor';
// import history from './utils/history';

instantiateAxios();

ReactDOM.render((
    <App />
), document.getElementById('root'));

// serviceWorker.unregister();
