import { apiUrl } from '../../constants';
import "isomorphic-fetch"
import history from 'src/utils/history';

export function login(username, password) {

    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
        credentials: 'include'

    };


    return fetch(`${apiUrl}/auth/login`, requestOptions,)
        .then(handleResponse)
        .then(user => {
            if (user.authTokenExpirationSeconds && user.authTokenExpirationSeconds >= 0) {
                let t = new Date();
                t.setSeconds(user.authTokenExpirationSeconds);
                localStorage.setItem('authTokenExpiration', JSON.stringify(+t));
                let time = localStorage.getItem('authTokenExpiration');
                console.log(new Date(parseInt(time)));

            }
            if (user.refreshTokenExpirationSeconds && user.refreshTokenExpirationSeconds >= 0) {
                let t = new Date();
                t.setSeconds(user.refreshTokenExpirationSeconds);
                localStorage.setItem('refreshTokenExpiration', JSON.stringify(+t));
                let time = localStorage.getItem('refreshTokenExpiration');
                console.log(new Date(parseInt(time)));
            }
            if (user.authorities) {
                localStorage.setItem('authorities', JSON.stringify(user.authorities));
            }



            return user;
        });
}


export function logout() {
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include'

    };
    return fetch(`${apiUrl}/auth/logout`, requestOptions)
        .then(() => {
            localStorage.removeItem('authTokenExpiration');
            localStorage.removeItem('refreshTokenExpiration');
            localStorage.removeItem('authorities');
            history.push("/login")
        }).catch(error => history.push("/login"));



}

function handleResponse(response) {
    return response.text().then(text => {
        let data = text;
        try {
            data = JSON.parse(text);
        }
        catch (err) {

        }
        if (!response.ok) {
            if (response.status === 401) {
                try {
                    localStorage.removeItem('authTokenExpiration');
                    localStorage.removeItem('refreshTokenExpiration');
                }
                catch (err) {

                }
            }
            else {
                return Promise.reject(data);
            }
            const error = 'Status:' + response.status + ',Message:' + data.error_description
            return Promise.reject(error);
        }
        return data;
    });
}