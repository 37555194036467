import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: colors.indigo[500]
    },
    secondary: {
      main: '#39dc36',
    },
    extra: {
      main: '#f44336',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  overrides: {
    MuiFormHelperText: {
      contained: { whiteSpace: 'pre-line' }
    },
    MuiInputLabel: {
      root: { fontSize: '0.950rem' }
    },
    MuiFormLabel: {
      root: { fontSize: '0.950rem' }
    },
    MuiAutocomplete: {
      option: { whiteSpace: 'pre-line' }
    },
    MuiTableCell: {
      sizeSmall: { padding: "6px 16px 6px 10px" }
    },
    MuiCardContent: {
      root: {
        padding: '8px',
        '&:last-child': { paddingBottom: '12px' }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    }
  },
  shadows,
  typography
});

export default theme;
