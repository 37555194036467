import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'underscore';
import styles from './styles';
import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';

const ServerAutocomplete = props => {
  const [open, setOpen] = React.useState(false);
  const initialOptions = props.defaultValue ? [props.defaultValue] : [];
  const [options, setOptions] = React.useState([...initialOptions]);
  const [loading, setLoading] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  let label = props.label;
  let variant = props.variant;
  let error = props.error;
  let hasError = props.hasError;
  let value = props.value;

  const getDataDebounced = useCallback(
    _.debounce(name => {
      if (!name) {
        return;
      }

      // console.log('name', name);
      // console.log('currentValue', currentValue);
      // console.log('props.value', props.value);
      // console.log('options', options);

      if (name === props?.value?.name) {
        return;
      }

      if (options.some(o => o.name === name)) {
        return;
      }

      setLoading(true);
      props
        .getOptions(name)
        .then(({ data }) => {
          setOptions(data);
          setLoading(false);
        })
        .catch(error => {
          console.error(error);
          setLoading(false);
        });
    }, 400),
    []
  );

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    } else {
    }
  }, [open]);

  // console.log('props in server autocomplete:', props);
  // console.log('value:', value);
  // console.log('defaulvalue:', props.defaultValue);

  if (value == defaultValue) {
    value = undefined;
  }

  let defaultValue = props.defaultValue;
  if (typeof defaultValue === 'string') {
    defaultValue = { id: defaultValue, name: defaultValue };
  }

  if (typeof value === 'string') {
    value = { id: value, name: value };
  }

  //   if (open) {
  //     value = undefined;
  //     defaultValue = undefined;
  //   }

  if (value) {
    defaultValue = undefined;
  }

  //   if (inputValue) {
  //     value = inputValue;
  //   }

  return (
    <Autocomplete
      // id="asynchronous-demo"
      fullWidth
      open={open}
      disabled={props.disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      style={
        props.style
          ? { ...props.style, whiteSpace: 'pre' }
          : { whiteSpace: 'pre' }
      }
      noOptionsText="Fara rezultat"
      loadingText="Se cauta"
      getOptionSelected={(option, value) =>
        option?.name === value?.name || option?.name === value
      }
      getOptionLabel={option => option.name || ''}
      options={options}
      loading={loading}
      //   inputValue={inputValue}
      onChange={(e, newValue) => {
        setCurrentValue(newValue?.name);
        console.log('onChange');
        if (props.onChange) {
          props.onChange(e, newValue);
        }
      }}
      //   defaultValue={defaultValue || currentValue} //{id:1,name:"name"}
      //   value={value}
      defaultValue={value}
      onInputChange={(event, newInputValue) => {
        if (options.some(v => v?.name === newInputValue)) {
          return;
        }
        // setInputValue(newInputValue);
        // console.log('input value', newInputValue);
        getDataDebounced(newInputValue);
      }}
      filterOptions={(options, key) => {
        if (!key?.inputValue || !key?.inputValue?.trim()?.length) {
          return [];
        }
        return options;
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            margin="dense"
            fullWidth
            label={label}
            multiline
            variant={variant} //"outlined"
            error={hasError} //{hasError}
            helperText={error} //{error}
            style={
              params.style
                ? { ...params.style, whiteSpace: 'pre' }
                : { whiteSpace: 'pre' }
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        );
      }}
    />
  );
};

export default compose(withStyles(styles), withSnackbar)(ServerAutocomplete);
