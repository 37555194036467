import * as constants from '../constants'
import { format } from 'date-fns';

const getDefaultData = (type) => {
    if (type === constants.checkbox) {
        return false;
    }
    else if (type === constants.datepicker) {
        return new Date().toISOString();
    }
    else if (type === constants.datetimepicker) {
        return new Date().toISOString();
    }
    else if (type === constants.timepicker) {
        const value = format(new Date(), 'HH:mm:ss');
        return value;
    }
    else if (type === constants.minMillis) {
        const value = randomIntFromInterval(1000, 2500);
        return value;
    }
    else if (type === constants.maxMillis) {
        const value = randomIntFromInterval(3000, 10000);
        return value;
    }
    else {
        return '';
    }
}

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}


export default getDefaultData;