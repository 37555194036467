import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { createQuiz, deleteQuiz, getAllQuizzesPaginated, updateQuiz, getQuiz } from 'src/services/quiz';
import { Fragment } from 'react';
import Draggable from 'react-draggable';
import { withSnackbar } from 'notistack';
import theme from './styles'
import * as constants from 'src/components/EditableTable/constants'
import EditableTable from 'src/components/EditableTable';
import { downloadTemplate, getCompanyUserByCompanyAndQueryDropdown, getCompanyUserDropdown, getCompanyUserQueryDropdown, uploadUsersExcel } from 'src/services/companyUser';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { getCompanyDropdown } from 'src/services/company';
import Tooltip from "@material-ui/core/Tooltip";
import ReplayIcon from '@material-ui/icons/Replay';
import { forwardContent, forwardContentToExistingUser } from 'src/services/content';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles = makeStyles(theme);


const UserSearchImporter = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ loading: false, company: undefined, page: 0, data: [], tableRefreshKey: 1 });
    const { isOpen, closeFunction, refreshFunction } = props;

    const showError = (error) => {
        props.enqueueSnackbar(error, {
            variant: 'error',
            autoHideDuration: 60000,
            style: { whiteSpace: 'pre-line' },
            action: (key) => (
                <Button onClick={(e) => props.closeSnackbar(key)}>
                    <span style={{ color: 'white' }}>{'Inchide'}</span>
                </Button>
            )
        });
    }

    const showWarning = (warning) => {
        props.enqueueSnackbar(warning, {
            variant: 'warning',
            autoHideDuration: 60000,
            style: { whiteSpace: 'pre-line' },
            action: (key) => (
                <Button onClick={(e) => props.closeSnackbar(key)}>
                    <span style={{ color: 'white' }}>{'Inchide'}</span>
                </Button>
            )
        });
    }


    const showSuccess = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'success' });
    }



    // useEffect(() => {
    //     //reset
    //     if (isAdd) {
    //         reset();
    //     }
    // }, [isAdd]);

    const setLoading = (state) => {
        setState(prevState => {
            return ({ ...prevState, loading: state, tableRefreshKey: (prevState.tableRefreshKey + 1) });
        })
    }

    let columns = {
        definitions: [
            { title: 'Linie', name: 'line', type: constants.numeric },
            { title: 'Erori', name: 'error', type: constants.text }
        ],

        isEditable: false,
        validatorSchema: {},
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
    };


    const setSelectedUser = (selectedUser) => {
        setState(prevState => ({ ...prevState, selectedUser }));
    }


    return (
        <Fragment>

            <Dialog
                open={isOpen}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'sm'}
                fullWidth

            >
                <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                    <Typography variant='h5' className={classes.dialogTitleText}>
                        Trimite catre utilizatori existenti
                        {state.loading ? <CircularProgress className={classes.circularProgress} /> : ''}

                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <br />
                    <Grid
                        container
                        spacing={3}
                        wrap="wrap"
                    >
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <ServerAutocomplete
                                onChange={(e, changeVal) => {
                                    setSelectedUser(changeVal);
                                }}
                                variant='outlined'
                                label="Utilizator"
                                margin="dense"
                                name="companyUser"
                                disabled={state.loading}
                                isLoading={props.loading || state.loading}
                                value={state.selectedUser}
                                getOptions={getCompanyUserByCompanyAndQueryDropdown}
                            />
                            <br />
                            <Button
                                onClick={() => {
                                    setLoading(true);
                                    forwardContentToExistingUser({ id: state.selectedUser.id }).then(() => {
                                        refreshFunction();
                                        showSuccess('Utilizatorul a fost adaugat cu success!')
                                        setSelectedUser(undefined);
                                        setLoading(false);
                                    }).catch(error => {
                                        let message = error?.response?.data?.message;
                                        if (!message) {
                                            message = 'A aparut o eroare la adaugarea utilizatorului!'
                                        }
                                        showError(message);
                                        setLoading(false);
                                    });

                                }
                                }
                                disabled={state.loading}
                                color="primary">
                                Trimite
                            </Button>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                        onClick={() => {
                            closeFunction();
                        }
                        }
                        disabled={state.loading}
                        color="primary">
                        Inchide
                    </Button>

                </DialogActions>
            </Dialog>
        </Fragment >


    );
};

export default compose(withRouter, withSnackbar)(UserSearchImporter);
