import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';
import { changePassword } from 'src/services/user';

const useStyles = makeStyles(({
  root: {}
}));

const Password = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const changePasswordHandler = () => {
    if (values.password !== values.confirm) {
      showError("Parolele nu corespund!");
      return;
    }

    changePassword(values).then(() => showSuccess("Parola a fost schimbata cu success!")).catch(() => showError("Eroare la schimbarea parolei!"));
  }

  const showError = (msg) => {
    props.enqueueSnackbar(msg, { variant: 'error' });
  }

  const showSuccess = (msg) => {
    props.enqueueSnackbar(msg, { variant: 'success' });
  }


  return (
    <form
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader
          subheader="Schimba parola"
          title="Administrare parola"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            size="small"
            label="Parola"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            size="small"
            label="Repeta parola"
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={changePasswordHandler}
          >
            Schimba
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default compose(withSnackbar)(Password);
