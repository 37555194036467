import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { createQuiz, deleteQuiz, getAllQuizzesPaginated, updateQuiz, getQuiz } from 'src/services/quiz';
import { Fragment } from 'react';
import Draggable from 'react-draggable';
import { withSnackbar } from 'notistack';
import theme from './styles'
import * as constants from 'src/components/EditableTable/constants'
import EditableTable from 'src/components/EditableTable';
import { downloadTemplate, getCompanyUserDropdown, uploadUsersExcel } from 'src/services/companyUser';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { getCompanyDropdown } from 'src/services/company';
import Tooltip from "@material-ui/core/Tooltip";
import data from '../product/ProductListView/data';
import ReplayIcon from '@material-ui/icons/Replay';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles = makeStyles(theme);


const UserImporter = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ loading: false, company: undefined, page: 0, data: [], tableRefreshKey: 1 });
    const { isOpen, closeFunction, refreshFunction } = props;

    useEffect(() => {
        if (props.defaultCompanyId && props.defaultCompanyName) {
            setState(prevState => {
                return ({ ...prevState, company: { id: props.defaultCompanyId, name: props.defaultCompanyName } });
            })
        }

    }, [props.defaultCompanyId, props.defaultCompanyName]);


    const showError = (error) => {
        props.enqueueSnackbar(error, {
            variant: 'error',
            autoHideDuration: 60000,
            style: { whiteSpace: 'pre-line' },
            action: (key) => (
                <Button onClick={(e) => props.closeSnackbar(key)}>
                    <span style={{ color: 'white' }}>{'Inchide'}</span>
                </Button>
            )
        });
    }

    const showWarning = (warning) => {
        props.enqueueSnackbar(warning, {
            variant: 'warning',
            autoHideDuration: 60000,
            style: { whiteSpace: 'pre-line' },
            action: (key) => (
                <Button onClick={(e) => props.closeSnackbar(key)}>
                    <span style={{ color: 'white' }}>{'Inchide'}</span>
                </Button>
            )
        });
    }


    const showSuccess = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'success' });
    }

    const setLoading = (state) => {
        setState(prevState => {
            return ({ ...prevState, loading: state, tableRefreshKey: (prevState.tableRefreshKey + 1) });
        })
    }

    const setData = (data) => {
        setState(prevState => {
            return ({ ...prevState, data });
        })
    }

    const setPage = (page) => {
        setState(prevState => {
            return ({ ...prevState, page });
        })
    }


    const setCompany = (company) => {
        setState(prevState => {
            return ({ ...prevState, company });
        })
    }


    let columns = {
        definitions: [
            { title: 'Linie', name: 'line', type: constants.numeric },
            { title: 'Erori', name: 'error', type: constants.text }
        ],

        isEditable: false,
        validatorSchema: {},
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
    };


    const getInternalPaginatedErrors = (page, size) => {
        const clonnedData = [...state.data];

        return new Promise(resolve => resolve({
            data: {
                "content": clonnedData?.splice(page * size, size),
                "pageable": { "sort": { "sorted": true, "unsorted": false, "empty": false }, "offset": 0, "pageSize": 10, "pageNumber": page, "paged": true, "unpaged": false },
                "last": true, "totalPages": state?.data?.length / size, "totalElements": state?.data?.length, "number": page, "size": size,
                "sort": { "sorted": true, "unsorted": false, "empty": false }, "first": true, "numberOfElements": size, "empty": false
            }
        }));
    }


    const icon = <ReplayIcon style={{ color: '#3f51b5' }} />;



    return (
        <Fragment>

            <Dialog
                open={isOpen}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'xl'}
                fullWidth

            >
                <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                    <Typography variant='h5' className={classes.dialogTitleText}>
                        Adaugare multipla utilizatori
                        {/* {state.loading ? <CircularProgress className={classes.circularProgress} /> : ''} */}

                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <br />
                    <Grid
                        container
                        spacing={3}
                        wrap="wrap"
                    >
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Card>
                                <CardHeader
                                    title="Operatiuni"
                                />
                                <Divider />
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={2}
                                        wrap="wrap"
                                        alignItems="center"
                                    // justify="center"
                                    >

                                        <Grid
                                            item
                                            md={2}
                                            sm={2}
                                            xs={2}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {/* <Button
                                                // onClick={() => {
                                                //     closeFunction();
                                                //     reset();
                                                // }
                                                // }
                                                disabled={state.loading || !state?.company?.id}
                                                color="primary">
                                                Incarca fisier
                                            </Button> */}
                                            <Tooltip title={!state?.company?.id ? "Nu s-a selectat o companie!" : ''} placement="bottom" arrow classes={{
                                                tooltip: classes.tooltip,
                                                arrow: classes.tooltipArrow
                                            }}>

                                                <label htmlFor="btn-upload-edit-component">
                                                    <input
                                                        id="btn-upload-edit-component"
                                                        name="btn-upload-edit-component"
                                                        style={{ display: 'none' }}
                                                        type="file"
                                                        disabled={state.loading || (!state?.company?.id)}
                                                        onChange={
                                                            (e) => {
                                                                // this.selectFile(e);
                                                                // e.target.value = ""

                                                                // console.log("edit")
                                                                // if (e?.target?.files[0]?.name?.length) {
                                                                //     setValue(name, e?.target?.files[0]?.name);
                                                                //     setValue('selectedFile', true);
                                                                //     setValue('file', e?.target?.files[0]);
                                                                //     setMultipleErrors({ name: e?.target?.files[0]?.name, 'selectedFile': true, 'file': e?.target?.files[0] }, schema);

                                                                const target = { ...e.target };
                                                                const file = e?.target?.files[0];
                                                                const companyId = state?.company?.id;
                                                                if (file) {
                                                                    console.log('file', file);
                                                                    console.log('companyId', companyId);
                                                                    uploadUsersExcel(file, companyId).then(({ data }) => {
                                                                        target.value = null;
                                                                        if (data.length > 0) {
                                                                            showWarning('Import efectuat partial.\nAu aparut erori pentru ' + data.length + ' linii\nCorectati erorile apoi incercati din nou!')

                                                                        }
                                                                        else {
                                                                            showSuccess('S-au incarcat cu succes toate datele!');
                                                                        }
                                                                        setData(data)
                                                                        setLoading(false);
                                                                    }).catch(error => {
                                                                        target.value = null;
                                                                        setLoading(false);

                                                                        const message = error?.response?.data?.message;
                                                                        if (message) {
                                                                            showError(message);
                                                                        }
                                                                        else {
                                                                            showError('Eroare la incarcarea fisierului!')
                                                                        }
                                                                    }
                                                                    )
                                                                    e.target.value = null;
                                                                }

                                                                // }
                                                                // else {
                                                                //     setValue(name, "");
                                                                //     setValue('selectedFile', false);
                                                                //     setValue('file', undefined);
                                                                //     setMultipleErrors({ name: '', 'selectedFile': false, 'file': undefined }, schema);

                                                                // }



                                                            }

                                                        }
                                                    />
                                                    <Button
                                                        fullWidth
                                                        className="btn-choose"
                                                        disabled={state.loading || (!state?.company?.id)}
                                                        color="primary"
                                                        variant='outlined'
                                                        component="span" >
                                                        Incarca Fisier
                                                    </Button>
                                                </label>
                                            </Tooltip>
                                        </Grid>

                                        <Grid
                                            item
                                            md={2}
                                            sm={2}
                                            xs={2}
                                            style={{ textAlign: 'center' }}

                                        >
                                            <Button
                                                fullWidth
                                                onClick={() => {
                                                    downloadTemplate();
                                                }
                                                }
                                                variant='outlined'
                                                color="primary">
                                                Descarca fisier template
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            style={{ textAlign: 'center' }}

                                        >
                                            <ServerAutocomplete
                                                onChange={(e, changeVal) => {
                                                    // updateDropdownFilter('sentToId', changeVal?.id);
                                                    setCompany({ ...changeVal });
                                                }}
                                                defaultValue={state.company}
                                                label="Companie"
                                                variant='outlined'
                                                disabled={state.loading}
                                                getOptions={getCompanyDropdown}
                                            />
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Card className={classes.quizCard}>
                                <CardHeader
                                    title={"Errori la incarcare"}
                                />
                                <Divider />
                                <CardContent >
                                    <EditableTable columns={columns}
                                        key={state.tableRefreshKey}
                                        queries={{
                                            getTableDataPaginatedQuery: getInternalPaginatedErrors
                                        }}
                                        disableFilter
                                        loading={state.loading}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                        onClick={() => {
                            closeFunction();
                        }
                        }
                        color="primary">
                        Inchide
                    </Button>

                </DialogActions>
            </Dialog>
        </Fragment >


    );
};

export default compose(withRouter, withSnackbar)(UserImporter);
