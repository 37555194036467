import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Paper,
  CircularProgress
} from '@material-ui/core';
import {
  downloadDocument,
  getAllDocumentsPaginated,
  getDocumentDropdown
} from 'src/services/files';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { getAllQuizzesPaginated, getQuizDropdown } from 'src/services/quiz';
import { LabelDivider } from 'mui-label-divider';
import { Fragment } from 'react';
import { createContent, updateContent } from 'src/services/content';
import Draggable from 'react-draggable';
import QuizTester from 'src/components/Quiz';
import { withSnackbar } from 'notistack';
import theme from './styles';
import { getEmployeeDropdown } from 'src/services/users';
import { getCompanyDropdown } from 'src/services/company';
import * as constants from 'src/components/EditableTable/constants';
import { downloadFile } from 'src/utils/downloadFile';
import EditableTable from 'src/components/EditableTable';
import PdfViewer from 'src/components/PdfViewer';
import { getCurrentUserData } from 'src/services/user';
import DraggablePaper from 'src/components/DraggablePaper';

const useStyles = makeStyles(theme);

const QuizPicker = props => {
  const classes = useStyles();
  const [state, setState] = useState({
    quizId: undefined,
    loading: false,
    email: undefined,
    tableKey: 1
  });

  const setLoading = state => {
    setState(prevState => ({ ...prevState, loading: state }));
  };

  const refreshTable = () => {
    setState(prevState => {
      let tableKey = prevState.tableKey + 1;
      return { ...prevState, tableKey };
    });
  };

  const showError = message => {
    props.enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 60000,
      style: { whiteSpace: 'pre-line' },
      action: key => (
        <Button onClick={e => props.closeSnackbar(key)}>
          <span style={{ color: 'white' }}>{'Inchide'}</span>
        </Button>
      )
    });
  };

  const setEmail = email => {
    setState(prevState => ({ ...prevState, email }));
  };

  useEffect(() => {
    setLoading(true);
    getCurrentUserData()
      .then(({ data }) => {
        setEmail(data.email);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  }, []);

  const columns = {
    definitions: [
      { title: 'Nume', name: 'name', type: constants.text, sortable: true },
      {
        title: 'Creare',
        name: 'creationDate',
        type: constants.datetimepicker,
        sortable: true
      },
      {
        title: 'Creat de',
        name: 'ownerUsername',
        type: constants.text,
        sortable: true,
        editableDisabled: true
      }
    ],
    isEditable: false,
    validatorSchema: {},
    defaultPageSize: 10,
    pageSizes: [10, 15, 25],
    filterDefinitions: [
      {
        type: 'text',
        name: 'name',
        useId: true,
        label: 'Nume',
        size: 3,
        value: ''
      },
      {
        type: 'dropdown',
        name: 'ownerUsername',
        defaultValue: { id: state.email, name: state.email },
        label: 'Creat de',
        size: 3,
        value: '',
        getOptions: getEmployeeDropdown
      }
    ],
    initialFilter: { ownerUsername: state.email },
    downloadAction: {
      label: 'Arata',
      requiredActionFields: ['id'],
      action: requiredFields => {
        setState(prevState => ({ ...prevState, quizId: requiredFields?.id }));
      }
    },
    customAction: {
      label: 'Alege',
      title: 'Alege Quiz',
      requiredActionFields: ['id', 'name'],
      action: requiredFields => {
        props.setQuiz(requiredFields);
      }
    }
  };

  const showSuccess = message => {
    props.enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: 5000,
      style: { whiteSpace: 'pre-line' }
    });
  };

  return (
    <Fragment>
      <Dialog
        open={props.isOpen}
        PaperComponent={DraggablePaper}
        maxWidth={'xl'}
        fullWidth
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="draggable-dialog-title123"
        >
          <Typography variant="h5" className={classes.dialogTitleText}>
            Cautare Avansata Quiz-uri
            {state.loading ? (
              <CircularProgress className={classes.circularProgress} />
            ) : (
              ''
            )}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {state.email ? (
            <EditableTable
              columns={columns}
              queries={{
                getTableDataPaginatedQuery: getAllQuizzesPaginated
              }}
              loading={state.loading}
            />
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={state.loading}
            onClick={props.close}
          >
            Inchide
          </Button>
        </DialogActions>
      </Dialog>
      {state.quizId ? (
        <QuizTester
          id={state.quizId}
          isTest
          close={() =>
            setState(prevState => ({ ...prevState, quizId: undefined }))
          }
        />
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default compose(withRouter, withSnackbar)(QuizPicker);
