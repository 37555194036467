import {
    Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, Radio, RadioGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Typography,
    Button,
    useMediaQuery,
    Checkbox
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { compose } from "recompose";
import { getShuffledQuiz, submitQuiz, testQuiz } from 'src/services/quiz';
import { radialConfig } from "./chartConfig";


const Quiz = (props) => {

    const [state, setState] = useState({ loading: false, data: [] });

    useEffect(() => {
        if (props.id)
            getQuiz();
    }, [props.id]);

    const setLoading = (state) => {
        setState(prevState => ({ ...prevState, loading: state }))
    }

    const getQuiz = () => {
        setLoading(true);
        getShuffledQuiz(props.id).then(({ data }) => {
            setLoading(false);
            setState(prevState => ({ ...prevState, data }))
        }).catch(error => {
            setState(false);
            console.error(error);
        })
    }

    const submitQuizHandler = () => {
        setLoading(true);
        submitQuiz({ questions: state.data, ...props.contentData }).then(({ data }) => {
            // props.enqueueSnackbar('Procent:' + data.percentage, {
            //     variant: 'success',
            // });


            props.enqueueSnackbar('Ati finalizat quiz-ul!', {
                content: key => getChart(data, key), autoHideDuration: 60000,
                style: { whiteSpace: 'pre-line' }


            });
            setLoading(false);
            if (!data.finished) {
                props.close();
                return;
            }

            props.closeAndSetComplete();

        }).catch(error => setLoading(false));
    }

    const setSelectedAnswer = (questionId, answerId) => {
        setState(prevState => {
            let newQuestionAndAnswers = [...prevState.data.questionAndAnswers];
            let selectedQuestion = newQuestionAndAnswers.find(q => q.id === questionId);
            selectedQuestion.selectedAnswerIds = [parseInt(answerId)];
            return ({ ...prevState, data: { ...prevState.data, questionAndAnswers: newQuestionAndAnswers } })
        })

    }

    const setMultipleSelectedAnswers = (questionId, answerId) => {
        setState(prevState => {
            let newQuestionAndAnswers = [...prevState.data.questionAndAnswers];
            let selectedQuestion = newQuestionAndAnswers.find(q => q.id === questionId);
            const newAnswerId = parseInt(answerId);
            let selectedAnswerIds = selectedQuestion.selectedAnswerIds;
            if (selectedAnswerIds.includes(newAnswerId)) {
                selectedQuestion.selectedAnswerIds = selectedAnswerIds.filter(s => s != newAnswerId);
            }
            else {
                selectedAnswerIds.push(newAnswerId);
            }
            return ({ ...prevState, data: { ...prevState.data, questionAndAnswers: newQuestionAndAnswers } })
        })

    }

    const getChart = (data, key) => {
        let title = 'Ati finalizat cu succes quiz-ul!';
        let color = 'rgb(67, 160, 71)';
        if (!data.finished) {
            title = 'Nu ati strans suficiente puncte pentru a trece quiz-ul!'
            color = '#d32f2f';
        }

        return (
            <Card
                style={{ border: 'solid 4px ' + color }}
            >
                <CardHeader
                    // style={{ color: 'white' }}
                    title={title}
                />
                <Divider />
                <ReactApexChart options={radialConfig} series={[data.percentage]} type="radialBar" height={150} />
                <Button style={{ float: 'right' }} onClick={(e) => props.closeSnackbar(key)}>
                    <span >{'Inchide'}</span>
                </Button>
            </Card>

        )
    }

    const shouldDisplayFullScreen = () => {
        return useMediaQuery('(max-width:1200px)');

    }

    const shouldHaveSmallMarginsForPhone = () => {
        return useMediaQuery('(max-width:800px)');

    }
    const shouldHaveSmallMarginsForPhoneVar = shouldHaveSmallMarginsForPhone();

    return (
        <Fragment>
            <Dialog
                open={true}
                //onClose={handleClose}
                // PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'xl'}
                fullScreen={shouldDisplayFullScreen()}
                fullWidth

            >
                <DialogTitle >
                    <Typography variant='h5' style={{ verticalAlign: 'middle' }}>
                        {'Test: '}{state?.data?.name}
                        {state.loading ? <CircularProgress style={{ width: '25px', height: '25px', marginLeft: '10px', verticalAlign: 'middle' }} /> : ''}
                    </Typography>
                </DialogTitle>
                <DialogContent style={shouldHaveSmallMarginsForPhoneVar ? { backgroundColor: '#f4f6f8', paddingLeft: '12px', paddingRight: '12px' } : { backgroundColor: '#f4f6f8' }}>
                    <Grid
                        container
                        spacing={2}
                        wrap="wrap"
                    >
                        {state?.data?.questionAndAnswers?.map(data => (
                            <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader
                                        title={data?.question}
                                        subheader={data.hasMultipleAnswers ? <span style={{ display: 'inline-block', marginTop: '5px' }}>Mai multe variante de raspuns</span> : <span style={{ display: 'inline-block', marginTop: '5px' }}>O singura varianta de raspuns</span>}

                                    />
                                    <Divider />
                                    <CardContent style={shouldHaveSmallMarginsForPhoneVar ? { paddingLeft: '12px', paddingRight: '12px' } : {}} >
                                        {!data.hasMultipleAnswers ?
                                            <RadioGroup aria-label="quiz" name="quiz"
                                                disabled={state.loading}
                                                value={data.selectedAnswerIds[0] || null} onChange={(e) => setSelectedAnswer(data.id, e.target.value)}>
                                                {data?.answerDtos?.map((answer, i) => (
                                                    <Fragment>
                                                        < FormControlLabel value={answer.id} style={{ overflowWrap: 'break-word', wordBreak: 'break-word', marginBottom: '2px' }} control={<Radio color="primary" disabled={state.loading} />} label={answer.textContent} />
                                                        {i < data.answerDtos.length - 1 ? <Divider style={{ marginLeft: '-42px', marginRight: '-42px', backgroundColor: 'rgb(0 0 0 / 8%)' }} /> : ''}
                                                    </Fragment>
                                                ))
                                                }
                                                {/* <FormControlLabel value={data.answer2Id} control={<Radio color="primary" />} label={data.answer2} />
                                            <FormControlLabel value={data.answer3Id} control={<Radio color="primary" />} label={data.answer3} />
                                            <FormControlLabel value={data.answer4Id} control={<Radio color="primary" />} label={data.answer4} /> */}
                                            </RadioGroup>
                                            :
                                            data?.answerDtos?.map((answer, i) => (
                                                <div key={`checkbox-${answer.id}`}>
                                                    <FormControlLabel
                                                        className="checkbox-group-checkbox-label"
                                                        style={{ overflowWrap: 'break-word', wordBreak: 'break-word', marginBottom: '2px' }}
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                type="checkbox"
                                                                // name={`${name}[${index}]`}
                                                                // value={value}
                                                                checked={data.selectedAnswerIds.includes(answer.id)}
                                                                onChange={(e) => setMultipleSelectedAnswers(data.id, answer.id)}
                                                            />
                                                        }

                                                        label={answer.textContent}
                                                    />
                                                    {i < data.answerDtos.length - 1 && <Divider style={{ marginLeft: '-42px', marginRight: '-42px', backgroundColor: 'rgb(0 0 0 / 8%)' }} />}
                                                </div>
                                            ))
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!props.isTest ? <Button onClick={() => submitQuizHandler(state.data)}
                        disabled={state.loading}


                    >Finalizare</Button> : <Button onClick={() => testQuiz(state.data).then(({ data }) => {
                        props.enqueueSnackbar('Ati finalizat quiz-ul!', {
                            content: key => getChart(data, key), autoHideDuration: 60000,
                            style: { whiteSpace: 'pre-line' }

                        });
                    })}
                        disabled={state.loading}

                    >Testeaza</Button>}

                    <Button color="primary"

                        disabled={state.loading}

                        onClick={() => props.close()}>Anuleaza</Button>

                </DialogActions>
                {/* <ReactApexChart options={radialConfig} series={[70]} type="radialBar" height={150} /> */}

            </Dialog>

        </Fragment >
    )



}


export default compose(withSnackbar)(Quiz);