import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Paper,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { Fragment } from 'react';
import Draggable from 'react-draggable';
import { LabelDivider } from 'mui-label-divider';
import { getEmployeeDropdown } from 'src/services/users';


const CCSelector = ({ open, onConfirm, onCancel, defaultValue, label, title, isLoading }) => {
    let initialState = {
        cc: [],
    };
    const [state, setState] = useState({ ...initialState });

    const updateCC = (cc, index) => {
        setState(prevState => {
            let newCC = [...prevState.cc];
            if (cc === null) {
                newCC.splice(index, 1);
            } else {
                newCC[index] = cc;
            }
            return { ...prevState, cc: newCC };
        });
    };

    const addCC = () => {
        setState(prevState => ({ ...prevState, cc: [...prevState.cc, ''] }));
    };

    useEffect(() => {
        if (open) {
            setState({ cc: defaultValue || [] })
        }
    }, [open])


    return (
        <Fragment>
            <Dialog
                open={open}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle
                    id="draggable-dialog-title"
                >
                    <Typography variant="h5" >
                        Confirmare
                    </Typography>
                </DialogTitle>
                <DialogContent key={'1d'}>
                    <LabelDivider>CC</LabelDivider>
                    <br />
                    <TextField
                        key={0}
                        style={{ fontSize: '14px' }}
                        InputProps={{
                            style: {
                                fontSize: '14px'
                            }
                        }}
                        InputLabelProps={{
                            style: { fontSize: '14px' }
                        }}
                        disabled={state.loading}
                        fullWidth
                        label="Email"
                        margin="dense"
                        name="name"
                        value="Email curent"
                        variant="outlined"
                    />
                    {state.cc.map((value, index) => (
                        <ServerAutocomplete
                            key={state?.cc[index]?.id}
                            style={{ fontSize: '14px' }}
                            InputProps={{
                                style: {
                                    fontSize: '14px'
                                }
                            }}
                            InputLabelProps={{
                                style: { fontSize: '14px' }
                            }}
                            onChange={(e, changeVal) => {
                                updateCC(changeVal, index);
                            }}
                            margin="dense"
                            size="small"
                            value={state.cc[index]}
                            label="Email"
                            variant="outlined"
                            disabled={state.loading}
                            getOptions={name =>
                                getEmployeeDropdown(name).then(({ data }) => {
                                    const newData = data.filter(d => {
                                        return !state.cc.filter(cc => cc.id === d.id).length > 0;
                                    });
                                    return Promise.resolve({ data: newData });
                                })
                            }
                        />
                    ))}
                    <Button
                        color="primary"
                        onClick={addCC}
                        disabled={state.loading || isLoading}
                    >
                        Adauga CC
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onConfirm(
                                state.cc.filter(cc => cc)
                            );
                        }}
                    >
                        Confirma
                    </Button>
                    <Button
                        color="primary"
                        onClick={onCancel}
                    >
                        Anuleaza
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default CCSelector;