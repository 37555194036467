import ViewTable from "src/components/EditableTable/ViewTable";
import * as constants from 'src/components/EditableTable/constants'
import { getAllCompanyUsersPaginated, getCompanyUserQueryDropdown } from "src/services/companyUser";
import { getCompanyDropdown } from "src/services/company";
import { getEmployeeDropdown } from "src/services/users";
import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Paper, Typography } from "@material-ui/core";
import theme from './styles'
import Draggable from "react-draggable";
import { sendToMultipleConfirmation } from "src/services/content";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme);
let data = [];

const MassConfirm = (props) => {

    const [initialData, setInitialData] = useState([])
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    // useEffect(() => {
    //     getAllCompanyUsersPaginated(0, 99999, {}, 'id', 'desc').then(({ data }) => {
    //         console.log(data)
    //         let content = data.content.map(d => ({ ...d, selected: true, canDownload: false, canDownloadQuiz: false, canForward: false, canDownloadForward: false }))
    //         setInitialData(content);
    //     })
    // }, [])

    useEffect(() => {
        if (props?.initialData?.length > 0) {
            const newInitialData = props.initialData.map(d => ({ ...d, selected: true, canDownload: !!d.canDownload, canDownloadQuiz: !!d.canDownloadQuiz, canForward: !!d.canForward, canDownloadForward: !!d.canDownloadForward }))
            setInitialData(newInitialData);
        }
    }, [props.initialData])

    function PaperComponent(props) {
        return (
            <Draggable handle="#draggable-dialog-title" >
                <Paper {...props} />
            </Draggable>
        );
    }

    let columns = {
        definitions: [
            { title: 'Selectat', name: 'selected', type: constants.editableCheckbox, sortable: true },
            { title: 'Prenume', name: 'firstName', type: constants.text, sortable: true },
            { title: 'Nume', name: 'lastName', type: constants.text, sortable: true },
            { title: 'Email', name: 'email', type: constants.text, sortable: true },
            { title: 'Descarcare documente', name: 'canDownload', type: constants.editableCheckbox, sortable: true },
            { title: 'Descarcare quiz-uri', name: 'canDownloadQuiz', type: constants.editableCheckbox, sortable: true },
            { title: 'Redirectionare', name: 'canForward', type: constants.editableCheckbox, sortable: true },
            { title: 'Descarcare fisiere redirectionate', name: 'canDownloadForward', type: constants.editableCheckbox, sortable: true },
            { title: 'Functie', name: 'job', type: constants.text, sortable: true },
            { title: 'Activ', name: 'active', type: constants.checkbox, sortable: true, defaultValue: true },
            { title: 'Creare', name: 'creationDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
            { title: 'Companie', name: 'companyName', type: constants.text, sortable: true },
            { title: 'Mesaj extra', name: 'extraDetails', type: 'editableText', sortable: true },
            { title: 'CC extra', name: 'extraCC', type: 'emailTagList', sortable: true },

        ],

        isEditable: false,
        validatorSchema: {},
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'boolean', name: 'selected', label: 'Selectat', size: 1, value: '' },
            { type: 'text', name: 'firstName', label: 'Prenume', size: 2, value: '' },
            { type: 'text', name: 'lastName', label: 'Nume', size: 2, value: '' },
            { type: 'text', name: 'email', label: 'Email', size: 2, value: '' },
            { type: 'text', name: 'job', label: 'Functie', size: 2, value: '' },
            { type: 'dropdown', name: 'companyName', label: 'Companie', size: 3, value: '', getOptions: getCompanyDropdown },
            { type: 'boolean', name: 'canDownload', label: 'Descarcare documente', size: 2, value: '' },
            { type: 'boolean', name: 'canDownloadQuiz', label: 'Descarcare quiz-uri', size: 2, value: '' },
            { type: 'boolean', name: 'canForward', label: 'Redirectionare', size: 2, value: '' },
            { type: 'boolean', name: 'canDownloadForward', label: 'Descarcare dupa redirectionare', size: 2 },
            {
                type: 'visibleColumns', name: 'columns', size: 2, label: 'Coloane Vizibile', value: [{ title: 'Selectat', name: 'selected' },
                { title: 'Prenume', name: 'firstName' },
                { title: 'Nume', name: 'lastName' },
                { title: 'Email', name: 'email' },
                { title: 'Descarcare documente', name: 'canDownload' },
                { title: 'Descarcare quiz-uri', name: 'canDownloadQuiz' },
                { title: 'Redirectionare', name: 'canForward' },
                { title: 'Descarcare fisiere redirectionate', name: 'canDownloadForward' },
                { title: 'Functie', name: 'job' },
                { title: 'Activ', name: 'active' },
                { title: 'Creare', name: 'creationDate' },
                { title: 'Companie', name: 'companyName' },
                { title: 'Mesaj extra', name: 'extraDetails' },
                { title: 'CC extra', name: 'ccExtra' },], options: [{ title: 'Selectat', name: 'selected' },
                { title: 'Prenume', name: 'firstName' },
                { title: 'Nume', name: 'lastName' },
                { title: 'Email', name: 'email' },
                { title: 'Descarcare documente', name: 'canDownload' },
                { title: 'Descarcare quiz-uri', name: 'canDownloadQuiz' },
                { title: 'Redirectionare', name: 'canForward' },
                { title: 'Descarcare fisiere redirectionate', name: 'canDownloadForward' },
                { title: 'Functie', name: 'job' },
                { title: 'Activ', name: 'active' },
                { title: 'Creare', name: 'creationDate' },
                { title: 'Companie', name: 'companyName' },
                { title: 'Mesaj extra', name: 'extraDetails' },
                { title: 'CC extra', name: 'ccExtra' },]
            }
        ],
    };
    return (<>
        <Dialog
            open={true || props.isEditCompletion}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            // maxWidth={'sm'}
            fullScreen
        >
            <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                <Typography variant='h5' className={classes.dialogTitle}>
                    Confirma detalii trimitere mass
                    {/* {state.loading || props.loading ? <CircularProgress className={classes.circularProgress} /> : ''} */}
                </Typography>
            </DialogTitle>
            <DialogContent key={1}>
                <ViewTable loading={props.loading || loading} columns={columns} initialData={initialData} setData={(newData) => { data = newData }} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={props.loading || loading} onClick={() => {
                    setLoading(true);
                    sendToMultipleConfirmation({ contentId: props.contentId, confirmedData: data }).then(() => {
                        setLoading(false);
                        props.onSend();
                        enqueueSnackbar('Datele au fost setate si urmeaza a fi trimise automat!', { variant: 'warning', autoHideDuration: 9500 })

                    }).catch((error) => {
                        setLoading(false);
                        if (error?.response?.data?.message) {
                            enqueueSnackbar(error?.response?.data?.message, { variant: 'error', autoHideDuration: 9500 })

                        }
                        else
                        {
                            enqueueSnackbar('A aparut o eroare la trimiterea datelor!', { variant: 'error', autoHideDuration: 9500 })

                        }
                    });
                }
                }>Trimite</Button><Button onClick={props.close} disabled={props.loading || loading}>Anuleaza</Button>
            </DialogActions>
        </Dialog>
    </>)

}

export default MassConfirm;