import React, { useState } from 'react';
import {
    Grid,
    makeStyles,
    Switch,
    Typography,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import _ from 'underscore';
import SummaryContent from './SummaryContent';
import DetailedContent from './DetailedContent';
import theme from './styles'

const useStyles = makeStyles(theme);

const Events = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({ detailed: true });

    const setDetailed = (event) => {
        let checked = event.target.checked;
        setState((prevState) => ({ ...prevState, detailed: checked }));
    }

    return (
        <Page
            className={classes.root}
            title="SSM & PSI"
        >
            <Grid component="label" container alignItems="center" spacing={1} className={classes.switchContainer}>
                <Grid item><Typography variant='body1' className={classes.switchText}>Sumar</Typography></Grid>
                <Grid item>
                    <span>
                        <Switch
                            color="primary"
                            checked={state.detailed}
                            onChange={setDetailed}
                        />
                    </span>
                </Grid>
                <Grid item><Typography variant='body1' className={classes.switchText}>Detaliat</Typography></Grid>
            </Grid>

            {state.detailed ?
                <DetailedContent /> :
                <SummaryContent />}

        </Page>
    );
};

export default compose(withRouter)(Events);
