import React from 'react';
import {
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Downloads from './Downloads';
import theme from './styles'

const useStyles = makeStyles(theme);

const DownloadView = () => {
  const classes = useStyles();

  return (
    <Page
      disableNavSpacing
      className={classes.root}
      title="Descarca"
    >
      <Downloads />
    </Page>
  );
};

export default DownloadView;
