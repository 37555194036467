import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { logout } from 'src/services/authentication';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = (props) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    const authorities = localStorage.getItem('authorities');
    const authTokenExpiration = localStorage.getItem('authTokenExpiration');
    const refreshTokenExpiration = localStorage.getItem('refreshTokenExpiration');
    if (!authorities || !authTokenExpiration || !refreshTokenExpiration) {
      logout();
      return;
    }
    try {
      let refreshTokenExpirationDate = new Date(Number(refreshTokenExpiration));
      let now = new Date();
      if (now >= refreshTokenExpirationDate) {
        logout();
        return;
      }

    }
    catch (err) {
      console.error(err);
      logout();
      return;
    }



  }, []);


  return (
    <Fragment>
      <TopBar />
      {!props?.disabledNav ?
        <NavBar
          // onMobileClose={() => setMobileNavOpen(false)}
          // openMobile={isMobileNavOpen}
        />
        : ''}
    </Fragment>

  );
};

export default DashboardLayout;
