import React, { Component, useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState, Modifier, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import { Fragment } from 'react';
import { Box, Button, Chip, CircularProgress, Divider, Grid, TextField } from '@material-ui/core';
import { testNotification, updateNotification } from 'src/services/notifications';
import { withSnackbar } from 'notistack';


const EmailEditor = (props) => {

    const getDefaultEditorState = () => {
        if (!props?.data[props?.valueField]) {
            return EditorState.createEmpty();
        }

        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(props?.data[props?.valueField])
            ))
    }


    useEffect(
        () => {
            setState(prevState => ({ ...prevState, editorState: getDefaultEditorState(), title: props?.data[props?.titleField], editorText: props?.data[props?.valueField] }));
        }
        , [props.data])



    const [state, setState] = useState
        ({
            editorState: getDefaultEditorState(), editorText: '', loading: false, title: ''
        })



    const getData = () => {
        let currentHtmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let currentData = props?.data;
        return { ...currentData, [props?.titleField]: state.title, [props?.valueField]: currentHtmlContent }
    }

    const onEditorStateChange = (editorState) => {
        // debugger;
        let currentHtmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        props?.setData(getData());
        console.log(currentHtmlContent);

        setState(prevState => ({
            ...prevState,
            editorState, editorText: currentHtmlContent
        }));
    };

    const handleChipClick = (name) => {
        let currentContent = draftToHtml(convertToRaw(state.editorState.getCurrentContent()));
        // currentContent += "${chip}"
        // console.log(currentContent)
        let tag = "${" + name + "}";

        sendTextToEditor(tag);
    }


    const sendTextToEditor = (text) => {
        // let currentHtmlContent = draftToHtml(convertToRaw(state.editorState.getCurrentContent()));

        // console.log(currentHtmlContent);
        // console.log(currentHtmlContent.includes('${chip}'));

        // if (currentHtmlContent.includes('${chip}')) {
        //     return;
        // }
        setState(prevState => ({ ...prevState, editorState: insertText(text, state.editorState) }));
        // focusEditor();
    }

    const insertText = (text, editorState) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text
        );
        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    }

    const updateTitleHandler = (e) => {
        const title = e.target.value;
        setState(prevState => ({ ...prevState, title }));

    }

    const setLoading = (state) => {
        setState(prevState => ({ ...prevState, loading: state }));

    }

    const showError = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'error' });
    }

    const showSuccess = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'success' });
    }

    const { classes } = props;
    const { editorState } = state;
    const { variables } = props;
    return (
        <Box style={{ position: 'relative' }}>
            {state.loading || props.loading ? <CircularProgress className={classes.circularProgress} /> : ''}

            <Box style={{ opacity: state.loading || props.loading ? '0.2' : '1' }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            disabled={state.loading || props.loading}
                            size="small"
                            label="Titlu email"
                            margin="normal"
                            name="confirm"
                            onChange={updateTitleHandler}
                            value={state.title}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={9}>
                        <span>Inlocuire:  </span>

                        {variables?.map(chip =>
                            <Fragment>
                                <Chip
                                    // icon={icon}
                                    disabled={state.loading || props.loading}
                                    clickable
                                    color="primary"
                                    label={chip.name}
                                    onClick={() => handleChipClick(chip.name)}
                                    // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                    style={chip.mandatory ? { backgroundColor: 'rgb(220, 0, 78)' } : {}}
                                />
                                <span> </span>
                            </Fragment>
                        )}
                    </Grid>
                </Grid>
                <br />
                <Editor
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign',
                            'history', 'colorPicker', 'image', 'link'],
                    }}
                    readOnly={state.loading || props.loading}
                    defaultEditorState={getDefaultEditorState()}
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName={classes.editorEdit}
                    onEditorStateChange={onEditorStateChange}
                />
                <br />
                <Divider />

                <Grid container alignItems="center" justify='flex-end' spacing={1} style={{ marginTop: '5px' }}>
                    <Grid item sm={1}>
                        <Button
                            fullWidth
                            disabled={state.loading || props.loading}
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setLoading(true);
                                testNotification({ type: props.type, content: state.editorText, title: state.title }).then(() => {
                                    setLoading(false)
                                }).catch(() => {
                                    showError('A aparut o eroare la testarea datelor!');
                                    setLoading(false)
                                })

                            }
                            }
                        >
                            Testeaza
                    </Button>
                    </Grid>
                    <Grid item sm={1}>

                        <Button
                            fullWidth
                            disabled={state.loading || props.loading}
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                let errors = "";
                                for (const crtVar of props.variables) {
                                    if (crtVar.mandatory && !state.editorText.includes('${' + crtVar.name + '}')) {
                                        errors += crtVar.name + ', '
                                    }
                                }
                                if (errors?.length > 0) {
                                    showError('Sablonul pentru notificare nu poate fi salvat deoarece lipsesc datele pentru: ' + errors);
                                    return;
                                }
                                setLoading(true);

                                updateNotification(getData()).then(() => {
                                    setLoading(false)
                                }).catch(() => {
                                    showError('A aparut o eroare la salvarea datelor!');
                                    setLoading(false);
                                })

                            }
                            }
                        >
                            Salveaza
                    </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )

}

export default compose(withStyles(styles), withSnackbar)(EmailEditor)