import validate from 'validate.js';

validate.validators.message = function (value, options, key, attributes) {
  return options.text;
};

export default {
  name: function (value, attributes, attributeName, options, constraints) {
    // console.log('value:', value, 'attributes:', attributes, 'attributeName:', attributeName, 'options:', options, 'constraints:', constraints)

    if (!attributes?.file && !attributes?.name) {
      return ({ message: { text: "Nu s-a selectat nici un fisier!" } });
    } else if (!attributes?.name?.length) {

      return ({ message: { text: "Denumirea fisierului este obligatorie!" } });
    }
    if (attributes?.file?.size && (attributes?.file?.size / (1024 * 1024)).toFixed(2) > 20) {
      return ({ message: { text: "Fsierul depaseste limita maxima de 20 MB!" } });
    }

    return null;

  }
};
