import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  Users as UsersIcon,
} from 'react-feather';
import NavItem from './NavItem';
import { BusinessOutlined } from '@material-ui/icons';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddAlarmOutlinedIcon from '@material-ui/icons/AddAlarmOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Fragment } from 'react';
import { hasAnyOfAuthorities } from 'src/utils/authUtils';
import { LabelDivider } from 'mui-label-divider';

const user = {
  avatar: '/static/images/logo/logo.png',
  jobTitle: 'Protectia Muncii',
  name: 'Protectia Muncii'
};

const items = [
  {
    divider: 'Aplicatie',
    authority: 'ROLE_EMPLOYEE',
  },
  {
    href: '/app/companii',
    icon: BusinessOutlined,
    title: 'Companii',
    authority: 'ROLE_EMPLOYEE',
  },
  {
    href: '/app/company-user',
    icon: UsersIcon,
    title: 'Utilizatori',
    authority: 'ROLE_EMPLOYEE'
  },
  {
    href: '/app/upload',
    icon: CloudUploadOutlinedIcon,
    title: 'Documente',
    authority: 'ROLE_EMPLOYEE'
  },
  {
    href: '/app/quiz',
    icon: AssessmentOutlinedIcon,
    title: 'Quiz',
    authority: 'ROLE_EMPLOYEE'
  },
  {
    href: '/app/events',
    icon: CalendarTodayOutlinedIcon,
    title: 'SSM & PSI',
    authority: 'ROLE_EMPLOYEE'
  },
  {
    href: '/app/reminder',
    icon: AddAlarmOutlinedIcon,
    title: 'Alarme',
    authority: 'ROLE_EMPLOYEE'
  },
  {
    href: '/downloads',
    icon: CloudDownloadOutlinedIcon,
    title: 'Downloads',
    authority: 'ROLE_USER'
  },
  {
    divider: 'Administrare',
    authority: 'ROLE_EMPLOYEE',
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'Angajati',
    authority: 'ROLE_ADMIN',
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Setari',
    authority: 'ROLE_EMPLOYEE'
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 225,
    top: 64,
    // height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 75,
    height: 75
  },
  name: {
    fontWeight: 900
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h4"
        >
          {user.name}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.filter(item => hasAnyOfAuthorities(item.authority)).map((item, index) => (
            <Fragment>
              {item?.divider ? <LabelDivider key={index}>{item.divider}</LabelDivider> :
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              }
            </Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      {/* <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={false}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden> */}
      <Hidden >
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default (NavBar);
