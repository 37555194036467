import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Tooltip,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const shouldHideBar = () => {
    const {pathname} = useLocation();
    return useMediaQuery('(max-height:500px)') && pathname === '/login';
  }

  return (
    <AppBar
      style={shouldHideBar() ? { display: 'none' } : {}}
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>

        <RouterLink to="/">

          <Logo />

        </RouterLink>

      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
