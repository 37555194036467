export default theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
    ,
    item: {
        display: 'flex',
        flexDirection: 'column'
    },
    listText: {
        width: '85%',
        overflowWrap: 'break-word'
    }
});