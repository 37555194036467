export const text = 'text';
export const numeric = 'numeric';
export const checkbox = 'checkbox';
export const editableCheckbox = 'editableCheckbox';
export const datepicker = 'datepicker';
export const datetimepicker = 'datetimepicker';
export const timepicker = 'timePicker';
export const dropdown = 'dropdown';
export const dropdownServer = 'dropdownServer';
export const link = 'link';
export const minMillis = 'min_millis';
export const maxMillis = 'max_millis';
export const query = 'query';
export const customDropdown = 'customDropdown';
export const selectServer = 'selectServer';
export const file = 'file';
export const textarea = 'textarea';
export const range = 'range';

