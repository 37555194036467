import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Paper,
    CircularProgress
} from '@material-ui/core';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { Fragment } from 'react';
import Draggable from 'react-draggable';
import theme from './styles'

const useStyles = makeStyles(theme);

const Delete = (props) => {
    const classes = useStyles();
    let initialState = { loading: false }
    const [state, setState] = useState({ ...initialState });

    const setLoading = (state) => {
        setState((prevState) => ({ ...prevState, loading: state }));
    }

    function PaperComponent(props) {
        return (
            <Draggable handle="#draggable-dialog-title" >
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <Fragment
        >
            <Dialog
                open={props.isDelete}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'sm'}
            >
                <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                    <Typography variant='h5' className={classes.dialogTitle}>
                        Sterge continut
                        {state.loading || props.loading ? <CircularProgress className={classes.circularProgress} /> : ''}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Esti sigur ca vrei sa stergi continutul pentru {props.name}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button disabled={state.loading || props.loading} onClick={() => {
                        props.delete(props.id);
                    }
                    }>Sterge</Button><Button color="primary" onClick={props.close} disabled={state.loading || props.loading}>Anuleaza</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default compose(withRouter)(Delete);
