import validate from 'validate.js';


export default {
  username: {
    email: {message:'Email-ul nu are un format corect!'}
  },



};
