import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    Paper,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Box
} from '@material-ui/core';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { getCompanyUserDropdown } from 'src/services/companyUser';
import { Fragment } from 'react';
import Draggable from 'react-draggable';
import theme from './styles'
import { DateTimePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme);

const EditCompletion = (props) => {
    const classes = useStyles();
    let initialState = { loading: false }
    const [state, setState] = useState({ ...initialState, data: props.data || {} });

    const setSelectedUser = (user) => {
        setState((prevState) => ({ ...prevState, selectedUser: user }));
    }

    const setLoading = (state) => {
        setState((prevState) => ({ ...prevState, loading: state }));
    }

    const setValue = (name, value) => {
        setState((prevState) => ({ ...prevState, data: { ...prevState.data, [name]: value } }));

    }

    useEffect(() => {
        if (props.isEditCompletion) {
            reset();
        }
    }, [props.isEditCompletion]);

    const reset = () => {
        setState({ ...initialState, data: props.data || {} });
    }

    function PaperComponent(props) {
        return (
            <Draggable handle="#draggable-dialog-title" >
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <Fragment
        >
            <Dialog
                open={props.isEditCompletion}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth={'sm'}
                fullWidth
                >
                <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                    <Typography variant='h5' className={classes.dialogTitle}>
                        Editare date finalizare {props.name}
                        {state.loading || props.loading ? <CircularProgress className={classes.circularProgress} /> : ''}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box m={1}>
                        <DateTimePicker
                            disabled={props.loading || state.loading}
                            fullWidth
                            size="small"
                            value={state?.data?.completionDate || null}
                            label='Data finalizare'
                            ampm={false}
                            openTo="year"
                            name="completionDate"
                            todayLabel="Now"
                            format="dd/MM/yyyy HH:mm"
                            showTodayButton
                            onChange={e => {
                                const differenceHours = e.getTimezoneOffset() / 60 * -1;
                                e.setTime(e.getTime() + (differenceHours * 60 * 60 * 1000))
                                const changeVal = e.toISOString().slice(0, -1);
                                setValue("completionDate", changeVal);
                            }}
                        // error={hasError}
                        // helperText={error}
                        />
                        <DateTimePicker
                            disabled={props.loading || state.loading}
                            fullWidth
                            size="small"
                            value={state?.data?.sendDate || null}
                            label='Data trimitere'
                            ampm={false}
                            openTo="year"
                            name="sendDate"
                            todayLabel="Now"
                            format="dd/MM/yyyy HH:mm"
                            showTodayButton
                            onChange={e => {
                                const differenceHours = e.getTimezoneOffset() / 60 * -1;
                                e.setTime(e.getTime() + (differenceHours * 60 * 60 * 1000))
                                const changeVal = e.toISOString().slice(0, -1);
                                setValue("sendDate", changeVal);
                            }}
                        // error={hasError}
                        // helperText={error}
                        />
                        <FormControlLabel style={{ marginLeft: '1px' }}
                            control={<Checkbox style={{ marginLeft: '0px' }} name="isComplete" onChange={e => {
                                const changeVal = e.target.checked;
                                setValue("isComplete", changeVal);
                            }} checked={state?.data?.isComplete} color="primary" size='small' name="activ" />}
                            label={<Typography style={{ fontSize: '14px' }}>Finalizat</Typography>}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button disabled={state.loading || props.loading} onClick={() => {
                        props.setCompletion(state.data)
                    }
                    }>Salveaza</Button><Button color="primary" onClick={props.close} disabled={state.loading || props.loading}>Anuleaza</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default compose(withRouter)(EditCompletion);
