import * as config from '../../constants';
import "isomorphic-fetch";
import axios from 'axios';




export function testNotification(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/notifications/test`, ...requestOptions })
}


export function updateNotification(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/notifications/update`, ...requestOptions })
}


export function getNotifications() {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
    }
    return axios({ url: `${config.apiUrl}/notifications/get`, ...requestOptions })
}