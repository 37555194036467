export default theme => ({
    editorEdit: {
        backgroundColor: theme.palette.background.dark,
        minHeight:'300px'

    },
    circularProgress: {
        position: 'absolute',
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 20px)'
      },
});