import * as config from '../../constants';
import "isomorphic-fetch";
import axios from 'axios';
import { downloadFile } from 'src/utils/downloadFile';



export function getAllCompanyUsersPaginated(page, size, filters, orderBy, orderDirection) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ page, elementsPerPage: size, filters, orderBy, orderDirection }),
    }
    return axios({ url: `${config.apiUrl}/company-user/getPaginated`, ...requestOptions })
}

export function getCompanyUsersByFilters(filters) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ filters }),
    }
    return axios({ url: `${config.apiUrl}/company-user/getUserConfirmation`, ...requestOptions })
}

export function deleteCompanyUser(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/company-user/delete`, ...requestOptions })
}


export function updateCompanyUser(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/company-user/update`, ...requestOptions })
}


export function createCompanyUser(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/company-user/create`, ...requestOptions })
}


export function getCompanyUserDropdown(name) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ name: name }),
    }
    return axios({ url: `${config.apiUrl}/company-user/getSelect`, ...requestOptions })
}

export function getCompanyUserQueryDropdown(query) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ query: query }),
    }
    return axios({ url: `${config.apiUrl}/company-user/getSelectByQuery`, ...requestOptions })
}

export function getCompanyUserByCompanyAndQueryDropdown(query) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ query: query }),
    }
    return axios({ url: `${config.apiUrl}/company-user/getSelectForCompanyUserByCompanyAndQuery`, ...requestOptions })
}

export function getReportCompanyUser(filter) {
    let formData = new FormData();
    formData.append('filters', JSON.stringify(filter));

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        responseType: 'arraybuffer',
        withCredentials: true,
        data: formData,
    }
    return axios({ url: `${config.apiUrl}/company-user/getReport`, ...requestOptions })
}


export function uploadUsersExcel(file, companyId, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("companyId", companyId);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onUploadProgress,
        data: formData,
    }

    return axios({ url: `${config.apiUrl}/company-user/importUsers`, ...requestOptions })
}


export function downloadTemplate(onDownloadProgress) {
    const requestOptions =
    {
        method: 'POST',
        // headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onDownloadProgress,
        responseType: 'arraybuffer'
        // data: formData,
    }


    return axios({ url: `${config.apiUrl}/company-user/downloadTemplate`, ...requestOptions })
        .then(response => { downloadFile(response.data, 'template-utilizatori.xlsx', 'application/octet-stream') });
}