export default theme => ({
  root: {
    // padding: theme.spacing.unit * 4
  },
  tableRow: {
    height: '30px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 500,
  },
  transparent: {
    opacity: '0.2'
  },
  loader: {
    position: 'fixed',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)'
  },
  addButton: {
    marginBottom: '10px',
    marginTop: '3.5px',
    marginRight: '10px',
    float: 'right',
    borderColor: 'transparent'
  },
  searchInput: {
    width: 'fit-content',
    minWidth: '200px',
    maxWidth: '500px',
    marginBottom: '10px',
    marginTop: '10px',
    float: 'right'
  },
  actionsTableCell: {
    width: '100px',
    textAlign: 'center',
    padding: '0px',
    margin: '5px'
  }
});
