import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Badge,
  Tooltip,
  useMediaQuery
} from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import { Fragment } from 'react';
import { forwardContent, getActiveForCompanyUser, getForwardedForCompanyUser, getReportForward } from 'src/services/content';
import { LabelDivider } from 'mui-label-divider';
import { downloadDocument, getDocumentDropdown } from 'src/services/files';
import QuizEditor from 'src/components/Quiz'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { downloadFile } from 'src/utils/downloadFile';
import { downloadQuizzes } from 'src/services/quiz';
import PdfViewer from 'src/components/PdfViewer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import theme from './styles';
import EditableTable from 'src/components/EditableTable';
import * as constants from 'src/components/EditableTable/constants'
import SearchIcon from '@material-ui/icons/Search';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import UserSearchImporter from './UserSearchImporter';
import UserImporter from './UserImporter';
import schema from './schema';

const useStyles = makeStyles(theme);

const Downloads = (props) => {
  const classes = useStyles();
  let triggerRefresh;
  const [state, setState] = useState({
    firstChecked: false, secondChecked: false, data: undefined,
    pdfViewer: { open: false, name: '' }
  });
  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -0.5,
      top: 19,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      color: 'white'
    },
  }))(Badge);

  useEffect(() => {
    getActiveForCompanyUser().then(({ data }) =>
      setState(prevState => ({ ...prevState, data }))
    ).catch(error => console.error(error));
  }, []);


  const closePdfViewer = () => {
    setState({ ...state, pdfViewer: { open: false, name: '' } });
  }

  const openPdfViewer = (pdfName, checkDocumentId, contentId) => {
    setState({ ...state, pdfViewer: { open: true, name: pdfName, checkDocumentId, contentId } });
  }

  const setQuizEditor = (type, visibilityState) => {
    if (type === 'SSM')
      setState(prevState => ({ ...prevState, ssmQuizVisible: visibilityState }));
    if (type === 'PSI')
      setState(prevState => ({ ...prevState, psiQuizVisible: visibilityState }));
    if (type === 'RSVTI')
      setState(prevState => ({ ...prevState, rsvtiQuizVisible: visibilityState }));

  }

  const closeQuizEditorAndSetFinished = (type) => {
    debugger;
    if (type === 'SSM')
      setState(prevState => ({ ...prevState, ssmQuizVisible: false, rsvtiQuizVisible: false, data: { ...prevState.data, ssmQuizFinished: true } }));
    if (type === 'PSI')
      setState(prevState => ({ ...prevState, psiQuizVisible: false, rsvtiQuizVisible: false, data: { ...prevState.data, psiQuizFinished: true } }));
    if (type === 'RSVTI')
      setState(prevState => ({ ...prevState, psiQuizVisible: false, ssmQuizVisible: false, rsvtiQuizVisible: false, data: { ...prevState.data, rsvtiQuizFinished: true } }));
  }

  const setDocumentDownloaded = (id) => {
    if (state?.data)
      setState(prevState => {
        const newDocuments = [...state.data.detailContentDocumentDtoList];
        let index = newDocuments?.findIndex(d => d.id === id);
        let newDocument = { ...newDocuments[index], downloaded: true };
        newDocuments[index] = newDocument;
        return ({ ...prevState, data: { ...prevState.data, detailContentDocumentDtoList: newDocuments } })
      })
  }

  const downloadQuiz = (id, type) => {
    downloadQuizzes(id).then(({ data }) => { downloadFile(data, "Test " + type + ".docx", 'application/octet-stream') })
  }


  const shouldDisplayBelow = () => {
    return useMediaQuery('(max-width:1280px)');
  }

  const shouldDisplayButtonsBelow = () => {
    return useMediaQuery('(max-width:560px)');

  }

  const shouldDisplayBellowConst = useMediaQuery('(max-width:1280px)');


  const openUserImporter = () => {
    setState(prevState => {
      return ({ ...prevState, isUserImporterOpen: true });
    })
  }

  const closeUserImporter = () => {
    setState(prevState => {
      return ({ ...prevState, isUserImporterOpen: false });
    })
  }


  let columns = {
    definitions: [
      { title: 'Prenume', name: 'firstName', type: constants.text, sortable: true },
      { title: 'Nume', name: 'lastName', type: constants.text, sortable: true },
      { title: 'Email', name: 'email', type: constants.text, sortable: true },
      { title: 'Functie', name: 'job', type: constants.text, sortable: true },
      { title: 'Data trimitere', name: 'sendDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
      { title: 'Finalizat', name: 'completed', type: constants.checkbox, sortable: true, editableDisabled: true },
      { title: 'Data finalizare', name: 'completionDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
    ],
    isEditable: true,
    validatorSchema: schema,
    defaultPageSize: 10,
    pageSizes: [10, 15, 25],
    filterDefinitions: [
      { type: 'text', name: 'firstName', label: 'Prenume', size: 2, value: '' },
      { type: 'text', name: 'lastName', label: 'Nume', size: 2, value: '' },
      { type: 'text', name: 'email', label: 'Email', size: 3, value: '' },
      { type: 'text', name: 'job', label: 'Functie', size: 2, value: '' },
      { type: 'boolean', name: 'completed', label: 'Finalizat', size: 3, value: '' },
    ],
    setTriggerRefresh: (triggerRefreshFunction) => {
      triggerRefresh = triggerRefreshFunction
    }

  };


  const getReport = (filter) => {
    setLoading(true);
    getReportForward(filter).then(({ data }) => {
      downloadFile(data, "Raport Redirectionare Anagajati.xlsx", 'application/octet-stream')
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    })
  }

  const setLoading = (state) => {
    setState(prevState => ({ ...prevState, loading: state }));
  }

  const iconMultiAdd = <AddToPhotosIcon style={{ color: '#3f51b5' }} />;
  const iconSearch = <SearchIcon style={{ color: '#3f51b5' }} />;

  const getCustomActions = [{
    tooltip: 'Adaugare multipla', icon: iconMultiAdd, onClick: () => {
      setState((prevState) => ({ ...prevState, isUserImporterOpen: true }));
    },
  },
  {
    tooltip: 'Cauta', icon: iconSearch, onClick: () => {
      setState((prevState) => ({ ...prevState, isSearchOpen: true }));

    }
  }
  ]


  const closeUserSearch = () => {
    setState(prevState => ({ ...prevState, isSearchOpen: false }));

  }

  const getCanDownloadQuiz = () => {
    if (state?.data?.forwardedById) {
      return state?.data?.canDownloadForward && state?.data?.canDownloadQuiz;
    }
    return state?.data?.canDownloadQuiz;
  }

  const getCanDownloadData = () => {
    if (state?.data?.forwardedById) {
      return state?.data?.canDownloadForward && state?.data?.canDownload;
    }
    return state?.data?.canDownload;
  }



  const buttonStyle = shouldDisplayButtonsBelow() ? { position: 'inherit', transform: 'inherit', paddingLeft: '16px', textAlign: 'center' } : {};

  return (
    <Fragment>
      <Card>
        <Grid
          container
          spacing={6}
          wrap="wrap"
        >
          <Grid
            className={classes.item}
            item
            lg={6}
            sm={6}
            xs={6}
          >
            <CardHeader
              subheader="Securitate si Sanatate in Munca"
              title="Protectia muncii"
            />
          </Grid>
          <Grid
            className={classes.item}
            item
            lg={6}
            sm={12}
            xs={12}
            style={shouldDisplayBelow() ? { display: 'none' } : {}}

          >
            <CardHeader
              subheader="Prevenirea si Stingerea Incendiilor"
              title={'\u00A0'}
            />
          </Grid>
        </Grid>

        <Divider />
        <CardContent>
          <Grid
            container

            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              lg={6}
              md={12}
              sm={12}
              xs={12}
            >
              <LabelDivider>Documente</LabelDivider>
              <List dense={false}>
                {state?.data?.detailContentDocumentDtoList?.filter(d => d.documentType === 'SSM')?.map(value =>
                  <ListItem>
                    <ListItemIcon>
                      <StyledBadge badgeContent={'v'} color="secondary" invisible={!value.downloaded}>
                        <InsertDriveFileOutlinedIcon />
                      </StyledBadge>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listText }}
                      primary={value.documentName}
                    />
                    <ListItemSecondaryAction style={buttonStyle}>
                      <Tooltip title="Vizualizeaza fisier SSM" placement="bottom" disabled={state.isLoading || props.loading}>
                        <span>
                          <IconButton aria-label="delete" onClick={() => {
                            openPdfViewer(value.documentName, value.id, state?.data?.id);
                          }}>
                            <VisibilityIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {getCanDownloadData() ?
                        (<Tooltip title="Descarca fisier SSM" placement="bottom" disabled={state.isLoading || props.loading}>
                          <span>
                            <IconButton aria-label="delete" onClick={() => {
                              setState(prevState => ({ ...prevState, firstChecked: true }));
                              downloadDocument({ name: value.documentName, checkDocumentId: value.id, contentId: state?.data?.id }).then(() => setDocumentDownloaded(value.id));
                            }}>
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>) : ''}
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                <LabelDivider>Teste</LabelDivider>
                {state?.data?.ssmQuizId ? (
                  <ListItem>
                    <ListItemIcon>
                      <StyledBadge badgeContent={'v'} color="secondary" invisible={!state?.data?.ssmQuizFinished}>
                        <InsertDriveFileOutlinedIcon />
                      </StyledBadge>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listText }}
                      primary={state?.data?.ssmQuizName || 'Test SSM'}
                    />
                    <ListItemSecondaryAction style={buttonStyle}>
                      {getCanDownloadQuiz() ?
                        (<Tooltip title="Descarca test SSM" placement="bottom" disabled={state.isLoading || props.loading}>
                          <span>
                            <IconButton onClick={() => downloadQuiz(state?.data?.ssmQuizId, 'SSM')}>
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>) : ''}
                      <Tooltip title="Rezolva test" placement="bottom" disabled={state.isLoading || props.loading}>
                        <span>
                          <IconButton onClick={() => setQuizEditor('SSM', true)}>
                            <HelpOutlineOutlinedIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {state?.ssmQuizVisible ? <QuizEditor id={state?.data?.ssmQuizId} close={() => setQuizEditor('SSM', false)} closeAndSetComplete={() => closeQuizEditorAndSetFinished('SSM')} contentData={{ type: 'SSM', contentId: state?.data?.id }} /> : ''}
                    </ListItemSecondaryAction>
                  </ListItem>
                ) : ''}
              </List>
            </Grid>
            <Grid
              className={classes.item}
              item
              lg={6}
              md={12}
              sm={12}
              xs={12}
            >
              <CardHeader
                subheader="Prevenirea si Stingerea Incendiilor"
                style={!shouldDisplayBelow() ? { display: 'none' } : {}}
              />
              <LabelDivider>Documente</LabelDivider>
              <List dense={false}>
                {state?.data?.detailContentDocumentDtoList?.filter(d => d.documentType === 'PSI')?.map(value =>
                  <ListItem>
                    <ListItemIcon>
                      <StyledBadge badgeContent={'v'} color="secondary" invisible={!value.downloaded}>
                        <InsertDriveFileOutlinedIcon />
                      </StyledBadge>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listText }}
                      primary={value.documentName}
                    />
                    <ListItemSecondaryAction style={buttonStyle}>
                      <Tooltip title="Vizualizeaza fisier PSI" placement="bottom" disabled={state.isLoading || props.loading}>
                        <span>
                          <IconButton aria-label="delete" onClick={() => {
                            openPdfViewer(value.documentName, value.id, state?.data?.id);
                          }}>
                            <VisibilityIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {getCanDownloadData() ?

                        (<Tooltip title="Descarca fisier PSI" placement="bottom" disabled={state.isLoading || props.loading}>
                          <span>
                            <IconButton aria-label="delete" onClick={() => {
                              setState(prevState => ({ ...prevState, firstChecked: true }));
                              downloadDocument({ name: value.documentName, checkDocumentId: value.id, contentId: state?.data?.id }).then(() => setDocumentDownloaded(value.id));

                            }}>
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>) : ''}
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                <LabelDivider>Teste</LabelDivider>
                {state?.data?.psiQuizId ? (
                  <ListItem>
                    <ListItemIcon>
                      <StyledBadge badgeContent={'v'} color="secondary" invisible={!state?.data?.psiQuizFinished}>

                        <InsertDriveFileOutlinedIcon />
                      </StyledBadge>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listText }}
                      primary={state?.data?.ssmQuizName || 'Test PSI'}
                    />
                    <ListItemSecondaryAction style={buttonStyle}>
                      {getCanDownloadQuiz() ?

                        (<Tooltip title="Descarca test PSI" placement="bottom" disabled={state.isLoading || props.loading}>
                          <span>
                            <IconButton onClick={() => downloadQuiz(state?.data?.psiQuizId, 'PSI')}>
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>) : ''}
                      <Tooltip title="Rezolva test" placement="bottom" disabled={state.isLoading || props.loading}>
                        <span>
                          <IconButton onClick={() => setQuizEditor('PSI', true)}>
                            <HelpOutlineOutlinedIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {state?.psiQuizVisible ? <QuizEditor id={state?.data?.psiQuizId} close={() => setQuizEditor('PSI', false)} closeAndSetComplete={() => closeQuizEditorAndSetFinished('PSI')} contentData={{ type: 'PSI', contentId: state?.data?.id }} /> : ''}
                    </ListItemSecondaryAction>
                  </ListItem>
                ) : ''}
              </List>
            </Grid>
            <Grid
              className={classes.item}
              item
              lg={6}
              md={12}
              sm={12}
              xs={12}
            >
              <CardHeader
                subheader="Responsabil cu Supravegherea si Verificarea Tehnica a Instalatiilor"
                style={{ marginLeft: '0px', paddingLeft: '0px' }}
              />
              <LabelDivider>Documente</LabelDivider>
              <List dense={false}>
                {state?.data?.detailContentDocumentDtoList?.filter(d => d.documentType === 'RSVTI')?.map(value =>
                  <ListItem>
                    <ListItemIcon>
                      <StyledBadge badgeContent={'v'} color="secondary" invisible={!value.downloaded}>
                        <InsertDriveFileOutlinedIcon />
                      </StyledBadge>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listText }}
                      primary={value.documentName}
                    />
                    <ListItemSecondaryAction style={buttonStyle}>
                      <Tooltip title="Vizualizeaza fisier RSVTI" placement="bottom" disabled={state.isLoading || props.loading}>
                        <span>
                          <IconButton aria-label="delete" onClick={() => {
                            openPdfViewer(value.documentName, value.id, state?.data?.id);
                          }}>
                            <VisibilityIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {getCanDownloadData() ?

                        (<Tooltip title="Descarca fisier RSVTI" placement="bottom" disabled={state.isLoading || props.loading}>
                          <span>
                            <IconButton aria-label="delete" onClick={() => {
                              setState(prevState => ({ ...prevState, firstChecked: true }));
                              downloadDocument({ name: value.documentName, checkDocumentId: value.id, contentId: state?.data?.id }).then(() => setDocumentDownloaded(value.id));

                            }}>
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>) : ''}
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                <LabelDivider>Teste</LabelDivider>
                {state?.data?.rsvtiQuizId ? (
                  <ListItem>
                    <ListItemIcon>
                      <StyledBadge badgeContent={'v'} color="secondary" invisible={!state?.data?.rsvtiQuizFinished}>

                        <InsertDriveFileOutlinedIcon />
                      </StyledBadge>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listText }}
                      primary={state?.data?.rsvtiQuizName || 'Test RSVTI'}
                    />
                    <ListItemSecondaryAction style={buttonStyle}>
                      {getCanDownloadQuiz() ?

                        (<Tooltip title="Descarca test RSVTI" placement="bottom" disabled={state.isLoading || props.loading}>
                          <span>
                            <IconButton onClick={() => downloadQuiz(state?.data?.rsvtiQuizId, 'RSVTI')}>
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>) : ''}
                      <Tooltip title="Rezolva test" placement="bottom" disabled={state.isLoading || props.loading}>
                        <span>
                          <IconButton onClick={() => setQuizEditor('RSVTI', true)}>
                            <HelpOutlineOutlinedIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {state?.rsvtiQuizVisible ? <QuizEditor id={state?.data?.rsvtiQuizId} close={() => setQuizEditor('RSVTI', false)} closeAndSetComplete={() => closeQuizEditorAndSetFinished('RSVTI')} contentData={{ type: 'RSVTI', contentId: state?.data?.id }} /> : ''}
                    </ListItemSecondaryAction>
                  </ListItem>
                ) : ''}
              </List>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        {
          ((!state?.data?.psiQuizFinished && state.data != null && state.data.psiQuizId != null) || (!state?.data?.ssmQuizFinished && state.data != null && state.data.ssmQuizId != null)
            || (!state?.data?.rsvtiQuizFinished && state.data != null && state.data.rsvtiQuizId != null)
            || state?.data?.detailContentDocumentDtoList?.some(d => !d.downloaded)) ?
            <Alert severity="error">Inca nu au fost descarcate toate documentele si/sau finalizat toate testele!</Alert>
            :
            < Alert severity="success">Instruirea a fost finalizata!</Alert>
        }
      </Card>
      <br />
      <br />
      {state?.data?.canForward && !state?.data?.forwardedById ?

        <Card>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >

            <Grid
              className={classes.item}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <CardHeader
                subheader="Trimite mai departe catre angajati"
                title="Redirectionare"
              />
            </Grid>

          </Grid>

          <Divider />
          <CardContent>
            {!shouldDisplayBellowConst ?

              <EditableTable columns={columns} queries={{
                getTableDataPaginatedQuery: getForwardedForCompanyUser,
                createTableData: forwardContent
              }}

                customActions={getCustomActions}
                isDeleteDisabled
                isEditDisabled
                getReport={getReport}
                loading={state.loading}
              /> :
              <Alert severity="error">Redirectionarea se poate face doar de pe computer/laptop!</Alert>}

          </CardContent>
        </Card> : ''}

      <PdfViewer close={closePdfViewer} {...state.pdfViewer} setDocumentDownloaded={setDocumentDownloaded} />
      {state?.data?.canForward && !state?.data?.forwardedById ?
        <Fragment>
          <UserSearchImporter isOpen={state.isSearchOpen} refreshFunction={() => {
            if (triggerRefresh)
              triggerRefresh()
          }
          } closeFunction={closeUserSearch} />

          <UserImporter isOpen={state.isUserImporterOpen} closeFunction={closeUserImporter} refreshFunction={() => {
            if (triggerRefresh)
              triggerRefresh()
          }
          }
          />
        </Fragment> : ''}


    </Fragment >
  );
};

Downloads.propTypes = {
  className: PropTypes.string
};

export default Downloads;
