import * as config from '../../constants';
import "isomorphic-fetch";
import axios from 'axios';


export function getAllQuizzesPaginated(page, size, filters, orderBy, orderDirection) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ page, elementsPerPage: size, filters, orderBy, orderDirection }),
    }
    return axios({ url: `${config.apiUrl}/quiz/getPaginated`, ...requestOptions })
}


export function deleteQuiz(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/quiz/delete`, ...requestOptions })
}


export function updateQuiz(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/quiz/update`, ...requestOptions })
}


export function createQuiz(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/quiz/create`, ...requestOptions })
}


export function getQuiz(id) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ id }),
    }
    return axios({ url: `${config.apiUrl}/quiz/getById`, ...requestOptions })
}

export function getShuffledQuiz(id) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ id }),
    }
    return axios({ url: `${config.apiUrl}/quiz/getShuffledById`, ...requestOptions })
}

export function testQuiz(quizData) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(quizData),
    }
    return axios({ url: `${config.apiUrl}/quiz/testQuiz`, ...requestOptions })
}

export function submitQuiz(quizData) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(quizData),
    }
    return axios({ url: `${config.apiUrl}/quiz/submitQuiz`, ...requestOptions })
}


export function getQuizDropdown(name) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ name: name }),
    }
    return axios({ url: `${config.apiUrl}/quiz/getSelect`, ...requestOptions })
}

export function getReportQuizzes(filter) {
    let formData = new FormData();
    formData.append('filters', JSON.stringify(filter));

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        responseType: 'arraybuffer',
        withCredentials: true,
        data: formData,
    }
    return axios({ url: `${config.apiUrl}/quiz/getReport`, ...requestOptions })
}


export function downloadQuizzes(id) {
    let formData = new FormData();
    formData.append('id', id);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        responseType: 'arraybuffer',
        withCredentials: true,
        data: formData,
    }
    return axios({ url: `${config.apiUrl}/quiz/downloadQuiz`, ...requestOptions })
}