import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { login } from "src/services/authentication"
import Page from 'src/components/Page';
import schema from "./schema";
import validate from 'validate.js';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';
import history from "src/utils/history"
import theme from './styles'
import { getUrlParameter } from 'src/utils/query';

const useStyles = makeStyles((theme));

const LoginView = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({ login: { email: '', password: '' }, emailTouched: false, passwordTouched: false, errors: {}, loading: false })
  const session_expired = getUrlParameter('session_expired', props.location.search);

  const setLoading = (state) => {
    setState(prevState => ({ ...prevState, loading: state }));

  }

  useEffect(() => {
    if (session_expired) {
      props.enqueueSnackbar('Sesiunea a expirat. Va rugam sa va logati din nou!', {
        autoHideDuration: 10000,
        variant: 'warning',
      });
      history.replace({ search: '' })
    }
  }, [])

  const loginHandler = (username, password) => {

    if (state.errors) {
      props.enqueueSnackbar('Corectati datele de logare pentru a putea continua!', {
        variant: 'warning',
      });
      return;
    }

    setLoading(true);

    login(username, password).then(
      ({ data }) => {
        setLoading(false);
        history.push('/')
      }

    ).catch(error => {
      let message = 'Username-ul si/sau parola nu sunt corecte!';
      if (error?.message) {
        message = error?.message;
      }
      props.enqueueSnackbar(message, {
        variant: 'error',
      });
      setLoading(false);
    }
    )
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(prevState => ({ ...prevState, login: { ...prevState.login, [name]: value } }));
    const errors = validate({ ...state.login, [name]: value }, schema, { fullMessages: false });
    setState(prevState => ({ ...prevState, errors }));

  }

  const handleBlur = (e) => {
    const baseName = e.target.name;
    const name = baseName + "Touched";
    setState(prevState => ({ ...prevState, [name]: true }));
    let errors = validate({ ...state.login, [baseName]: state.login[baseName] }, schema, { fullMessages: false });
    setState(prevState => ({ ...prevState, errors }));
  }

  const getFormatedError = (errors) => {
    if (errors) {
      return errors.reduce((accumulator, currentValue) => accumulator + '\n' + currentValue);
    }
    else return null;
  }


  const handleKeyPress = (e) => {
    const keyCode = e.which || e.charCode || e.keyCode || 0;

    if (keyCode == 13) {
      loginHandler(state.login.email, state.login.password);
    }
  }

  return (
    <Page
      disableSpacing
      className={classes.root}
      title="Autentificare"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <div>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Autentificare
                  </Typography>
            </Box>
            <Grid
              container
              spacing={3}
            >
            </Grid>
            <TextField
              error={Boolean(state.emailTouched && state?.errors?.email)}
              fullWidth
              helperText={state.emailTouched && getFormatedError(state?.errors?.email)}
              label="Adresa Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={state.login.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(state.passwordTouched && state?.errors?.password)}
              fullWidth
              helperText={state.passwordTouched && getFormatedError(state?.errors?.password)}
              label="Parola"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="password"
              value={state.login.password}
              variant="outlined"
            />
            <Box my={2}>
              {!state.loading ?
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={
                    () => loginHandler(state.login.email, state.login.password)
                  }
                >
                  Conectare
                  </Button>
                : <CircularProgress className={classes.circularProgress} />}
            </Box>
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default compose(withSnackbar)(LoginView);
