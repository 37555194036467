import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Paper,
  CircularProgress
} from '@material-ui/core';
import { downloadDocument, getDocumentDropdown } from 'src/services/files';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { getQuizDropdown } from 'src/services/quiz';
import { LabelDivider } from 'mui-label-divider';
import { Fragment } from 'react';
import { createContent, updateContent } from 'src/services/content';
import Draggable from 'react-draggable';
import QuizTester from 'src/components/Quiz';
import { withSnackbar } from 'notistack';
import theme from './styles';
import DocumentPicker from './DocumentPicker';
import QuizPicker from './QuizPicker';

const useStyles = makeStyles(theme);

const EventsEditor = props => {
  const classes = useStyles();
  let initialState = {
    ssm: {
      documents: [],
      selectedDocument: null,
      quiz: undefined,
      selectedQuiz: null
    },
    psi: { documents: [], selectedDocument: null },
    rsvti: { documents: [], selectedDocument: null },
    name: '',
    loading: false,
    pickerType: ''
  };
  const [state, setState] = useState({ ...initialState });
  const removeDocument = (type, id) => {
    setState(prevState => {
      let newDocuments = prevState[type].documents.filter(d => d.id !== id);
      return {
        ...prevState,
        [type]: { ...prevState[type], documents: newDocuments }
      };
    });
  };

  const setSelectedDocument = (type, document) => {
    setState(prevState => ({
      ...prevState,
      [type]: { ...prevState[type], selectedDocument: document }
    }));
  };

  const setLoading = state => {
    setState(prevState => ({ ...prevState, loading: state }));
  };

  const addDocument = (type, document) => {
    if (!state[type].documents.some(d => d.documentId === document.id)) {
      {
        document = { ...document, documentId: document.id };
        setState(prevState => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            documents: [...prevState[type].documents, document]
          }
        }));
      }
    }
  };

  const removeQuiz = type => {
    setState(prevState => {
      return { ...prevState, [type]: { ...prevState[type], quiz: undefined } };
    });
  };

  const setSelectedQuiz = (type, quiz) => {
    setState(prevState => ({
      ...prevState,
      [type]: { ...prevState[type], selectedQuiz: quiz }
    }));
  };

  const addQuiz = (type, quiz) => {
    // if (!state[type].quiz) {
      setState(prevState => ({
        ...prevState,
        [type]: { ...prevState[type], quiz: quiz }
      }));
    // }
  };

  const updateOrCreate = () => {
    let ssmDocuments = state.ssm.documents.map(d => ({
      documentId: d.documentId,
      documentType: 'SSM',
      downloaded: false
    }));
    let psiDocuments = state.psi.documents.map(d => ({
      documentId: d.documentId,
      documentType: 'PSI',
      downloaded: false
    }));
    let rsvtiDocuments = state.rsvti.documents.map(d => ({
      documentId: d.documentId,
      documentType: 'RSVTI',
      downloaded: false
    }));

    let detailContentDocumentDtoList = [
      ...ssmDocuments,
      ...psiDocuments,
      ...rsvtiDocuments
    ];

    let content = {
      id: state.id,
      name: state.name,
      documentsDownloaded: false,
      quizzesFinished: false,
      ssmQuizId: state?.ssm?.quiz?.id,
      psiQuizId: state?.psi?.quiz?.id,
      detailContentDocumentDtoList,
      rsvtiQuizId: state?.rsvti?.quiz?.id,
      creationDate: state.creationDate
    };
    let errorMsg = '';
    if (!content.name) {
      errorMsg += 'Este necesara completarea numelui!\n';
    }
    if (
      !detailContentDocumentDtoList ||
      detailContentDocumentDtoList.length <= 0
    ) {
      errorMsg += 'Este necesara adaugarea a cel putin un document!';
    }
    if (errorMsg && errorMsg.length > 0) {
      showError(errorMsg);
      return;
    }

    if (props.addMode) {
      create(content);
    } else if (props.editMode) {
      update(content);
    }
  };

  useEffect(() => {
    if (props.editMode) {
      setEditData(props.editData);
    }
  }, [props.editMode]);

  const setEditData = editData => {
    let ssm = {
      documents: editData.documents
        .filter(d => d.type === 'SSM')
        .map(d => ({ ...d, id: d.id }))
    };
    let psi = {
      documents: editData.documents
        .filter(d => d.type === 'PSI')
        .map(d => ({ ...d, id: d.id }))
    };
    let rsvti = {
      documents: editData.documents
        .filter(d => d.type === 'RSVTI')
        .map(d => ({ ...d, id: d.id }))
    };

    if (editData.psiQuizId && editData.psiQuizName) {
      psi = {
        ...psi,
        quiz: { id: editData.psiQuizId, name: editData.psiQuizName }
      };
    }
    if (editData.ssmQuizId && editData.ssmQuizName) {
      ssm = {
        ...ssm,
        quiz: { id: editData.ssmQuizId, name: editData.ssmQuizName }
      };
    }
    if (editData.rsvtiQuizId && editData.rsvtiQuizName) {
      rsvti = {
        ...rsvti,
        quiz: { id: editData.rsvtiQuizId, name: editData.rsvtiQuizName }
      };
    }

    const newEditData = {
      id: editData.id,
      name: editData.name,
      creationDate: editData.creationDate,
      ssm,
      psi,
      rsvti
    };
    setState(prevState => ({ ...prevState, ...newEditData }));
  };

  useEffect(() => {
    if (props.addMode) {
      reset();
    }
  }, [props.addMode]);

  const reset = () => {
    setState({ ...initialState });
  };

  const update = content => {
    setLoading(true);
    updateContent(content)
      .then(({ data }) => {
        setLoading(false);
        props.refresh();
        props.close();
      })
      .catch(e => {
        let message = 'Nu s-au putut modifica date!\n';
        if (e?.response?.data?.message) {
          message = e?.response?.data?.message;
        }

        showError(message);
        setLoading(false);
      });
  };

  const create = content => {
    setLoading(true);
    createContent(content)
      .then(({ data }) => {
        setLoading(false);
        props.refresh();
        props.close();
      })
      .catch(e => {
        let message = 'Nu s-au putut adauga date!\n';
        if (e?.response?.data?.message) {
          message = e?.response?.data?.message;
        }
        showError(message);
        setLoading(false);
      });
  };

  const showError = message => {
    props.enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 60000,
      style: { whiteSpace: 'pre-line' },
      action: key => (
        <Button onClick={e => props.closeSnackbar(key)}>
          <span style={{ color: 'white' }}>{'Inchide'}</span>
        </Button>
      )
    });
  };

  const showSuccess = msg => {
    props.enqueueSnackbar(msg, { variant: 'success' });
  };

  const changeNameHandler = e => {
    const newValue = e.target.value;
    setState(prevState => ({ ...prevState, name: newValue }));
  };

  const setQuiz = quizId => {
    setState(prevState => ({ ...prevState, quizId }));
  };

  const closeDocumentPicker = () => {
    setState(prevState => ({
      ...prevState,
      openPickerSSM: false,
      openPickerPSI: false,
      openPickerRSVTI: false
    }));
  };

  const openPicker = (mode, pickerType) => {
    if (mode === 'SSM')
      setState(prevState => ({
        ...prevState,
        openPickerSSM: true,
        openPickerRSVTI: false,
        openPickerPSI: false,
        pickerType
      }));
    else if (mode === 'PSI')
      setState(prevState => ({
        ...prevState,
        openPickerSSM: false,
        openPickerPSI: true,
        openPickerRSVTI: false,
        pickerType
      }));
    else
      setState(prevState => ({
        ...prevState,
        openPickerSSM: false,
        openPickerPSI: false,
        openPickerRSVTI: true,
        pickerType
      }));
  };

  //workaround for mysterious state update issue
  //state variable would not update while child is open
  const addDocumentPicker = document => {
    document = { ...document, documentId: document.id };
    if (state.openPickerSSM) {
      setState(prevState => {
        const alreadyAdded = prevState.ssm.documents.some(
          d => d.id === document.id
        );
        if (alreadyAdded) {
          showError('Documentul este deja adaugat la SSM!');
          return { ...prevState };
        }
        showSuccess('Documentul s-a adaugat cu succes!');
        return {
          ...prevState,
          ssm: {
            ...prevState.ssm,
            documents: [...prevState.ssm.documents, document]
          }
        };
      });
    } else if (state.openPickerPSI) {
      setState(prevState => {
        const alreadyAdded = prevState.psi.documents.some(
          d => d.id === document.id
        );
        if (alreadyAdded) {
          showError('Documentul este deja adaugat la PSI!');
          return { ...prevState };
        }
        showSuccess('Documentul s-a adaugat cu succes!');
        return {
          ...prevState,
          psi: {
            ...prevState.psi,
            documents: [...prevState.psi.documents, document]
          }
        };
      });
    } else {
      setState(prevState => {
        const alreadyAdded = prevState.rsvti.documents.some(
          d => d.id === document.id
        );
        if (alreadyAdded) {
          showError('Documentul este deja adaugat la RSVTI!');
          return { ...prevState };
        }
        showSuccess('Documentul s-a adaugat cu succes!');
        return {
          ...prevState,
          rsvti: {
            ...prevState.rsvti,
            documents: [...prevState.rsvti.documents, document]
          }
        };
      });
    }
  };

  const isDocumentPickerOpen = () => {
    return (
      (state.openPickerPSI || state.openPickerSSM || state.openPickerRSVTI) &&
      state.pickerType === 'Document'
    );
  };

  const isQuizPickerOpen = () => {
    return (
      (state.openPickerPSI || state.openPickerSSM || state.openPickerRSVTI) &&
      state.pickerType === 'Quiz'
    );
  };

  const addQuizPicker = quiz => {
    let mode = 'psi';
    if (state.openPickerSSM) {
      mode = 'ssm';
    } else if (state.openPickerRSVTI) {
      mode = 'rsvti';
    }

    addQuiz(mode, quiz);
    showSuccess('Quiz-ul s-a selectat cu succes!');
  };

  return (
    <Fragment>
      <Dialog
        open={props.editMode || props.addMode}
        // PaperComponent={PaperComponent}
        maxWidth={'xl'}
        fullWidth
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="draggable-dialog-title123"
        >
          <Typography variant="h5" className={classes.dialogTitleText}>
            {props.editMode ? 'Editeaza Continut' : ''}
            {props.addMode ? 'Adauga Continut' : ''}
            {props.loading ? (
              <CircularProgress className={classes.circularProgress} />
            ) : (
              ''
            )}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Card>
            <CardHeader title="Date Generale" />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid item md={3} sm={3} xs={3}>
                  <TextField
                    fullWidth
                    label="Nume"
                    margin="dense"
                    name="name11"
                    onChange={changeNameHandler}
                    value={state.name}
                    variant="outlined"
                    disabled={state.loading}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardHeader title="SSM" />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid item md={6} sm={6} xs={6}>
                  <LabelDivider>Documente</LabelDivider>

                  {state.ssm.documents.map((document, index) => (
                    <Typography>
                      {document.name}{' '}
                      <Button onClick={e => removeDocument('ssm', document.id)}>
                        X
                      </Button>{' '}
                      <a href="#" onClick={() => downloadDocument(document)}>
                        Link
                      </a>
                    </Typography>
                  ))}
                  <LabelDivider>Quiz</LabelDivider>

                  {state.ssm.quiz ? (
                    <Typography>
                      {state?.ssm?.quiz?.name}{' '}
                      <a href="#" onClick={() => setQuiz(state?.ssm?.quiz?.id)}>
                        Link
                      </a>{' '}
                      <Button onClick={e => removeQuiz('ssm')}>X</Button>{' '}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  container
                  // spacing={2}
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item md={8} sm={8} xs={8} item>
                    <ServerAutocomplete
                      onChange={(e, changeVal) => {
                        setSelectedDocument('ssm', changeVal);
                      }}
                      style={{ paddingRight: '15px', color: 'white' }}
                      variant="outlined"
                      label="Document"
                      margin="dense"
                      name="document"
                      isLoading={state.loading}
                      getOptions={getDocumentDropdown}
                    />
                  </Grid>
                  <Grid item md={4} sm={4} xs={4} item>
                    <Button
                      fullWidth
                      onClick={() => openPicker('SSM', 'Document')}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      Picker Documente
                    </Button>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} item>
                    <Button
                      variant="contained"
                      disabled={
                        !state.ssm.selectedDocument ||
                        state.ssm.documents.some(
                          d => d.id === state?.ssm?.selectedDocument?.id
                        )
                      }
                      onClick={e =>
                        addDocument('ssm', state.ssm.selectedDocument)
                      }
                      color="primary"
                      size="small"
                    >
                      Adauga Document
                    </Button>
                  </Grid>
                  <br />
                  <Grid item md={8} sm={8} xs={8} item>
                    <ServerAutocomplete
                      onChange={(e, changeVal) => {
                        setSelectedQuiz('ssm', changeVal);
                      }}
                      style={{ paddingRight: '15px', color: 'white' }}
                      variant="outlined"
                      label="Quiz"
                      margin="dense"
                      name="companie"
                      isLoading={state.loading}
                      getOptions={getQuizDropdown}
                    />
                  </Grid>
                  <Grid item md={4} sm={4} xs={4} item>
                    <Button
                      fullWidth
                      onClick={() => openPicker('SSM', 'Quiz')}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      Picker Quiz
                    </Button>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} item>
                    <Button
                      variant="contained"
                      disabled={!state.ssm.selectedQuiz || state.ssm.quiz}
                      onClick={e => addQuiz('ssm', state.ssm.selectedQuiz)}
                      color="primary"
                      size="small"
                    >
                      Adauga Quiz
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardHeader title="PSI" />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid item md={6} sm={6} xs={6}>
                  <LabelDivider>Documente</LabelDivider>

                  {state.psi.documents.map((document, index) => (
                    <Typography>
                      {document.name}{' '}
                      <Button onClick={e => removeDocument('psi', document.id)}>
                        X
                      </Button>{' '}
                      <a href="#" onClick={() => downloadDocument(document)}>
                        Link
                      </a>
                    </Typography>
                  ))}
                  <LabelDivider>Quiz</LabelDivider>

                  {state.psi.quiz ? (
                    <Typography>
                      {state?.psi?.quiz?.name}{' '}
                      <a href="#" onClick={() => setQuiz(state?.psi?.quiz?.id)}>
                        Link
                      </a>{' '}
                      <Button onClick={e => removeQuiz('psi')}>X</Button>{' '}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  container
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item md={8} sm={8} xs={8} item>
                    <ServerAutocomplete
                      onChange={(e, changeVal) => {
                        setSelectedDocument('psi', changeVal);
                      }}
                      style={{ paddingRight: '15px', color: 'white' }}
                      variant="outlined"
                      label="Document"
                      margin="dense"
                      name="document"
                      isLoading={state.loading}
                      getOptions={getDocumentDropdown}
                    />
                  </Grid>
                  <Grid md={4} sm={4} xs={4} item>
                    <Button
                      fullWidth
                      onClick={() => openPicker('PSI', 'Document')}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      Picker Documente
                    </Button>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} item>
                    <Button
                      variant="contained"
                      disabled={
                        !state.psi.selectedDocument ||
                        state.psi.documents.some(
                          d => d.id === state?.psi?.selectedDocument?.id
                        )
                      }
                      onClick={e =>
                        addDocument('psi', state.psi.selectedDocument)
                      }
                      color="primary"
                      size="small"
                    >
                      Adauga Document
                    </Button>
                  </Grid>

                  {/* <br /> */}
                  <Grid md={8} sm={8} xs={8} item>
                    <ServerAutocomplete
                      onChange={(e, changeVal) => {
                        setSelectedQuiz('psi', changeVal);
                      }}
                      style={{ paddingRight: '15px', color: 'white' }}
                      variant="outlined"
                      label="Quiz"
                      margin="dense"
                      name="companie"
                      isLoading={state.loading}
                      getOptions={getQuizDropdown}
                    />
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Button
                      fullWidth
                      onClick={() => openPicker('PSI', 'Quiz')}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      Picker Quiz
                    </Button>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Button
                      variant="contained"
                      disabled={!state.psi.selectedQuiz || state.psi.quiz}
                      onClick={e => addQuiz('psi', state.psi.selectedQuiz)}
                      color="primary"
                      size="small"
                    >
                      Adauga Quiz
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardHeader title="RSVTI" />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid item md={6} sm={6} xs={6}>
                  <LabelDivider>Documente</LabelDivider>

                  {state.rsvti.documents.map((document, index) => (
                    <Typography>
                      {document.name}{' '}
                      <Button
                        onClick={e => removeDocument('rsvti', document.id)}
                      >
                        X
                      </Button>{' '}
                      <a href="#" onClick={() => downloadDocument(document)}>
                        Link
                      </a>
                    </Typography>
                  ))}
                  <LabelDivider>Quiz</LabelDivider>

                  {state.rsvti.quiz ? (
                    <Typography>
                      {state?.rsvti?.quiz?.name}{' '}
                      <a
                        href="#"
                        onClick={() => setQuiz(state?.rsvti?.quiz?.id)}
                      >
                        Link
                      </a>{' '}
                      <Button onClick={e => removeQuiz('rsvti')}>X</Button>{' '}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  container
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item md={8} sm={8} xs={8} item>
                    <ServerAutocomplete
                      onChange={(e, changeVal) => {
                        setSelectedDocument('rsvti', changeVal);
                      }}
                      style={{ paddingRight: '15px', color: 'white' }}
                      variant="outlined"
                      label="Document"
                      margin="dense"
                      name="document"
                      isLoading={state.loading}
                      getOptions={getDocumentDropdown}
                    />
                  </Grid>
                  <Grid md={4} sm={4} xs={4} item>
                    <Button
                      fullWidth
                      onClick={() => openPicker('RSVTI', 'Document')}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      Picker Documente
                    </Button>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} item>
                    <Button
                      variant="contained"
                      disabled={
                        !state.rsvti.selectedDocument ||
                        state.rsvti.documents.some(
                          d => d.id === state?.rsvti?.selectedDocument?.id
                        )
                      }
                      onClick={e =>
                        addDocument('rsvti', state.rsvti.selectedDocument)
                      }
                      color="primary"
                      size="small"
                    >
                      Adauga Document
                    </Button>
                  </Grid>

                  {/* <br /> */}
                  <Grid md={8} sm={8} xs={8} item>
                    <ServerAutocomplete
                      onChange={(e, changeVal) => {
                        setSelectedQuiz('rsvti', changeVal);
                      }}
                      style={{ paddingRight: '15px', color: 'white' }}
                      variant="outlined"
                      label="Quiz"
                      margin="dense"
                      name="companie"
                      isLoading={state.loading}
                      getOptions={getQuizDropdown}
                    />
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <Button
                      fullWidth
                      onClick={() => openPicker('RSVTI', 'Quiz')}
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      Picker Quiz
                    </Button>
                  </Grid>
                  <Grid md={12} sm={12} xs={12} item>
                    <Button
                      variant="contained"
                      disabled={!state.rsvti.selectedQuiz || state.rsvti.quiz}
                      onClick={e => addQuiz('rsvti', state.rsvti.selectedQuiz)}
                      color="primary"
                      size="small"
                    >
                      Adauga Quiz
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
        </DialogContent>
        <DialogActions>
          <Button disabled={state.loading} onClick={updateOrCreate}>
            Salveaza
          </Button>
          <Button
            disabled={state.loading}
            onClick={props.close}
          >
            Anuleaza
          </Button>
        </DialogActions>
      </Dialog>
      {state.quizId ? (
        <QuizTester
          id={state.quizId}
          isTest
          close={() =>
            setState(prevState => ({ ...prevState, quizId: undefined }))
          }
        />
      ) : (
        ''
      )}
      <DocumentPicker
        isOpen={isDocumentPickerOpen()}
        add={addDocumentPicker}
        close={closeDocumentPicker}
      />
      <QuizPicker
        isOpen={isQuizPickerOpen()}
        setQuiz={addQuizPicker}
        close={closeDocumentPicker}
      />
    </Fragment>
  );
};

export default compose(withRouter, withSnackbar)(EventsEditor);
