export const hasAuthority = (authority) => {
    if (!localStorage.key('authorities')) {
        return false;
    }
    let authorities = [];
    try {
        authorities = JSON.parse(localStorage.getItem('authorities'))
    }
    catch (error) {
        return false;
    }

    return authorities?.includes(authority);

}

export const isLoggedOut = () => {
    const loggedOut = !localStorage['authorities'];
    return loggedOut;
}


export const hasAnyOfAuthorities = (...authorities) => {

    for (const authority of authorities) {
        if (hasAuthority(authority)) {
            return true;
        }
    }
    return false;

}