import * as config from '../../constants';
import "isomorphic-fetch";
import axios from 'axios';



export function getAllUsersPaginated(page, size, filters, orderBy, orderDirection) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ page, elementsPerPage: size, filters, orderBy, orderDirection }),
    }
    return axios({ url: `${config.apiUrl}/employee/getPaginated`, ...requestOptions })
}


export function deleteUser(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/employee/delete`, ...requestOptions })
}


export function updateUser(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/employee/update`, ...requestOptions })
}


export function createUser(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/employee/create`, ...requestOptions })
}

export function getEmployeeDropdown(name) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ name: name }),
    }
    return axios({ url: `${config.apiUrl}/employee/getSelect`, ...requestOptions })
}

export function getReportEmployees(filter) {
    let formData = new FormData();
    formData.append('filters', JSON.stringify(filter));

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        responseType: 'arraybuffer',
        withCredentials: true,
        data: formData,
    }
    return axios({ url: `${config.apiUrl}/employee/getReport`, ...requestOptions })
}