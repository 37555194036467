export default theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    circularProgress: {
        position: 'absolute',
        left: 'calc(50% - 20px)',
    },
});