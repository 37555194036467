import React, { useEffect, useState } from 'react';
import {
    Breadcrumbs,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { getAllCompanyUsersPaginated, deleteCompanyUser, updateCompanyUser, createCompanyUser, getReportCompanyUser } from 'src/services/companyUser';
import { getCompanyById } from 'src/services/company';

import * as constants from 'src/components/EditableTable/constants'
import EditableTable from 'src/components/EditableTable';
import schema from './schema';
import { getCompanyDropdown } from 'src/services/company';
import { getUrlParameter } from 'src/utils/query';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import theme from './styles';
import { downloadFile } from 'src/utils/downloadFile';
import { hasAnyOfAuthorities } from 'src/utils/authUtils';
import { resetPassword } from 'src/services/user';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import UserImporter from './UserImporter';
import ConfirmationDialog from 'src/components/ConfirmationDialog';

const useStyles = makeStyles(theme);



const CompanyUser = (props) => {
    const classes = useStyles();
    const companyId = getUrlParameter('companyId', props.location.search);
    const [state, setState] = useState({ isImporterOpen: false, resetDialogVisible: false, resetEmail: undefined });
    let triggerRefresh;

    useEffect(() => {
        if (companyId)
            getCompanyById(companyId).then(({ data }) => setState(prevState => ({ ...prevState, companyName: data.name })));
    }, []);


    let companyDefinition = { title: 'Companie', name: 'companyName', type: constants.selectServer, sortable: true, definition: { id: 'companyId', label: 'companyName' }, getOptions: getCompanyDropdown };

    if (companyId) {
        companyDefinition = { ...companyDefinition, editableDisabled: true, fixedValue: { id: companyId, name: state.companyName } }
        schema['companyName'] = undefined;
    }

    const showError = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'error' });
    }

    const showSuccess = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'success' });
    }

    const setLoading = (mode) => {
        setState(prevState => ({ ...prevState, loading: mode }))
    }

    let columns = {
        definitions: [
            { title: 'Prenume', name: 'firstName', type: constants.text, sortable: true },
            { title: 'Nume', name: 'lastName', type: constants.text, sortable: true },
            { title: 'Email', name: 'email', type: constants.text, sortable: true },
            { title: 'Functie', name: 'job', type: constants.text, sortable: true },
            { title: 'Activ', name: 'active', type: constants.checkbox, sortable: true, defaultValue: true },
            { title: 'Creare', name: 'creationDate', type: constants.datetimepicker, sortable: true, editableDisabled: true },
            { ...companyDefinition },
        ],
        isDeleteDisabled: false,

        isEditable: true,
        validatorSchema: schema,
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'text', name: 'firstName', label: 'Prenume', size: 2, value: '' },
            { type: 'text', name: 'lastName', label: 'Nume', size: 2, value: '' },
            { type: 'text', name: 'email', label: 'Email', size: 2, value: '' },
            { type: 'text', name: 'job', label: 'Functie', size: 2, value: '' },
            { type: 'boolean', name: 'active', label: 'Activ', size: 1, value: '' }

        ],
        mandatoryFilters: [{ companyId }],
        setTriggerRefresh: (triggerRefreshFunction) => { triggerRefresh = triggerRefreshFunction }
    };

    if (!companyId) {
        columns.filterDefinitions = [...columns.filterDefinitions, { type: 'dropdown', name: 'companyName', label: 'Companie', size: 3, value: '', getOptions: getCompanyDropdown }]
    }
    else {
        columns = { ...columns, mandatoryDataCreate: { companyId, companyName: state.companyName } }

    }

    if (hasAnyOfAuthorities("ROLE_ADMIN")) {
        columns.customAction = {
            label: 'Reseteaza', title: 'Reseteaza parola', requiredActionFields: ["email"], action: (requiredFields) => {
                setResetEmail(requiredFields.email);
            }
        }
    }

    const setResetEmail = (email) => {
        setState(prevState => ({ ...prevState, resetDialogVisible: !!email, resetEmail: email }))
    }

    const resetEmail = () => {
        setLoading(true);
        resetPassword({ username: state.resetEmail }).then(() => {
            showSuccess('Parola a fost resetata cu succes pentru: ' + state.resetEmail)
            setLoading(false);
            setResetEmail(false);
        }
        ).catch(() => {
            showError('Parola nu a putut fi resetata pentru: ' + state.resetEmail)
            setLoading(false);
        }
        );
    }


    const getReport = (filter) => {
        setLoading(true);
        getReportCompanyUser(filter).then(({ data }) => {
            downloadFile(data, "Raport_Utilizator.xlsx", 'application/octet-stream')
            setLoading(false);
        }).catch(error => {
            setLoading(false);

        })
    }

    const icon = <AddToPhotosIcon style={{ color: '#3f51b5' }} />;
    const customActions = [{
        tooltip: 'Adaugare multipla', icon: icon, onClick: () => {
            setState((prevState) => ({ ...prevState, isImporterOpen: true }));

        }
    }]

    const closeImporter = () => {
        if (triggerRefresh) {
            triggerRefresh();
        }
        setState((prevState) => ({ ...prevState, isImporterOpen: false }));
    }


    return (
        <Page
            className={classes.root}
            title="Utilizatori"
        >
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/app/companii" >
                    Companii
                </Link>
                <a href="/app/company-user" >
                    Utilizatori
                </a>
                {state?.companyName ?
                    <Typography color="textPrimary">{state.companyName}</Typography>
                    : ''}
            </Breadcrumbs>

            <EditableTable columns={columns} queries={{
                getTableDataPaginatedQuery: getAllCompanyUsersPaginated,
                deleteTableData: deleteCompanyUser,
                updateTableData: updateCompanyUser,
                createTableData: createCompanyUser
            }}
                loading={state.loading}
                getReport={getReport}
                customActions={customActions}

            />
            <UserImporter isOpen={state.isImporterOpen} closeFunction={closeImporter} defaultCompanyId={companyId} defaultCompanyName={state.companyName} />
            <ConfirmationDialog visible={state.resetDialogVisible} title={"Confirmare resetare parola"}
                content={"Esti sigur ca doresti resetarea parolei pentru: " + state.resetEmail + " ?"}
                onCancel={() => { setResetEmail(false) }}
                onOk={() => { resetEmail() }}
                isLoading={state.loading}
            />
        </Page>
    );
};

export default compose(withRouter, withSnackbar)(CompanyUser);
