export default theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),

    },
    dialogTitle: {
        cursor: 'move'
    },
    dialogTitleText: {
        verticalAlign: 'middle'
    },
    circularProgress: {
        width: '25px!important',
        height: '25px!important',
        marginLeft: '10px',
        verticalAlign: 'middle'
    },
    switchContainer: {
        display: 'inline-flex',
        width: 'unset'
    },
    switchText: {
        fontSize: 14,
        fontWeight: '200',
        letterSpacing: '-0.05px'
    },
    dialogContent: {
        backgroundColor: '#f4f6f8'
    },
});