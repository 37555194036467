import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  createMuiTheme,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography
} from '@material-ui/core';
import { downloadDocument, getDocumentDropdown } from 'src/services/files';
import ServerAutocomplete from 'src/components/ServerAutocomplete';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { LabelDivider } from 'mui-label-divider';
import EventsEditor from './EventsEditor';
import { Fragment } from 'react';
import {
  createCopyContent,
  deleteContent,
  getAllDetailedContentPaginated,
  getReportSummaryContent,
  resendContentMail,
  sendCopyContent,
  sendTo,
  sendToMultiple,
  setCompletionDetails
} from 'src/services/content';
import _ from 'underscore';
import { AddBox } from '@material-ui/icons';
import Send from './Send';
import QuizTester from 'src/components/Quiz';
import { withSnackbar } from 'notistack';
import {
  getCompanyUserDropdown,
  getCompanyUserQueryDropdown
} from 'src/services/companyUser';
import { getCompanyDropdown } from 'src/services/company';
import { DateTimePicker } from '@material-ui/pickers';
import { format, parse } from 'date-fns';
import { hasAuthority } from 'src/utils/authUtils';
import Delete from './Delete';
import EditCompletion from './EditCompletion';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { downloadFile } from 'src/utils/downloadFile';
import { getReportDetailContent } from 'src/services/content';
import { getEmployeeDropdown } from 'src/services/users';
import PdfViewer from 'src/components/PdfViewer';
import ContentSender from './ContentSender';
import MassSearch from './MassSearch';
import MassConfirm from './MassConfirm';
import AppMassSend from './AppMassSend';
import RangeCalendar from 'src/components/RangeCalendar';

const customTheme = createMuiTheme({
  overrides: {
    MuiCardHeader: {
      root: { padding: '2px', paddingLeft: '5px' },
      title: { fontSize: '15px', fontWeight: 'bold' }
    },
    MuiCardContent: {
      root: { padding: '2px' }
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px'
    }
  }
});

const customThemeDropdown = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      outlined: {
        '&$shrink': { transform: 'translate(14px, -1.5px) scale(0.75)' },
        transform: 'translate(14px, 17px) scale(1)',
        color: '#546e7a'
      }
    },
    MuiFormLabel: {
      root: { fontSize: '0.950rem' }
    }
  },
  typography: {}
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    position: 'relative',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Events = props => {
  const classes = useStyles();
  const [state, setState] = useState({
    editMode: false,
    addMode: false,
    loading: false,
    page: 0,
    elementsPerPage: 3,
    totalElements: 0,
    filters: {},
    orderBy: 'id',
    orderDirection: 'desc',
    data: [],
    pdfViewer: {}
  });

  useEffect(() => {
    getInitialData();
  }, []);

  const documentsDtoToDocuments = dtos => {
    return dtos.map(dto => ({
      ...dto,
      type: dto.documentType,
      name: dto.documentName
    }));
  };

  const getData = (page, elementsPerPage, filters, orderBy, orderDirection) => {
    setLoading(true);
    getAllDetailedContentPaginated(
      page,
      elementsPerPage,
      filters,
      orderBy,
      orderDirection
    )
      .then(({ data }) => {
        let newData = data.content.map(content => ({
          ...content,
          documents: documentsDtoToDocuments(
            content.detailContentDocumentDtoList
          )
        }));

        setState(prevState => ({
          ...prevState,
          elementsPerPage: data.size,
          page: data.number,
          totalElements: data.totalElements,
          data: newData,
          loading: false
        }));
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  const setLoading = loadingState => {
    setState(prevState => ({ ...prevState, loading: loadingState }));
  };

  const getInitialData = () => {
    getData(0, 3, {}, 'id', 'desc');
  };

  const refreshData = () => {
    getData(
      state.page,
      state.elementsPerPage,
      state.filters,
      state.orderBy,
      state.orderDirection
    );
  };

  const changePage = (e, newPage) => {
    if (!state.loading)
      getData(
        newPage,
        state.elementsPerPage,
        state.filters,
        state.orderBy,
        state.orderDirection
      );
  };

  const changeRowsPerPage = e => {
    console.log(e);
    const newElementsPerPage = e.target.value;
    if (!state.loading)
      getData(
        0,
        newElementsPerPage,
        state.filters,
        state.orderBy,
        state.orderDirection
      );
  };

  useEffect(() => {
    if (Object.keys(state.filters)?.length > 0) {
      refreshFilters(state.filters);
    }
  }, [state.filters, state.orderBy]);

  const updateFilter = e => {
    const name = e.target.name;
    const value = e.target.value;
    setState(prevState => ({
      ...prevState,
      filters: { ...prevState.filters, [name]: value }
    }));
  };

  const updateDropdownFilter = (name, value) => {
    setState(prevState => ({
      ...prevState,
      filters: { ...prevState.filters, [name]: value }
    }));
  };

  const setIsSend = id => {
    setState(prevState => ({ ...prevState, isSend: true, sendId: id }));
  };

  const setIsCompletion = value => {
    setState(prevState => ({
      ...prevState,
      isEditCompletion: true,
      editCompletionData: {
        contentId: value.id,
        sendDate: value.sendDate,
        isComplete: value.completionDate && true,
        completionDate: value.completionDate
      }
    }));
  };

  const setCompletionDetailsHandler = data => {
    setLoading(true);
    setCompletionDetails(data)
      .then(({ data }) => {
        closeCompletionDialog();
        refreshData();
        setLoading(false);
      })
      .catch(error => {
        showError('A aparut o eroare la setarea datelor!');
        setLoading(false);
      });
  };

  const closePdfViewer = () => {
    setState(prevState => ({
      ...prevState,
      pdfViewer: { open: false, name: '' }
    }));
  };

  const openPdfViewer = pdfName => {
    setState(prevState => ({
      ...prevState,
      pdfViewer: { open: true, name: pdfName }
    }));
  };

  const closeCompletionDialog = () => {
    setState(prevState => ({
      ...prevState,
      isEditCompletion: false,
      editCompletionData: undefined
    }));
  };

  const setIsSendCopy = id => {
    setState(prevState => ({ ...prevState, isSendCopy: true, sendId: id }));
  };

  const setIsSendMass = id => {
    setState(prevState => ({ ...prevState, isSendMass: true, sendId: id }));
  };

  const setIsDelete = (id, name) => {
    setState(prevState => ({
      ...prevState,
      isDelete: true,
      deleteData: { id, name }
    }));
  };

  const closeDeleteDialog = () => {
    setState(prevState => ({
      ...prevState,
      isDelete: false,
      deleteData: undefined
    }));
  };

  const closeSendDialog = () => {
    setState(prevState => ({
      ...prevState,
      isSendMass: false,
      isSend: false,
      isSendCopy: false,
      sendId: undefined
    }));
  };

  const deleteContentData = id => {
    setLoading(true);
    deleteContent({ id })
      .then(({ data }) => {
        closeDeleteDialog();
        refreshData();
        setLoading(false);
      })
      .catch(error => {
        let message = 'A aparut o eroare la stergere!';
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;
        }
        showError(message);

        setLoading(false);
      });
  };

  const refreshFilters = useCallback(
    _.debounce(filters => {
      getData(
        state.page,
        state.elementsPerPage,
        filters,
        state.orderBy,
        state.orderDirection
      );
    }, 400),
    []
  );

  const addClick = () => {
    setState(prevState => ({ ...prevState, addMode: true }));
  };
  const closeDialog = () => {
    setState(prevState => ({ ...prevState, addMode: false, editMode: false }));
  };

  const editClick = editData => {
    setState(prevState => ({ ...prevState, editMode: true, editData }));
  };

  const sendContentToUser = (
    contentId,
    users,
    extraCC,
    extraDetails,
    canDownload,
    canDownloadForward,
    canDownloadQuiz,
    canForward
  ) => {
    const newExtraCC = extraCC.map(cc => cc.name);
    {
      if (users.length == 1) {
        const userId = users[0].id;
        if (state.isSendCopy) {
          sendContentCopy(
            contentId,
            userId,
            newExtraCC,
            extraDetails,
            canDownload,
            canDownloadForward,
            canDownloadQuiz,
            canForward
          );
          return;
        }
        const detailedContent = state?.data?.find(d => d.id === contentId);
        setLoading(true);
        sendTo({
          contentId,
          userId,
          newExtraCC,
          extraDetails,
          canDownload,
          canDownloadForward,
          canDownloadQuiz,
          canForward
        })
          .then(({ data }) => {
            refreshData();
            closeSendDialog();
            setLoading(false);
          })
          .catch(error => {
            let message = 'A aparut o eroare la trimitere!';
            if (error?.response?.data?.message) {
              message = error?.response?.data?.message;
            }

            showError(message);
            setLoading(false);
          });
      } else {
        setLoading(true);
        sendToMultiple({
          contentId,
          users,
          newExtraCC,
          extraDetails,
          canDownload,
          canDownloadForward,
          canDownloadQuiz,
          canForward
        })
          .then(({ data }) => {
            refreshData();
            closeSendDialog();
            setLoading(false);
          })
          .catch(error => {
            let message = 'A aparut o eroare la trimitere!';
            if (error?.response?.data?.message) {
              message = error?.response?.data?.message;
            }

            showError(message);
            setLoading(false);
          });
      }
    }
  };

  const setQuiz = quizId => {
    setState(prevState => ({ ...prevState, quizId }));
  };

  const createContentCopy = id => {
    setLoading(true);
    createCopyContent(id)
      .then(() => refreshData())
      .catch(error => {
        setLoading(false);
        let message = 'A aparut o eroare crearea unei copii!';
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;
        }

        showError(message);
      });
  };

  const sendContentCopy = (
    contentId,
    userId,
    extraCC,
    extraDetails,
    canDownload,
    canDownloadForward,
    canDownloadQuiz,
    canForward
  ) => {
    setLoading(true);
    sendCopyContent({
      contentId,
      userId,
      extraCC,
      extraDetails,
      canDownload,
      canDownloadForward,
      canDownloadQuiz,
      canForward
    })
      .then(() => {
        closeSendDialog();
        refreshData();
      })
      .catch(error => {
        setLoading(false);
        let message = 'A aparut o eroare trimiterea copiei!';
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;
        }
        showError(message);
      });
  };

  const resendMail = (contentId, userId) => {
    setLoading(true);
    const detailedContent = state?.data?.find(d => d.id === contentId);
    resendContentMail({ ...detailedContent, sentToId: userId })
      .then(({ data }) => {
        setLoading(false);
        showSuccess('Datele s-au trimis cu succes!');
      })
      .catch(error => {
        let message = 'A aparut o eroare la trimitere!';
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;
        }

        showError(message);
        setLoading(false);
      });
  };

  const getCustomCheckBox = (label, value) => (
    <FormControlLabel
      style={{ marginLeft: '1px' }}
      control={
        <Checkbox
          style={{
            marginLeft: '0px',
            paddingLeft: '0px',
            backgroundColor: 'transparent',
            cursor: 'unset'
          }}
          disableRipple
          checked={value}
          color="primary"
          size="small"
          name="activ"
        />
      }
      label={<Typography style={{ fontSize: '14px' }}>{label}</Typography>}
    />
  );

  const showError = message => {
    props.enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 60000,
      style: { whiteSpace: 'pre-line' },
      action: key => (
        <Button onClick={e => props.closeSnackbar(key)}>
          <span style={{ color: 'white' }}>{'Inchide'}</span>
        </Button>
      )
    });
  };

  const showSuccess = message => {
    props.enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: 5000,
      style: { whiteSpace: 'pre-line' }
    });
  };

  const downloadReport = () => {
    setLoading(true);
    getReportDetailContent(state.filters)
      .then(({ data }) => {
        downloadFile(
          data,
          'Raport Detaliat SSM&PSI.xlsx',
          'application/octet-stream'
        );

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <EventsEditor
        addMode={state.addMode}
        editMode={state.editMode}
        close={closeDialog}
        refresh={refreshData}
        editData={state.editData}
      />
      {state.loading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            left: 'calc(50% - 20px)',
            top: 'calc(50% - 20px)'
          }}
        />
      ) : (
        ''
      )}
      <Box key="master box" style={state.loading ? { opacity: '0.3' } : {}}>
        <Card key="main card">
          <CardContent key="card content">
            <Box key="box">
              <Tooltip
                title="Adauga"
                placement="bottom"
                disabled={state.isLoading}
                style={{
                  verticalAlign: 'middle',
                  float: 'right',
                  marginRight: '10px',
                  marginBottom: '10px',
                  marginTop: '13px'
                }}
              >
                <IconButton
                  disabled={state.isLoading}
                  color="inherit"
                  aria-label="edit"
                  onClick={() => addClick()}
                >
                  <AddBox style={{ color: '#3f51b5' }} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Descarca raport"
                style={{
                  verticalAlign: 'middle',
                  float: 'right',
                  marginBottom: '10px',
                  marginTop: '13px'
                }}
                placement="bottom"
                disabled={state.isLoading || props.loading}
              >
                <IconButton
                  disabled={state.isLoading || props.loading}
                  color="inherit"
                  aria-label="edit"
                  onClick={() => downloadReport()}
                >
                  <SystemUpdateAltOutlinedIcon style={{ color: '#3f51b5' }} />
                </IconButton>
              </Tooltip>
              <Grid
                container
                key={'container container filtru nume'}
                spacing={1}
                style={{
                  paddingRight: '15px',
                  paddingLeft: '15px',
                  width: 'inherit',
                  paddingTop: '5px',
                  verticalAlign: 'middle'
                }}
                justify="start"
                alignItems="center"
              >
                <Grid key="container filtru nume" item xs={2}>
                  <TextField
                    key={'filtru nume'}
                    style={{ fontSize: '14px' }}
                    // inputProps={{
                    //     style: {
                    //         fontSize: '14px',
                    //     },
                    // }}
                    // inputLabelProps={{
                    //     style: { fontSize: '14px' }
                    // }}
                    // disabled={!!state.loading}
                    fullWidth
                    label="Nume"
                    margin="dense"
                    name="name"
                    onChange={e => updateFilter(e)}
                    value={state.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <ServerAutocomplete
                    onChange={(e, changeVal) => {
                      updateDropdownFilter('companyId', changeVal?.id);
                    }}
                    label="Companie"
                    variant="outlined"
                    disabled={state.loading}
                    getOptions={getCompanyDropdown}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ServerAutocomplete
                    onChange={(e, changeVal) => {
                      updateDropdownFilter('sentToId', changeVal?.id);
                    }}
                    label="User"
                    variant="outlined"
                    disabled={state.loading}
                    getOptions={getCompanyUserQueryDropdown}
                  />
                </Grid>
                <Grid item xs={2}>
                  <ThemeProvider theme={customThemeDropdown}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="demo-simple-select-filled-label"
                        style={{ backgroundColor: 'white' }}
                      >
                        Activ
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        fullWidth
                        label="Activ"
                        margin="dense"
                        variant="outlined"
                        displayEmpty
                        // inputProps={{
                        //     style:{
                        //         marginTop: theme.spacing(2),
                        //     }
                        // }}
                        style={{ marginTop: '4px' }}
                        onChange={e =>
                          updateDropdownFilter('active', e.target.value)
                        }
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem
                          disableAutoFocus
                          disableEnforceFocus
                          disableRestoreFocus
                          value=" "
                        >
                          <em>Nesetat</em>
                        </MenuItem>
                        <MenuItem
                          disableEnforceFocus
                          disableRestoreFocus
                          disableAutoFocus
                          value={'true'}
                        >
                          Da
                        </MenuItem>
                        <MenuItem
                          disableEnforceFocus
                          disableRestoreFocus
                          disableAutoFocus
                          value={'false'}
                        >
                          Nu
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={2}>
                  <ThemeProvider theme={customThemeDropdown}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="demo-simple-select-filled-label"
                        style={{ backgroundColor: 'white' }}
                      >
                        Finalizat
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        fullWidth
                        label="Finalizat"
                        margin="dense"
                        variant="outlined"
                        displayEmpty
                        // inputProps={{
                        //     style:{
                        //         marginTop: theme.spacing(2),
                        //     }
                        // }}
                        style={{ marginTop: '4px' }}
                        onChange={e =>
                          updateDropdownFilter('completionDate', e.target.value)
                        }
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem
                          disableEnforceFocus
                          disableRestoreFocus
                          disableAutoFocus
                          value=" "
                        >
                          <em>Nesetat</em>
                        </MenuItem>
                        <MenuItem
                          disableEnforceFocus
                          disableRestoreFocus
                          disableAutoFocus
                          value={'true'}
                        >
                          Da
                        </MenuItem>
                        <MenuItem
                          disableEnforceFocus
                          disableRestoreFocus
                          disableAutoFocus
                          value={'false'}
                        >
                          Nu
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={2}>
                  <ServerAutocomplete
                    onChange={(e, changeVal) => {
                      updateDropdownFilter('sentByEmail', changeVal?.name);
                    }}
                    label="Trimis de"
                    variant="outlined"
                    disabled={state.loading}
                    getOptions={getEmployeeDropdown}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ServerAutocomplete
                    onChange={(e, changeVal) => {
                      updateDropdownFilter('forwardedById', changeVal?.id);
                    }}
                    useId
                    label="Redirectionat de"
                    variant="outlined"
                    disabled={state.loading}
                    getOptions={getCompanyUserQueryDropdown}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        <br />

        {state.data.map((value, index) => (
          <span key={value.id}>
            <ThemeProvider theme={customTheme}>
              <Grid container spacing={2} wrap="wrap">
                <Grid item md={12} sm={12} xs={12}>
                  <Card style={{ height: '100%' }}>
                    <Grid
                      container
                      spacing={2}
                      wrap="wrap"
                      style={{ padding: '8px' }}
                    >
                      <Grid item md={4} sm={4} xs={4}>
                        <Typography variant="h6">Date generale</Typography>
                      </Grid>
                      <Grid item md={4} sm={4} xs={4}>
                        <Typography variant="h6">SSM</Typography>
                      </Grid>
                      <Grid item md={4} sm={4} xs={4}>
                        <Typography variant="h6">PSI</Typography>
                      </Grid>
                    </Grid>

                    <Divider />
                    <CardContent>
                      <Grid container spacing={2} wrap="wrap">
                        <Grid item md={4} sm={4} xs={4}>
                          <TextField
                            style={{ marginLeft: '5px', fontSize: '14px' }}
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                fontSize: '14px'
                              }
                            }}
                            disabled={state.loading}
                            InputLabelProps={{
                              style: { fontSize: '14px' }
                            }}
                            fullWidth
                            label="Nume"
                            margin="dense"
                            name="name"
                            value={value.name || ''}
                          />
                          {value?.sentTo ? (
                            <Fragment>
                              <TextField
                                style={{ marginLeft: '5px', fontSize: '14px' }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: '14px'
                                  }
                                }}
                                disabled={state.loading}
                                InputLabelProps={{
                                  style: { fontSize: '14px' }
                                }}
                                fullWidth
                                multiline
                                label="Trimis catre"
                                margin="dense"
                                name="name"
                                value={
                                  value?.sentTo?.firstName +
                                  ' ' +
                                  value?.sentTo?.lastName +
                                  '\n' +
                                  value?.sentTo?.email || ''
                                }
                              />
                              {value?.sentToCompany?.name ? (
                                <TextField
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: '14px'
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      fontSize: '14px'
                                    }
                                  }}
                                  disabled={state.loading}
                                  InputLabelProps={{
                                    style: { fontSize: '14px' }
                                  }}
                                  fullWidth
                                  label="Companie"
                                  margin="dense"
                                  name="name"
                                  value={value?.sentToCompany?.name || ''}
                                />
                              ) : (
                                ''
                              )}
                              {console.log('value=', value)}
                              {value?.forwardedByFirstName &&
                                value?.forwardedByLastName &&
                                value?.forwardedByEmail ? (
                                <TextField
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: '14px'
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      fontSize: '14px'
                                    }
                                  }}
                                  disabled={state.loading}
                                  InputLabelProps={{
                                    style: { fontSize: '14px' }
                                  }}
                                  fullWidth
                                  multiline
                                  label="Redirectionat de"
                                  margin="dense"
                                  name="name"
                                  value={
                                    value?.forwardedByFirstName +
                                    ' ' +
                                    value?.forwardedByLastName +
                                    '\n' +
                                    value?.forwardedByEmail || ''
                                  }
                                />
                              ) : (
                                ''
                              )}
                              <TextField
                                style={{ marginLeft: '5px', fontSize: '14px' }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: '14px'
                                  }
                                }}
                                disabled={state.loading}
                                InputLabelProps={{
                                  style: { fontSize: '14px' }
                                }}
                                fullWidth
                                label="Trimis la data"
                                margin="dense"
                                name="name"
                                value={
                                  value?.sendDate
                                    ? format(
                                      new Date(value?.sendDate),
                                      'dd/MM/yyyy HH:mm:ss'
                                    )
                                    : ''
                                }
                              />
                              {value?.lastAccessDate && (
                                <TextField
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: '14px'
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      fontSize: '14px'
                                    }
                                  }}
                                  disabled={state.loading}
                                  InputLabelProps={{
                                    style: { fontSize: '14px' }
                                  }}
                                  fullWidth
                                  label="Accesat la data"
                                  margin="dense"
                                  name="name"
                                  value={
                                    value?.lastAccessDate
                                      ? format(
                                        new Date(value?.lastAccessDate),
                                        'dd/MM/yyyy HH:mm:ss'
                                      )
                                      : ''
                                  }
                                />
                              )}
                              {value?.completionDate ? (
                                <TextField
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: '14px'
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      fontSize: '14px'
                                    }
                                  }}
                                  disabled={state.loading}
                                  InputLabelProps={{
                                    style: { fontSize: '14px' }
                                  }}
                                  fullWidth
                                  label="Finalizat la data"
                                  margin="dense"
                                  name="name"
                                  value={
                                    value?.completionDate
                                      ? format(
                                        new Date(value?.completionDate),
                                        'dd/MM/yyyy HH:mm:ss'
                                      )
                                      : ''
                                  }
                                />
                              ) : (
                                ''
                              )}
                              {getCustomCheckBox('Activ', value?.active)}
                              <p>
                                {value?.forwardedByEmail
                                  ? getCustomCheckBox(
                                    'Poate descarca teste',
                                    value?.canDownloadQuiz &&
                                    value?.canDownloadForward
                                  )
                                  : getCustomCheckBox(
                                    'Poate descarca teste',
                                    value?.canDownloadQuiz
                                  )}
                                {value?.forwardedByEmail
                                  ? getCustomCheckBox(
                                    'Poate descarca documente',
                                    value?.canDownload &&
                                    value?.canDownloadForward
                                  )
                                  : getCustomCheckBox(
                                    'Poate descarca documente',
                                    value?.canDownload
                                  )}
                                {value?.forwardedByEmail
                                  ? getCustomCheckBox(
                                    'Poate redirectiona',
                                    false
                                  )
                                  : getCustomCheckBox(
                                    'Poate redirectiona',
                                    value?.canForward
                                  )}
                              </p>
                              <TextField
                                style={{ marginLeft: '5px', fontSize: '14px' }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: '14px'
                                  }
                                }}
                                disabled={state.loading}
                                InputLabelProps={{
                                  style: { fontSize: '14px' }
                                }}
                                fullWidth
                                label="Trimis de"
                                margin="dense"
                                name="name"
                                value={value?.sentByEmail}
                              />
                            </Fragment>
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Grid
                          item
                          container
                          spacing={2}
                          wrap="wrap"
                          style={{ padding: '8px' }}
                          md={8}
                          sm={8}
                          xs={8}
                        >
                          <Grid item md={6} sm={6} xs={6}>
                            <LabelDivider>Documente</LabelDivider>
                            {value.documents
                              .filter(d => d.type === 'SSM')
                              .map((document, index) => (
                                <Fragment>
                                  <Typography
                                    style={{
                                      marginLeft: '5px',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {document.name}
                                    <Button
                                      style={{ marginLeft: '16px' }}
                                      onClick={() =>
                                        openPdfViewer(document.name)
                                      }
                                    >
                                      Arata
                                    </Button>
                                    <Button
                                      onClick={() => downloadDocument(document)}
                                    >
                                      Link
                                    </Button>{' '}
                                    {getCustomCheckBox(
                                      undefined,
                                      document?.downloaded
                                    )}
                                  </Typography>
                                </Fragment>
                              ))}
                            <LabelDivider>Quiz</LabelDivider>
                            {value.ssmQuizId && value.ssmQuizName ? (
                              <Typography
                                style={{ marginLeft: '5px', fontSize: '14px' }}
                              >
                                {value.ssmQuizName}{' '}
                                <Button
                                  onClick={() => setQuiz(value.ssmQuizId)}
                                >
                                  Link
                                </Button>{' '}
                                {getCustomCheckBox(
                                  undefined,
                                  value?.ssmQuizFinished
                                )}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Grid>
                          <Grid item md={6} sm={6} xs={6}>
                            <LabelDivider>Documente</LabelDivider>
                            {value.documents
                              .filter(d => d.type === 'PSI')
                              .map((document, index) => (
                                <Fragment>
                                  <Typography
                                    style={{
                                      marginLeft: '5px',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {document.name}
                                    <Button
                                      style={{ marginLeft: '16px' }}
                                      onClick={() =>
                                        openPdfViewer(document.name)
                                      }
                                    >
                                      Arata
                                    </Button>
                                    <Button
                                      onClick={() => downloadDocument(document)}
                                    >
                                      Link
                                    </Button>{' '}
                                    {getCustomCheckBox(
                                      undefined,
                                      document?.downloaded
                                    )}
                                  </Typography>
                                </Fragment>
                              ))}
                            <LabelDivider>Quiz</LabelDivider>
                            {value.psiQuizId && value.psiQuizName ? (
                              <Typography
                                style={{ marginLeft: '5px', fontSize: '14px' }}
                              >
                                {value.psiQuizName}{' '}
                                <Button
                                  onClick={() => setQuiz(value.psiQuizId)}
                                >
                                  Link
                                </Button>{' '}
                                {getCustomCheckBox(
                                  undefined,
                                  value?.psiQuizFinished
                                )}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6">RSVTI</Typography>
                            <LabelDivider>Documente</LabelDivider>
                            {value.documents
                              .filter(d => d.type === 'RSVTI')
                              .map((document, index) => (
                                <Fragment>
                                  <Typography
                                    style={{
                                      marginLeft: '5px',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {document.name}
                                    <Button
                                      style={{ marginLeft: '16px' }}
                                      onClick={() =>
                                        openPdfViewer(document.name)
                                      }
                                    >
                                      Arata
                                    </Button>
                                    <Button
                                      onClick={() => downloadDocument(document)}
                                    >
                                      Link
                                    </Button>{' '}
                                    {getCustomCheckBox(
                                      undefined,
                                      document?.downloaded
                                    )}
                                  </Typography>
                                </Fragment>
                              ))}
                            <LabelDivider>Quiz</LabelDivider>
                            {value.rsvtiQuizId && value.rsvtiQuizName ? (
                              <Typography
                                style={{ marginLeft: '5px', fontSize: '14px' }}
                              >
                                {value.rsvtiQuizName}{' '}
                                <Button
                                  onClick={() => setQuiz(value.rsvtiQuizId)}
                                >
                                  Link
                                </Button>{' '}
                                {getCustomCheckBox(
                                  undefined,
                                  value?.rsvtiQuizFinished
                                )}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-start" p={1}>
                      <Button
                        style={{ fontSize: '12px', fontWeight: '600px' }}
                        size="small"
                        onClick={() => editClick(value)}
                      >
                        Editeaza
                      </Button>
                      <Button
                        size="small"
                        style={{ fontSize: '12px', fontWeight: '600px' }}
                        onClick={() => createContentCopy(value.id)}
                        style={{ marginLeft: '5px' }}
                      >
                        Creaza copie
                      </Button>
                      <Button
                        size="small"
                        style={{ fontSize: '12px', fontWeight: '600px' }}
                        onClick={() => setIsSendMass(value.id)}
                        style={{ marginLeft: '5px' }}
                      >
                        Trimitere Multipla
                      </Button>
                      {value?.sentTo ? (
                        <Fragment>
                          <Button
                            size="small"
                            style={{ fontSize: '12px', fontWeight: '600px' }}
                            onClick={() => setIsSendCopy(value.id)}
                            style={{ marginLeft: '5px' }}
                          >
                            Trimite copie
                          </Button>
                        </Fragment>
                      ) : (
                        <Button
                          size="small"
                          style={{ fontSize: '12px', fontWeight: '600px' }}
                          onClick={() => setIsSend(value.id)}
                          style={{ marginLeft: '5px' }}
                        >
                          Trimite
                        </Button>
                      )}
                      <Button
                        size="small"
                        style={{ fontSize: '12px', fontWeight: '600px' }}
                        onClick={() => setIsDelete(value.id, value.name)}
                        style={{ marginLeft: '5px' }}
                      >
                        Sterge
                      </Button>
                      {value?.sentTo && hasAuthority('ROLE_ADMIN') ? (
                        <Button
                          size="small"
                          style={{ fontSize: '12px', fontWeight: '600px' }}
                          onClick={() => setIsCompletion(value)}
                          style={{ marginLeft: '5px' }}
                        >
                          Seteaza date finalizare
                        </Button>
                      ) : (
                        ''
                      )}
                      {value?.sendDate ? (
                        <Button
                          size="small"
                          style={{ fontSize: '12px', fontWeight: '600px' }}
                          onClick={() => resendMail(value.id, value.sentTo.id)}
                          style={{ marginLeft: '5px' }}
                        >
                          Retrimite mail
                        </Button>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Card>
                </Grid>
              </Grid>

              <br />
            </ThemeProvider>
          </span>
        ))}
        <Card>
          <CardContent>
            <Grid container justify="flex-end">
              <Grid item>
                <table>
                  <tbody>
                    <tr>
                      <TablePagination
                        disabled={state.loading}
                        style={{ borderBottom: '0px' }}
                        rowsPerPageOptions={[3, 6]}
                        count={state.totalElements}
                        rowsPerPage={state.elementsPerPage}
                        page={state.page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' }
                        }}
                        labelRowsPerPage="Numar inregistrari per pagina"
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} din ${count}`
                        }
                        backIconButtonText="Pagina anterioara"
                        nextIconButtonText="Pagina urmatoare"
                        onChangePage={changePage}
                        onChangeRowsPerPage={changeRowsPerPage}
                      />
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {state.quizId ? (
        <QuizTester
          id={state.quizId}
          isTest
          close={() =>
            setState(prevState => ({ ...prevState, quizId: undefined }))
          }
        />
      ) : (
        ''
      )}

      <Send
        isSend={state.isSend || state.isSendCopy || false}
        id={state.sendId}
        loading={state.loading}
        close={closeSendDialog}
        send={sendContentToUser}
      />
      <Delete
        isDelete={state?.isDelete || false}
        id={state?.deleteData?.id}
        name={state?.deleteData?.name}
        loading={state.loading}
        close={closeDeleteDialog}
        delete={deleteContentData}
      />
      <EditCompletion
        isEditCompletion={state?.isEditCompletion || false}
        data={state?.editCompletionData}
        loading={state.loading}
        close={closeCompletionDialog}
        setCompletion={setCompletionDetailsHandler}
      />
      <PdfViewer close={closePdfViewer} {...state.pdfViewer} />
      <ContentSender
        isOpen={state.isSendMass}
        id={state.sendId}
        close={closeSendDialog}
        refreshFunction={refreshData}
      />
    </Fragment>
  );
};

export default compose(withRouter, withSnackbar)(Events);
