import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    CircularProgress,
    TextField
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { compose } from "recompose";


const ConfirmationText = ({ open, onConfirm, onCancel, defaultValue, label, title, isLoading }) => {

    const [currentValue, setCurrentValue] = useState('');


    return (<Fragment>
        <Dialog
            open={open}
            aria-labelledby="draggable-dialog-title"
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle >
                <Typography variant='h4' style={{ verticalAlign: 'middle' }} component={'span'}>
                    {title}
                    {isLoading && <CircularProgress size={25} style={{ marginLeft: '7px', verticalAlign: 'middle' }} />}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ backgroundColor: '#f4f6f8' }}>
                <Typography variant='body1'>
                    <TextField fullWidth label={label} multiline rows={8} onChange={(e) => setCurrentValue(e.target.value)} defaultValue={defaultValue} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={() => { onCancel() }
                }>
                    Refuza</Button>

                <Button color="primary"
                    disabled={isLoading}
                    onClick={() => {
                        onConfirm(currentValue)
                    }}>Confirma</Button>

            </DialogActions>
        </Dialog>

    </Fragment >)
}

export default ConfirmationText;