import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
// import routes from 'src/routes';
import { SnackbarProvider } from 'notistack';
import history from './utils/history';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import SettingsView from 'src/views/settings/SettingsView';
import DownloadView from './views/downloads/DownloadView';
import Users from './views/users';
import Company from './views/company1';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CompanyUser from './views/companyUser';
import UploadFiles from './views/files';
import Reminder from './views/reminder';
import Events from './views/events';
import Quiz from './views/quiz';
import { hasAnyOfAuthorities, isLoggedOut } from './utils/authUtils';
import GdprDialog from './components/GdprDialog';
import RangeCalendar from './components/RangeCalendar';

const App = () => {

  const [state, setState] = useState({ refresh: 0 });

  history.listen((location, action) => {
    if ((location.pathname === 'login' || location.pathname === '/')) {
      setState({ refresh: (state.refresh + 1) })
    }
  });



  return (
    <>
      <Router history={history}>

        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <SnackbarProvider maxSnack={4} autoHideDuration={3500}>

              <GlobalStyles />
              <Route path='/' component={MainLayout} />
              <Route path='/app' component={DashboardLayout} />
              <Route path='/downloads' component={() => <DashboardLayout disabledNav />} />

              <Switch>
                <Route path='/login' component={LoginView} />
                <Route path='/404' component={NotFoundView} />
                {hasAnyOfAuthorities("ROLE_ADMIN") ?
                  <Route path='/app/users' component={Users} />
                  : undefined}
                {hasAnyOfAuthorities("ROLE_USER") ?
                  <Route path='/downloads' component={DownloadView} />
                  : undefined}
                {hasAnyOfAuthorities("ROLE_USER") && !hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/' exact render={() => (<Redirect to="/downloads" />)} />
                  : undefined}
                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/app/products' component={ProductListView} />
                  : undefined
                }
                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/app/settings' component={SettingsView} />
                  : undefined
                }
                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/app/companii' component={Company} />
                  : undefined
                }
                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/app/company-user' component={CompanyUser} />
                  : undefined
                }
                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/app/upload' component={UploadFiles} />
                  : undefined
                }
                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/app/reminder' component={Reminder} />
                  : undefined
                }

                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/app/events' component={Events} />
                  : undefined
                }
                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/app/quiz' component={Quiz} />
                  : undefined
                }
                {hasAnyOfAuthorities("ROLE_EMPLOYEE") ?
                  <Route path='/' exact render={() => (<Redirect to="/app/events" />)} />
                  : undefined
                }

                {isLoggedOut() ?
                  <Route path='/' exact render={() => (<Redirect to="/login" />)} />
                  : undefined
                }

                <Route path='*' render={() => (<Redirect to="/404" />)} />
              </Switch>
              <GdprDialog />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Router>
    </>

  );
};

export default App;
