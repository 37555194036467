import * as config from '../../constants';
import "isomorphic-fetch";
import axios from 'axios';
import { downloadFile } from 'src/utils/downloadFile';


export function getAllDetailedContentPaginated(page, size, filters, orderBy, orderDirection) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ page, elementsPerPage: size, filters, orderBy, orderDirection }),
    }
    return axios({ url: `${config.apiUrl}/content/getPaginated/detailContent`, ...requestOptions })
}

export function getReportDetailContent(filter) {
    let formData = new FormData();
    formData.append('filters', JSON.stringify(filter));

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        responseType: 'arraybuffer',
        withCredentials: true,
        data: formData,
    }
    return axios({ url: `${config.apiUrl}/content/getReport/detailContent`, ...requestOptions })
}

export function getReportForward(filter) {
    let formData = new FormData();
    formData.append('filters', JSON.stringify(filter));

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        responseType: 'arraybuffer',
        withCredentials: true,
        data: formData,
    }
    return axios({ url: `${config.apiUrl}/content/user/getReport`, ...requestOptions })
}


export function getAllSummaryContentPaginated(page, size, filters, orderBy, orderDirection) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ page, elementsPerPage: size, filters, orderBy, orderDirection }),
    }
    return axios({ url: `${config.apiUrl}/content/getPaginated/summaryContent`, ...requestOptions })
}

export function getReportSummaryContent(filter) {
    let formData = new FormData();
    formData.append('filters', JSON.stringify(filter));

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        responseType: 'arraybuffer',
        withCredentials: true,
        data: formData,
    }
    return axios({ url: `${config.apiUrl}/content/getReport/summaryContent`, ...requestOptions })
}

export function createContent(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/create`, ...requestOptions })
}

export function deleteContent(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/delete`, ...requestOptions })
}


export function updateContent(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/update`, ...requestOptions })
}

export function getContent(id) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ id }),
    }
    return axios({ url: `${config.apiUrl}/content/getById`, ...requestOptions })
}

export function createCopyContent(id) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ id }),
    }
    return axios({ url: `${config.apiUrl}/content/createCopy`, ...requestOptions })
}

export function sendCopyContent(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/sendCopy`, ...requestOptions })
}

export function setCompletionDetails(completionDetails) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(completionDetails),
    }
    return axios({ url: `${config.apiUrl}/content/setCompletionDetails`, ...requestOptions })
}

export function sendTo(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/sendTo`, ...requestOptions })
}

export function sendToMultiple(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/sendToMultiple`, ...requestOptions })
}

export function sendToMultipleConfirmation(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/sendToMultipleUsersConfirm`, ...requestOptions })
}

export function resendContentMail(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/resendContentMail`, ...requestOptions })
}

export function getActiveForCompanyUser() {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        // data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/getActiveForCompanyUser`, ...requestOptions })
}

export function getForwardedForCompanyUser(page, size, filters, orderBy, orderDirection) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify({ page, elementsPerPage: size, filters, orderBy, orderDirection }),
    }
    return axios({ url: `${config.apiUrl}/content/getPaginated/getForwardedForCompanyUser`, ...requestOptions })
}

export function forwardContent(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/forward`, ...requestOptions })
}

export function forwardContentToExistingUser(data) {
    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": 'application/json' },
        withCredentials: true,
        data: JSON.stringify(data),
    }
    return axios({ url: `${config.apiUrl}/content/forwardExisting`, ...requestOptions })
}

export function downloadTemplate(onDownloadProgress) {
    const requestOptions =
    {
        method: 'POST',
        // headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onDownloadProgress,
        responseType: 'arraybuffer'
        // data: formData,
    }


    return axios({ url: `${config.apiUrl}/content/downloadTemplate`, ...requestOptions })
        .then(response => { downloadFile(response.data, 'template-utilizatori_redirectionare.xlsx', 'application/octet-stream') });
}


//downloadSendTemplate
export function downloadSendTemplate(onDownloadProgress) {
    const requestOptions =
    {
        method: 'POST',
        // headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onDownloadProgress,
        responseType: 'arraybuffer'
        // data: formData,
    }


    return axios({ url: `${config.apiUrl}/content/downloadSendTemplate`, ...requestOptions })
        .then(response => { downloadFile(response.data, 'template-utilizatori-mass.xlsx', 'application/octet-stream') });
}


export function uploadUsersExcel(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onUploadProgress,
        data: formData,
    }

    return axios({ url: `${config.apiUrl}/content/importUsers`, ...requestOptions })
}


export function uploadUsersExcelMass(file, contentId, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("contentId", contentId);

    const requestOptions =
    {
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onUploadProgress,
        data: formData,
    }

    return axios({ url: `${config.apiUrl}/content/sendMass`, ...requestOptions })
}

// export function getQuizDropdown(name) {
//     const requestOptions =
//     {
//         method: 'POST',
//         headers: { "Content-Type": 'application/json' },
//         withCredentials: true,
//         data: JSON.stringify({ name: name }),
//     }
//     return axios({ url: `${config.apiUrl}/quiz/getSelect`, ...requestOptions })
// }