import React from 'react';
import {
    makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { getAllRemindersPaginated, deleteReminder, updateReminder, createReminder } from 'src/services/reminder';
import * as constants from 'src/components/EditableTable/constants'
import EditableTable from 'src/components/EditableTable';
import schema from './schema';
import { getCompanyDropdown } from 'src/services/company';
import { getEmployeeDropdown } from 'src/services/users';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { hasAnyOfAuthorities } from 'src/utils/authUtils';
import theme from './styles'
import { getCompanyUserDropdown } from 'src/services/companyUser';

const useStyles = makeStyles(theme);

const Reminder = (props) => {
    const classes = useStyles();
    let definitions = [
        { title: 'Mesaj', name: 'description', type: constants.textarea, sortable: false },
        { title: 'Data', name: 'triggerDate', type: constants.datetimepicker, sortable: true },
        { title: 'Despre compania', name: 'companyName', type: constants.selectServer, sortable: true, definition: { id: 'companyId', label: 'companyName' }, getOptions: getCompanyDropdown },
        { title: 'Despre userul', name: 'username', type: constants.selectServer, sortable: true, definition: { id: 'userId', label: 'username' }, getOptions: getCompanyUserDropdown }
    ]

    if (hasAnyOfAuthorities("ROLE_ADMIN")) {
        definitions = [...definitions,
        { title: 'Angajat asociat', name: 'associatedUsername', type: constants.selectServer, sortable: true, definition: { id: 'associatedUserId', label: 'associatedUsername' }, getOptions: getEmployeeDropdown }
        ]
    }

    definitions = [...definitions,
    { title: 'Trimis', name: 'sent', type: constants.checkbox, sortable: true, editableDisabled: true },
    ]


    let columns = {
        definitions: definitions,
        isEditable: true,
        validatorSchema: schema,
        defaultPageSize: 10,
        pageSizes: [10, 15, 25],
        filterDefinitions: [
            { type: 'dropdown', name: 'companyId', useId: true, label: 'Companie', size: 3, value: '', getOptions: getCompanyDropdown },
            { type: 'dropdown', name: 'userId', useId: true, label: 'Utilizator', size: 3, value: '', getOptions: getCompanyUserDropdown }
        ],
    };

    if (hasAnyOfAuthorities("ROLE_ADMIN")) {
        columns.filterDefinitions = [...columns.filterDefinitions,
        { type: 'dropdown', name: 'associatedUserId', useId: true, label: 'Angajat asociat', size: 3, value: '', getOptions: getEmployeeDropdown }]

    }

    return (
        <Page
            className={classes.root}
            title="Alarme"
        >

            <EditableTable columns={columns} queries={{
                getTableDataPaginatedQuery: getAllRemindersPaginated,
                deleteTableData: deleteReminder,
                updateTableData: updateReminder,
                createTableData: createReminder
            }} />
        </Page>
    );
};

export default compose(withRouter)(Reminder);
