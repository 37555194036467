import { IconButton, Tooltip } from '@material-ui/core';
import { Clear, Delete, Done, EditRounded } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { compose } from 'underscore';


const TableAction = (props) => {

    const { isNotEditDeleteAdd, editClick, deleteClick, isEditOnCurrentRow, isDeleteOnCurrentRow, isAddOnCurrentRow, updateTableData,
        deleteTableData, addTableData, cancelClick, isLoading, row, index, error, isEditable, isDeleteDisabled, isEditDisabled } = props;


    if (!isEditable) {
        return (
            <Fragment></Fragment>
        )
    }

    return (
        (!isEditOnCurrentRow(index) && !isDeleteOnCurrentRow(index) && !isAddOnCurrentRow(index)) ? (
            <Fragment>
                {!isEditDisabled?
                <Tooltip title="Editeaza" placement="bottom" disabled={isLoading}>
                    <span>
                        <IconButton color='inherit' disabled={isLoading} aria-label="edit" onClick={() => editClick(index, row)}>
                            <EditRounded style={{ color: '#3f51b5' }} />
                        </IconButton>
                    </span>
                </Tooltip>:''}
                {!isDeleteDisabled ?
                    <Tooltip title="Sterge" placement="bottom" disabled={isLoading}>
                        <span>
                            <IconButton color='inherit' disabled={isLoading} aria-label="delete" onClick={deleteClick(index, row)}>
                                <Delete style={{ color: '#3f51b5' }} />
                            </IconButton>
                        </span>
                    </Tooltip> : ''}
            </Fragment>) : (
            <Fragment>
                <Tooltip title="Accepta" placement="bottom" disabled={isLoading}>
                    <span>

                        <IconButton color='inherit' disabled={isLoading} aria-label="save" onClick={() => {
                            if (error !== undefined) {
                                let errors = false;
                                let entries = Object.keys(error)
                                for (let value of entries) {
                                    if (error[value]) {
                                        props.enqueueSnackbar('Datele nu au un format corect!', {
                                            variant: 'error',
                                        });
                                        errors = true;
                                        break;
                                    }
                                }
                                if (errors) return;
                            }

                            if (isEditOnCurrentRow(index)) {
                                updateTableData();
                            }
                            else if (isDeleteOnCurrentRow(index)) {
                                deleteTableData(row, index);
                            }
                            else if (isAddOnCurrentRow(index)) {
                                addTableData(row, index);
                            }
                        }}>
                            <Done style={{ color: '#3f51b5' }} />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Anuleaza" placement="bottom" disabled={isLoading} >
                    <span>
                        <IconButton color='inherit' disabled={isLoading} aria-label="cancel" onClick={cancelClick(index)}>
                            <Clear style={{ color: '#3f51b5' }} />
                        </IconButton>
                    </span>
                </Tooltip>
            </Fragment>
        )
    );

}


export default compose(withSnackbar)(TableAction);