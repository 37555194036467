import {
    Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, Radio, RadioGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Typography,
    Button,
    Tooltip,
    IconButton,
    AppBar,
    Box,
    useMediaQuery
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { compose } from "recompose";
import { getDocumentAsBlob } from "src/services/files";
import { Document, Outline, Page, pdfjs } from 'react-pdf';
import { ArrowBackIos, ArrowForwardIos, NavigateBefore, NavigateNext } from "@material-ui/icons";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import './styling.css';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CloseIcon from '@material-ui/icons/Close';


const PdfViewer = (props) => {

    const [state, setState] = useState({ loading: false, blobFile: {}, blobURL: null });
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageScale, setPageScale] = useState(window.devicePixelRatio > 1.9 ? 0.98 :
        1.1);



    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    useEffect(() => {
        if (props.name)
            getData();
    }, [props.name]);

    const setLoading = (state) => {
        setState(prevState => ({ ...prevState, loading: state }))
    }

    const changeFileToNullAndSetLoading = () => {
        setState(prevState => ({ ...prevState, loading: true, blobFile: {}, blobURL: null }))

    }

    const showError = (msg) => {
        props.enqueueSnackbar(msg, { variant: 'error' });
    }

    const getData = () => {
        //name: value.documentName, checkDocumentId: value.id, contentId: state?.data?.id
        //, checkDocumentId: props.checkDocumentId, contentId: props.contentId
        if (!props.name.includes('.pdf')) {
            showError('Doar fisierele pdf pot fi vizualizate!');
            props.close();
            return;
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            showError('Datele nu pot fi vizualizate in Internet Explorer!');
            return;
        }
        changeFileToNullAndSetLoading();
        let documentData = { name: props.name }
        if (props.checkDocumentId) {
            documentData.checkDocumentId = props.checkDocumentId;
        }
        if (props.contentId) {
            documentData.contentId = props.contentId;
        }

        getDocumentAsBlob({ ...documentData }).then(({ data }) => {
            // debugger;
            var arrayBufferView = new Uint8Array(data);
            const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
            let blobURL = window.URL.createObjectURL(blob);
            // blobURL=blobURL.replace('blob:','');
            // console.log('bloburl', blobURL)
            setPageNumber(1);
            setNumPages(null);
            setState(prevState => ({ ...prevState, blobURL }));
            if (props.setDocumentDownloaded && props.checkDocumentId) {
                props.setDocumentDownloaded(props.checkDocumentId);
            }
            setLoading(false);

        }).catch(error => {
            setLoading(false);

        })
    }

    const shouldDisplayButtonsBelow = () => {
        return useMediaQuery('(max-width:316px)');

    }



    return (
        <Fragment>
            <Dialog
                open={props.open}
                //onClose={handleClose}
                // PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                // maxWidth={'xl'}
                // fullWidth
                fullScreen
            >
                <DialogTitle >
                    <Grid
                        container
                        spacing={1}
                        wrap="wrap"
                        direction="row"
                        alignItems="center"
                        alignContent='center'
                    // justify="center"
                    >
                        <Grid
                            // className={classes.item}
                            item
                            md={8}
                            sm={8}
                            xs={10}

                        >
                            <Typography variant='h5' component='span' style={{ verticalAlign: 'middle' }}>
                                {props.name || ''}
                                {state.loading ? <CircularProgress style={{ width: '25px', height: '25px', marginLeft: '10px', verticalAlign: 'middle', opacity: '0.5' }} /> : ''}

                            </Typography>
                        </Grid>
                        <Grid
                            // className={classes.item}
                            item
                            md={4}
                            sm={4}
                            xs={2}
                            style={{ textAlign: 'right' }}
                        >
                            <IconButton aria-label="close" onClick={() => props.close()}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    {/* <Grid
                            // className={classes.item}
                            item
                            md={12}
                            sm={12}
                            // xs={12}
                            style={{ textAlign: 'right' }}
                        > */}
                    <Box style={{ textAlign: 'right', position: 'absolute', zIndex: '999999' }}>
                        <Tooltip title="Zoom+" placement="bottom" disabled={state.isLoading || props.loading}>
                            <span>
                                <IconButton aria-label="delete" disabled={(pageScale + 0.1) > 3} onClick={() => {
                                    setPageScale(pageScale + 0.1);
                                }}>
                                    <ZoomInIcon fontSize='large' />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Zoom-" placement="bottom" disabled={state.isLoading || props.loading}>
                            <span>
                                <IconButton aria-label="delete" disabled={(pageScale - 0.1) <= 0.1} onClick={() => {
                                    setPageScale(pageScale - 0.1);
                                }}>
                                    <ZoomOutIcon fontSize='large' />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                    {/* </Grid> */}
                </DialogTitle>
                <DialogContent style={{ height: '80vh' }}>

                    {/* <iframe src={state.blobURL} title="title" type="application/pdf" width="100%" height="100%" style={{ "overflow": "auto" }} >
                    </iframe> */}
                    <div style={{ margin: 'auto' }}>
                        <Document
                            style={{ margin: 'auto', textAlign: 'center' }}
                            file={{ url: state.blobURL }}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={'Datele se incarca!'}
                            onLoadError={console.error}
                            width={'100%'}
                            height={'100%'}

                        >
                            <Page pageNumber={pageNumber} scale={pageScale} style={{ margin: 'auto', 'display': 'flex' }} />
                            <Outline />

                        </Document>
                    </div>

                </DialogContent>
                <DialogActions style={{ height: '59px' }}>
                    <Grid
                        container
                        spacing={1}
                        wrap="wrap"
                        direction="row"
                        alignItems="center"
                        alignContent='center'
                    // justify="center"
                    >
                        <Grid
                            // className={classes.item}
                            item
                            md={8}
                            sm={8}
                            xs={shouldDisplayButtonsBelow() ? 12 : 9}

                        >
                            <Box fullWidth >
                                <Typography variant='h5' component='span' style={{ verticalAlign: 'middle' }}>Pagina {pageNumber} din {numPages} </Typography>
                                <Tooltip title="Pagina precedenta" placement="bottom" disabled={state.isLoading || props.loading}>
                                    <span>
                                        <IconButton aria-label="delete" disabled={(pageNumber - 1) <= 0} onClick={() => {
                                            setPageNumber(pageNumber - 1);
                                        }}>
                                            <NavigateBefore fontSize='large' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Urmatoarea pagina" placement="bottom" disabled={state.isLoading || props.loading}>
                                    <span>
                                        <IconButton aria-label="delete" disabled={(pageNumber + 1) > numPages} onClick={() => {
                                            setPageNumber(pageNumber + 1);
                                        }}>
                                            <NavigateNext fontSize='large' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        </Grid>
                        <Grid
                            // className={classes.item}
                            item
                            md={4}
                            sm={4}
                            xs={shouldDisplayButtonsBelow() ? 12 : 3}
                            style={{ textAlign: 'Right' }}
                        >
                            <Button color="primary"
                                disabled={state.loading}
                                onClick={() => props.close()}>Inchide</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

        </Fragment >
    )



}


export default compose(withSnackbar)(PdfViewer);